/* eslint-disable */
import { FC, useCallback } from "react";
import styled, { css } from "styled-components";

const VideoContainer = styled.div<{ isSelected: boolean }>`
  ${({ isSelected }) => css`
    background-color: ${isSelected ? "var(--clr-neutral-800)" : "transparent"};
    border-radius: 6px;
    padding: 10px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
  `}
`;

const VideoThumbnail = styled.img`
  min-width: 80px;
  max-width: 80px;
  min-height: 45px;
  max-height: 45px;
  border-radius: 4px;
  object-fit: cover;
`;

const VideoInfo = styled.div`
  margin-left: 10px;
`;

const VideoTitle = styled.p`
  color: var(--clr-neutral-200);
  text-size: var(--fs-body-subtext-large);
`;

const VideoDuration = styled.p`
  color: var(--clr-neutral-300);
  font-size: 0.8rem;
  margin-top: 4px;
`;

const SelectCircle = styled.input.attrs({ type: "checkbox" })`
  margin-right: 10px;
  cursor: pointer;
`;

interface VideoProps {
  id: number;
  title: string;
  thumbnail: string;
  duration: string;
  isSelected: boolean;
  onToggleSelect: () => void;
  setPreview: (id: number) => void;
}

const formatDuration = (duration: string) => {
  const parts = duration.split(":");
  if (parts.length === 3 && parts[0] === "00") {
    return `${parts[1]}:${parts[2]}`;
  }
  return duration;
};

const VideoCard: FC<VideoProps> = ({
  id,
  title,
  thumbnail,
  duration,
  isSelected,
  onToggleSelect,
  setPreview
}) => {
  const handleToggleSelect = useCallback(() => {
    onToggleSelect();
  }, [id, onToggleSelect]);

  return (
    <VideoContainer isSelected={isSelected}>
      <SelectCircle
        checked={isSelected}
        onChange={handleToggleSelect}
        aria-label={`Select video titled ${title}`}
      />
      <VideoThumbnail onClick={() => setPreview(id)} src={thumbnail} alt={`Thumbnail for ${title}`} />
      <VideoInfo>
        <VideoTitle>{title}</VideoTitle>
        <VideoDuration>
          Duration: {formatDuration(duration)} minutes
        </VideoDuration>
      </VideoInfo>
    </VideoContainer>
  );
};

export default VideoCard;
