/* eslint-disable react/react-in-jsx-scope */
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom'

import { VideoProvider } from '../contexts/video'

import AddTeamMember from '../pages/Auth/AddTeamMember'
import Category from '../pages/Auth/Category'
import SubCategory from '../pages/Auth/SubCategory'
// import Experts from '../pages/Auth/Experts'
// import ExpertDetails from '../pages/Auth/Experts/ExpertDetails'
import Account from '../pages/Auth/Account'
import ChangePassword from '../pages/Auth/Account/ChangePassword'
import DeleteAccount from '../pages/Auth/Account/DeleteAccount'
import EditAccount from '../pages/Auth/Account/EditAccount'
import Achievements from '../pages/Auth/Achievements'
import CategorySorter from '../pages/Auth/CategorySorter'
import Dashboard from '../pages/Auth/Dashboard'
import FirstLogin from '../pages/Auth/FirstLogin'
import Home from '../pages/Auth/Home'
import Logout from '../pages/Auth/Logout'
import ManageOrganization from '../pages/Auth/ManageOrganization'
import CreateTeam from '../pages/Auth/ManageOrganization/CreateTeam'
// import EditEssentialsCategories from '../pages/Auth/ManageOrganization/EditEssentialsCategories'
// import EditOrderCategories from '../pages/Auth/ManageOrganization/EditOrderCategories'
import EditOrganization from '../pages/Auth/ManageOrganization/EditOrganization'
import EditTeam from '../pages/Auth/ManageOrganization/EditTeam'
// import TeamEssentialsCategories from '../pages/Auth/ManageOrganization/TeamEssentialsCategories'
import TeamForm from '../pages/Auth/ManageOrganization/TeamForm'
import TeamOverview from '../pages/Auth/ManageOrganization/TeamOverview'
import ManageTeam from '../pages/Auth/ManageTeam'
import Plan from '../pages/Auth/Plan'
import BillingDetails from '../pages/Auth/Plan/BillingDetails'
import SuccessContract from '../pages/Auth/Plan/SuccessContract'
import ProfilePage from '../pages/Auth/ProfileUser'
import ShareVideo from '../pages/Auth/ShareVideo'
import Trailer from '../pages/Auth/Trailer'
import Video from '../pages/Auth/Video'
import ViewAll from '../pages/Auth/ViewAll'
import PageCustom from '../pages/Common/PageCustom'
import VideoShare from '../pages/Common/Video'

const Routes = () => (
  <VideoProvider>
    <Router>
      <Switch>
        <Route path="/add-team-member" component={AddTeamMember} />
        <Route path="/library" component={Home} />
        <Route path="/category/:id" component={Category} />
        <Route path="/sub-category/:id/:name" component={SubCategory} />
        {/* <Route path="/experts" exact component={Experts} /> */}
        {/* <Route path="/experts/:id" component={ExpertDetails} /> */}
        <Route path="/home" component={ProfilePage} />
        <Route path="/achievements" component={Achievements} />
        <Route path="/video/:id" component={Video} />
        <Route path="/trailer" component={Trailer} />
        <Route path="/view-all/:name" component={ViewAll} />
        <Route path="/manage-organization" exact component={ManageOrganization} />
        <Route path="/manage-organization/edit" component={EditOrganization} />
        {/* <Route path="/manage-organization/essentials-categories" component={EditEssentialsCategories} />
        <Route path="/manage-organization/order-categories" component={EditOrderCategories} /> */}
        <Route path="/manage-organization/edit-team" component={EditTeam} />
        <Route path="/manage-organization/team-form/:name/:id" component={TeamForm} />
        {/* <Route path="/manage-organization/team-essential-form/:name/:id" component={TeamEssentialsCategories} /> */}
        <Route path="/manage-organization/team-overview/:name/:id" component={TeamOverview} />
        <Route path="/manage-organization/create-team" component={CreateTeam} />
        <Route path="/manage-organization/learning-path-creator" component={CategorySorter} />
        <Route path="/manage-team" component={ManageTeam} />
        <Route path="/account" exact component={Account} />
        <Route path="/account/edit" component={EditAccount} />
        <Route path="/account/change-password" component={ChangePassword} />
        <Route path="/account/delete" component={DeleteAccount} />
        <Route path="/plans" exact component={Plan} />
        <Route path="/plans/me" component={BillingDetails} />
        <Route path="/success-payment" component={SuccessContract} />
        <Route path="/logout" component={Logout} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/watch/:unique_id/:token" component={VideoShare} />
        <Route path="/share/emails" component={ShareVideo} />
        <Route path="/page/:id" component={PageCustom} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/firstLogin" component={FirstLogin} />
        <Route path="**" component={() => <Redirect to="/home" />} />
      </Switch>
    </Router>
  </VideoProvider>
)

export default Routes
