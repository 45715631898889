import React, { ChangeEvent, /* useEffect, */ useState } from 'react'
/* import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import useOrganization from '../../../hooks/useOrganization' */
import api from '../../../services/api'

import {
  ContainerDiv,
  InputDefault,
  InputLabel,
  ContainerOrganizationForm,
  TitleForm
} from './styled'
import { ButtonOrange } from '../../Buttons'
import { Link } from 'react-router-dom'

/* const animatedOptions = makeAnimated() */

const OrganizationForm = () => {
  /* const [orgCategory, setOrgCategory] = useState<any>([])
  const [orgVideos, setOrgVideos] = useState<any>([]) */
  const [orgBanner, setOrgBanner] = useState<File>()
  /* const [categoriesOptions, setCategoriesOptions] = useState<any>([])
  const { allCategories, allSubCategories, allVideos } = useOrganization() */

  /* const createCategoriesOptions = () => {
    const categoriesOptions = []
    allCategories.sort((a, b) => a.category_id < b.category_id ? -1 : (a.category_id > b.category_id ? 1 : 0))
    for (const category of allCategories) {
      categoriesOptions.push({
        value: category.category_id, label: category.name
      })
    }

    setCategoriesOptions(categoriesOptions)
  } */

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setOrgBanner(event.target.files[0])
    }
  }

  const editOrganization = async () => {
    const formData = {
      banner_file: orgBanner ?? false
      /* categories: JSON.stringify(orgCategory),
      videos: JSON.stringify(orgVideos) */
    }

    const response = await api.postMethod(`organization/edit/banner/${localStorage.getItem('organizationId')}`, formData)
    if (response.status === 201) {
      alert('Organization updated successfully!')
      window.location.href = '/home'
    }
  }

  /* useEffect(() => {
    createCategoriesOptions()
  }, [allCategories, allSubCategories]) */

  return (
    <React.Fragment>
      <ContainerDiv style={{ position: 'absolute', marginLeft: '5%', top: '12%' }}>
        <Link to='/manage-organization'>
          <ButtonOrange
            as="button"
            type="button"
            className='btn'
          >
            Back
          </ButtonOrange>
        </Link>
      </ContainerDiv>

      <ContainerOrganizationForm>
        <TitleForm>Edit Organization Configuration</TitleForm>
        <ContainerDiv className="container">
          <ContainerDiv className="form-group mt-2">
            <InputLabel htmlFor="orgName">Organization Name</InputLabel>
            <InputDefault
              className="form-control"
              id="orgName"
              name="orgName"
              placeholder="Organization Name"
              value={localStorage.getItem('organizationName')}
              minLength={1}
              readOnly
            />
          </ContainerDiv>

          <InputLabel htmlFor="orgBanner">Choose Banner:</InputLabel>
          <ContainerDiv style={{ textAlign: 'left' }}>
            <InputDefault
              id="orgBanner"
              name="orgBanner"
              type="file"
              onChange={handleFileChange}
            />
          </ContainerDiv>

          {/* <ContainerDiv className="form-group mt-2">
            <InputLabel htmlFor="categories[]">Organization Categories:</InputLabel>
            <Select
              onChange={(item) => setOrgCategory(item)}
              components={animatedOptions}
              options={categoriesOptions}
              closeMenuOnSelect={false}
              className="basic-multi-select"
              classNamePrefix="select"
              name="categories[]"
              isMulti
              required
            />
          </ContainerDiv>

          <ContainerDiv className="form-group mt-2">
            <InputLabel htmlFor="categories[]">Hide Videos:</InputLabel>
            <Select
              onChange={(item) => setOrgVideos(item)}
              components={animatedOptions}
              options={allVideos}
              closeMenuOnSelect={false}
              className="basic-multi-select"
              classNamePrefix="select"
              name="videos[]"
              isMulti
            />
          </ContainerDiv> */}

          <ButtonOrange
            as="button"
            type="button"
            className='btn'
            data-dismiss="modal"
            onClick={async () => await editOrganization()}
          >
            Update Organization
          </ButtonOrange>
        </ContainerDiv>
      </ContainerOrganizationForm>
    </React.Fragment>
  )
}

export default OrganizationForm
