import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--gray-bg);
  width: 100%;
  min-width: 100%;
  height: calc(100vh - 64px);
  min-height: 100%;
`

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--gray-bg);
  margin-top: 64px;
  width: 100%;
  padding-inline: 7%;
  min-height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: var(--font-family-body);
  font-size: var(--fs-body-subtext-large);

  @media (max-width: 769px) {
    display: none;
  }
`

export const MobileWarning = styled.div`
  display: none;
  @media (max-width: 769px) {
    width: 100%;
    min-width: 100%;
    height: calc(100vh - 64px);
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      color: var(--clr-neutral-100);
      width: 50%;
      text-align: center;
    }
  }
`

export const DashboardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 90px;
`

export const TeamPicker = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  text {
    font-size: var(--fs-headline-subtitle-medium);
    color: var(--clr-neutral-100);
    font-weight: var(--fw-semibold);
    width: fit-content;
  }
`

export const OrganizationName = styled.text`
  font-size: var(--fs-headline-subtitle-medium);
  color: var(--clr-neutral-100);
  font-weight: var(--fw-semibold);

`

export const BlocksContainer = styled.div`
  height: calc(80% - 64px);
  width: 100%;
  margin-block: 16px;
  display: grid;
  gap: 24px;
  grid-template-areas: 
  "tracker alerts alerts"
  "tracker compliance optional";
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
`

export const ScrollContainerX = styled.div`
  width: 100%;
  overflow-x: scroll;
  height: max-content;
  padding: 8px;
  margin-top: 8px;
  background: rgba(148, 146, 225, 0.07);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`

export const ScrollContainerY = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: 54vh;
  padding: 8px;
  margin-top: 8px;
  background: rgba(148, 146, 225, 0.07);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`

export const ScrollContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 33vh;
  padding: 8px 2px;
  margin-top: 8px;
  background: rgba(148, 146, 225, 0.07);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`

export const TeamTracker = styled.div`
  grid-area: tracker;
  width: 100%;
  height: 100%;
  min-width: 450px;
`

export const ProgressText = styled.div`
  font-weight: var(--fw-medium);
  font-size: var(--fs-body-text-medium);
  color: var(--clr-neutral-100);
  margin-top: 16px;
  margin-bottom: -8px;
`

export const Alerts = styled.div`
  grid-area: alerts;
  width: 100%;
  height: 100%;
`

export const ComplianceReporting = styled.div`
  grid-area: compliance;
  width: 100%;
  height: 100%;
`

export const OptionalTraining = styled.div`
  grid-area: optional;
  width: 100%;
  height: 100%;
`
