/* eslint-disable react/react-in-jsx-scope */
import { t } from 'react-multi-lang'
import { Link } from 'react-router-dom'
import Helper from '../../../helper/helper'
import Logo from '../../Logo'
// import Notifications from '../Notifications'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import wordMark from '../../../assets/images/ManU-Logo2.png'
import arrowDown from '../../../assets/images/arrow-down.svg'
import certMenuIco from '../../../assets/images/certificate-header-ico.svg'
import dashboardIco from '../../../assets/images/dashboard-ico.svg'
import expertsIco from '../../../assets/images/experts-ico.svg'
import homeIco from '../../../assets/images/home-ico.svg'
import libraryIco from '../../../assets/images/library-ico.svg'
import menuIcon from '../../../assets/images/menu.svg'
import profileUserIco from '../../../assets/images/profile-user-ico.svg'
import signOutIco from '../../../assets/images/sign-out-ico.svg'
import teamIco from '../../../assets/images/team-ico.svg'

class UserHeader extends Helper {
    state = {
      isGuestUser: true,
      isSuperUser: null,
      categories: null,
      mobileSidebar: false
    };

    toggleMobileSidebar = () => this.setState({ mobileSidebar: !this.state.mobileSidebar })

    componentDidMount () {
      const superUser = Boolean(+localStorage.getItem('isSuperUser'))
      this.setState({ isSuperUser: superUser })

      const dataUserGuest = JSON.parse(localStorage.getItem('user'))
      const isGuestUser = localStorage.getItem('accessToken') + localStorage.getItem('userId') + localStorage.getItem('name')
      const isGuestUserData = dataUserGuest.token + dataUserGuest.user_id + dataUserGuest.name
      if (localStorage.getItem('isGuestUser') && localStorage.getItem('isGuestUser') === isGuestUser) {
        this.setState({ isGuestUser: false })
      }
      if (localStorage.getItem('isGuestUser') === isGuestUserData) {
        this.setState({ isGuestUser: false })
      }
    }

    render () {
      const {
        isSuperUser,
        isGuestUser
      } = this.state

      return (
        <div>
          <nav
            className="navbar navbar-expand main-nav fixed-top"
            id="header"
          >
            <Link className="navbar-brand abs" to="/home">
              <Logo />
            </Link>
            <ul className="navbar-nav desktop-nav">
              <div className='left-items'>
                <li className="nav-item active" key="home">
                  <Link className="nav-link text-blue-logo" to={'/home'}>
                    {t('home')}
                  </Link>
                </li>
                {/* {
                  localStorage.getItem('expert_organization') === '0' && (
                    <li className="nav-item">
                      <Link
                        className="nav-link text-blue-logo"
                        to={'/experts'}
                      >
                        {t('Experts')}
                      </Link>
                    </li>
                  )
                } */}
                {
                  isSuperUser && (
                    <li className="nav-item">
                      <Link
                        className="nav-link text-blue-logo"
                        to={'/dashboard'}
                      >
                        {t('dashboard')}
                      </Link>
                    </li>
                  )
                }
                {
                  isSuperUser && (
                    <li className="nav-item">
                      <Link
                        className="nav-link text-blue-logo"
                        to={'/library'}
                      >
                        {t('Library')}
                      </Link>
                    </li>
                  )
                }
              </div>
              <div className='right-items'>
                {/* <Search /> */}
              <li className="nav-item help-buttons">
                <Link to="/page/help" rel="noopener noreferrer" className="nav-link text-blue-logo">
                    Help
                </Link>
              </li>
              {
                isGuestUser && (
                  <li className="nav-item help-buttons">
                    <Link
                      to='#'
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSf9AWdB_Eae12RhcH56KYahCCbSQ4CJUusdiHKJbg6IWsC-NQ/viewform"
                      className="nav-link text-blue-logo"
                    >
                      Request Topic
                    </Link>
                  </li>
                )
              }
              {/* <li className="nav-item d-md-flex align-items-center d-none mb-2 ml-2 mobile-view">
                <Notifications />
              </li> */}
              <li className="nav-item dropdown mobile-view">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    data-toggle="dropdown"
                  >
                    {
                      localStorage.getItem('isGuestUser')
                        ? <span className='text-blue-logo'>{localStorage.getItem('name')}</span>
                        : <>
                        <img src={wordMark} className="nav-profile-img" alt="profile_img" />
                        <img src={arrowDown} className="arrow-down" alt="arrow-down" />
                        </>
                    }
                  </Link>
                  <div className="dropdown-menu profile-drop">
                    {
                      isSuperUser && (
                        <Link
                        className="dropdown-item"
                        to="/manage-organization"
                        >
                          <LazyLoadImage src={teamIco} width='20px' height='20px' style={{ marginRight: '16px', paddingLeft: '1px' }}/>{t('manage')}
                        </Link>
                      )
                    }
                    {
                      isGuestUser && (
                        <Link className="dropdown-item" to="/account">
                          <LazyLoadImage src={profileUserIco} width='20px' height='20px' style={{ marginRight: '16px', paddingLeft: '1px' }}/>{t('User Account')}
                        </Link>
                      )
                    }
                    <Link className="dropdown-item" to={'/achievements'}>
                      <LazyLoadImage src={certMenuIco} width='22px' height='24px' style={{ marginRight: '15px' }}/>{t('Certificates')}
                    </Link>
                    <Link className="dropdown-item" to={'/logout'}>
                      <LazyLoadImage src={signOutIco} width='22px' height='22px' style={{ marginRight: '16px', paddingLeft: '2px' }}/>{t('signout')}
                    </Link>
                  </div>
                </li>
              </div>
              <span
                className="menu-icon"
                id="menu_icon"
                onClick={() => this.toggleMobileSidebar()}
              >
                <img
                  src={menuIcon}
                  alt="menu_img"
                />
              </span>
            </ul>
            {/* <ul className="navbar-nav ml-auto">
            </ul> */}
        </nav>
        <div className="header-height" />
        <div
          className="mobile-sidebar"
          id="menu_content"
          style={ this.state.mobileSidebar ? { display: 'flex', justifyContent: 'flex-end' } : { display: 'none' }}
        >
          <div className="sidebar-content">
            <ul className="sidebar-menu pt-5" id="mobile-side-menu">
              <li key="home-sidemenu">
                <Link to="/home">{t('home')}<LazyLoadImage src={homeIco} width='22px' height='22px' style={{ marginLeft: '16px' }}/></Link>
              </li>
              {
                isSuperUser && (
                  <li className="nav-item">
                    <Link
                      to={'/dashboard'}
                    >
                      {t('dashboard')}<LazyLoadImage src={dashboardIco} width='20px' height='20px' style={{ marginLeft: '16px' }}/>
                    </Link>
                  </li>
                )
              }
              {
                isSuperUser && (
                  <li className="nav-item">
                    <Link
                      to={'/library'}
                    >
                      {t('library')}<LazyLoadImage src={libraryIco} width='20px' height='20px' style={{ marginLeft: '16px' }}/>
                    </Link>
                  </li>
                )
              }
              <li className="nav-item">
                <Link
                  to={'/experts'}
                >
                  {t('experts')}<LazyLoadImage src={expertsIco} width='20px' height='20px' style={{ marginLeft: '16px' }}/>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={'/achievements'}
                >
                  {t('Certificates')}<LazyLoadImage src={certMenuIco} width='22px' height='24px' style={{ marginLeft: '14px' }}/>
                </Link>
              </li>
              {
                isGuestUser && (
                  <li key="account-sidemenu">
                    <Link to="/account">{'user account'}<LazyLoadImage src={profileUserIco} width='20px' height='20px' style={{ marginLeft: '16px', paddingRight: '5px' }}/></Link>
                  </li>
                )
              }
              {
                isSuperUser && (
                  <>
                    <li key="manage-organization-sidemenu">
                      <Link to="/manage-organization">{t('manage')}<LazyLoadImage src={teamIco} width='20px' height='20px' style={{ marginRight: '16px', paddingLeft: '1px' }}/></Link>
                    </li>
                  </>
                )
              }
              <li key="logout-sidemenu">
                <Link to="/logout">{t('sign out')}<LazyLoadImage src={signOutIco} width='22px' height='22px' style={{ marginLeft: '16px', paddingRight: '2px' }}/></Link>
              </li>
              <div className="mt-5">
                <li className="nav-item">
                  <Link to="/page/help">
                        Help
                    </Link>
                </li>
                {
                  isGuestUser && (
                    <li>
                      <Link
                        to="#"
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSf9AWdB_Eae12RhcH56KYahCCbSQ4CJUusdiHKJbg6IWsC-NQ/viewform"
                      >
                        Request Topic
                      </Link>
                    </li>
                  )
                }
              </div>
            </ul>
          </div>
        </div>
      </div>)
    }
}
export default UserHeader
