/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import IconArrowDown from '../Icons/IconArrowDown'
import './SlideButton.scss'
import { useLocation } from 'react-router-dom'

const SlideButton = ({ onClick, type }) => {
  const location = useLocation()
  const [home, setHome] = useState(true)
  const isSubCategory = () => {
    const path = location.pathname
    if (path.split('/')[1] !== 'sub-category') {
      setHome(false)
    } else {
      setHome(true)
    }
  }
  useEffect(() => {
    isSubCategory()
  }, [])
  return (
  <button className={`slide-button slide-button--${type} ${home ? 'button-none-subcategory' : ''}`} onClick={onClick}>
    <span>
      <IconArrowDown />
    </span>
  </button>
  )
}

export default SlideButton
