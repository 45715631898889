/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from 'react'
import Select from 'react-select'
import teamIco from '../../../assets/images/team-16-ico.svg'
import { AlertsUserItem } from '../../../components/Auth/AlertsUserItem'
import { DashboardCard } from '../../../components/Auth/DashboardCard'
import Header from '../../../components/Auth/Header'
import { TrackerUserItem } from '../../../components/Auth/TrackerUserItem'
import Loading from '../../../components/Loading'

import {
  Alerts,
  BlocksContainer,
  ComplianceReporting,
  Container,
  DashboardHeader,
  MobileWarning,
  OptionalTraining,
  OrganizationName,
  PageContainer,
  ProgressText,
  ScrollContainer,
  ScrollContainerX,
  ScrollContainerY,
  TeamPicker,
  TeamTracker
} from './styled'

import { AxiosResponse } from 'axios'
import { HorizontalStackedBarChart } from '../../../components/Auth/HorizontalStackedBarChart'
import { VerticalBarChart } from '../../../components/Auth/VerticalBarChart'
import LoadingSpinner from '../../../components/LoadingSpinner'
import api from '../../../services/api'

interface TeamUsersProgressInterface {
  userId: number,
  name: string,
  team: string,
  picture: string,
  progress: number,
}

interface SelectProps {
  label: string,
  value: string
}

interface TeamTrackerInterface {
  teamProgress: number,
  teamUsersProgress: TeamUsersProgressInterface[]
}

const customStyles = {
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#CBAF63' : '#fff',
    color: '#2D2935',
    fontWeight: state.isSelected ? 600 : 500,
    '&:hover': { backgroundColor: '#D8DCF3' }
  }),
  control: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    borderRadius: '6px',
    borderColor: state.isFocused ? '#CBAF63' : '#9D9AAD',
    backgroundColor: '#F4F3FE',
    fontWeight: 600,
    '&:hover': { borderColor: '#222B6A' }
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: 'transparent'
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#2D2935'
  })
}

const Dashboard = () => {
  // loading components
  const [loading, setLoading] = useState<boolean>(false)
  const [isLoadingTracker, setIsLoadingTracker] = useState<boolean>(true)
  const [isLoadingOptional, setIsLoadingOptional] = useState<boolean>(true)
  const [isLoadingAlerts, setIsLoadingAlerts] = useState<boolean>(true)
  const [isLoadingCompliance, setIsLoadingCompliance] = useState<boolean>(false)

  // categories
  // const [categoriesEssential, setCategoriesEssential] = useState<any[]>([])
  const [categoriesTotal, setCategoriesTotal] = useState<any[]>([])

  // team
  const [teamSelect, setTeamSelect] = useState<any[]>([])
  const [selectedOptionTeam, setSelectedOptionTeam] = useState(null)
  const [team, setTeam] = useState(null)

  // alerts
  const [userAlertsAllCategories, setUserAlertsAllCategories] = useState<any[]>([])
  const [alertsShow, setAlertsShow] = useState<any[]>([])
  const [alertsSelect, setAlertsSelect] = useState<any[]>([])
  const [selectedOptionAlerts, setSelectedOptionAlerts] = useState({} as any)

  // optional training
  const [courseNames, setCourseNames] = useState<Array<string>>()
  const [keyNames, setKeyNames] = useState<Array<string>>()
  const [valueNames, setValueNames] = useState<Array<number>>()
  const [chartData, setChartData] = useState<Array<object>>([])
  const [optionalShow, setOptionalShow] = useState<any>()

  // compliance reporting
  const [complianceShow, setComplianceShow] = useState<any>()

  // team tracker
  const [teamTracker, setTeamTracker] = useState<TeamTrackerInterface>({} as TeamTrackerInterface)
  const [teamTrackerSelect, setTeamTrackerSelect] = useState<any[]>([
    {
      value: 'All optional content',
      label: 'All optional content'
    },
    {
      value: 'All required content',
      label: 'All required content'
    }
  ])
  const [selectedOptionTeamTracker, setSelectedOptionTeamTracker] = useState<SelectProps>()

  // csv
  const [csvData, setCsvData] = useState<any[]>([])

  const organizationName = localStorage.getItem('organizationName')
  const organizationOption = [{
    value: organizationName,
    label: `${organizationName} (Organization)`
  }]

  const getTeams = async () => {
    const { data }: AxiosResponse<any[any]> = await api.getMethod(`organization/${localStorage.getItem('organizationId')}/teams`)
    console.log(data)
    const selectFormatTeams = []

    for (let i = 0; i < data.length; i++) {
      selectFormatTeams.push({
        value: data[i].name,
        label: data[i].name,
        teamId: data[i].id
      })
    }

    const teamSelectArray = [...organizationOption, ...selectFormatTeams]
    setTeamSelect(teamSelectArray)
  }

  const getUserAlerts = useCallback(async (categoryEssential: any[], team: any) => {
    setIsLoadingAlerts(true)
    const userAlerts: any = []

    for (let i = 0; i < categoryEssential.length; i++) {
      const { data }: AxiosResponse<any[any]> = await api.getMethod('get_dashboard_users_alerts', {
        organizationId: localStorage.getItem('organizationId'),
        team: team === organizationName ? null : team,
        categoryId: Number(categoryEssential[i].category_id)
      })
      userAlerts.push(data)
    }
    setUserAlertsAllCategories(userAlerts)
    setAlertsShow(userAlerts[0].users_alerts)
    setIsLoadingAlerts(false)
  }, [])

  function convertToCamelCase (string:any) {
    const words = string.split(' ')
    const firstWord = words[0].toLowerCase()
    const remainingWords = words.slice(1)
    for (let i = 0; i < remainingWords.length; i++) {
      remainingWords[i] = remainingWords[i][0].toUpperCase() + remainingWords[i].substring(1)
    }
    const camelCaseString = [firstWord, ...remainingWords].join('')
    return camelCaseString
  }

  async function coursesToCompletionRates (courses: any) {
    const completionRates:any = {}
    const completionRatesStrings:any = []
    const completionRatesKeys:any = []
    const completionRatesValues:any = []

    if (courses === null) {
      return
    }

    const sumTotalMinutes = courses.reduce((total:any, course:any) => total + course.total_minutes, 0)

    let remainingPercentage = 100
    courses.forEach((course: { total_minutes: any, name: any, completion: any }) => {
      const percentageTotalMinutes = (course.total_minutes / sumTotalMinutes) * 100
      const roundedPercentage = Math.round(percentageTotalMinutes)
      const adjustedPercentage = roundedPercentage >= 0.5 ? Math.ceil(percentageTotalMinutes) : Math.floor(percentageTotalMinutes)
      const finalPercentage = remainingPercentage <= adjustedPercentage ? remainingPercentage : adjustedPercentage
      remainingPercentage -= finalPercentage
      course.completion = finalPercentage
    })

    for (let i = 0; i < courses.length; i++) {
      const { name, completion } = courses[i]
      completionRates[convertToCamelCase(name)] = !Number.isNaN(completion) ? completion : 0
      completionRatesStrings.push(name)
      completionRatesKeys.push(convertToCamelCase(name))
      completionRatesValues.push(!Number.isNaN(completion) ? completion : 0)
    }
    completionRates.courseLabel = 'Optional Courses'
    setCourseNames(completionRatesStrings)
    setKeyNames(completionRatesKeys)
    setChartData(completionRates)
    setValueNames(completionRatesValues)
  }

  const getOptionalTrainingProgress = useCallback(async (team: any) => {
    setIsLoadingOptional(true)

    const { data }: AxiosResponse<any[any]> = await api.getMethod('get_organization_team_optional_training', {
      organizationId: localStorage.getItem('organizationId'),
      team: team === organizationName ? null : team
    })

    setOptionalShow(data)
    coursesToCompletionRates(data)
    setIsLoadingOptional(false)
  }, [])

  function getCategoryIdFromArray (arrayOfCategories: any[] | undefined, categoryName: string | undefined | null) {
    if (arrayOfCategories) {
      for (let i = 0; i < arrayOfCategories.length; i++) {
        if (arrayOfCategories[i].category.name === categoryName) {
          return arrayOfCategories[i].category.id
        }
      }
      return null
    }
  }

  const getOverallProgress = useCallback(async (team: any, select?: string, arrayOfCategories?: any[] | undefined) => {
    setIsLoadingTracker(true)

    const onlyEssential = select === 'All required content' ? 1 : 0
    const categoryChoosen = select !== 'All required content' && select !== 'All optional content' ? getCategoryIdFromArray(arrayOfCategories, select) : null

    const { data }: AxiosResponse<any[any]> = await api.getMethod('get_organization_team_overall_progress', {
      organizationId: localStorage.getItem('organizationId'),
      team: team === organizationName ? null : team,
      categoryId: categoryChoosen,
      onlyEssentials: onlyEssential
    })

    const userData = data.overallProgress.teamUsersProgress

    const teamProgress = [
      {
        name: 'Overall Progress',
        progress: data.overallProgress.teamProgress
      }
    ]

    const usersProgress = []

    for (let i = 0; i < userData.length; i++) {
      usersProgress.push({
        name: userData[i].name,
        progress: userData[i].progress
      })
    }

    const dataArrayToCsv = [...teamProgress, ...usersProgress]
    setCsvData(dataArrayToCsv)
    setTeamTracker(data.overallProgress)
    setIsLoadingTracker(false)
  }, [])

  const getComplianceReporting = useCallback(async (team: any) => {
    setIsLoadingCompliance(true)

    const { data }: AxiosResponse<any[any]> = await api.getMethod('get_organization_compliance_report', {
      organizationId: localStorage.getItem('organizationId'),
      team: team === organizationName ? null : team
    })

    setComplianceShow(data)
    setIsLoadingCompliance(false)
  }, [])

  function formatDate (dateString: string) {
    const date = new Date(dateString)
    const formatter = new Intl.DateTimeFormat('pt-BR', { day: 'numeric', month: 'numeric', year: 'numeric' })
    const formattedDate = formatter.format(date)
    return formattedDate
  }

  async function checkIfEssential (categoryTotal: any) {
    const onlyEssentialCategories = categoryTotal.map((category:any) => {
      if (category.essential === 1) {
        return category
      }
      return null
    })

    const filteredArray = onlyEssentialCategories.filter((value:any) => value !== null)

    const essentialOptions = filteredArray.map((item:any, index:any) => ({
      value: index,
      label: item.category.name
    }))

    await getUserAlerts(filteredArray, team)
    setAlertsSelect(essentialOptions)
    // setCategoriesEssential(filteredArray)
  }

  const getCategoriesInfo = async () => {
    const organizationCategories: AxiosResponse<any[any]> = await api.getMethod(`get_all_organization_categories/${parseInt(localStorage.getItem('organizationId') as string)}`)
    setCategoriesTotal(organizationCategories?.data?.data)
    checkIfEssential(organizationCategories?.data?.data)

    const options = organizationCategories?.data?.data.map((item:any, index:any) => ({
      value: index,
      label: item.category.name
    }))

    const mergedArrays = [...teamTrackerSelect, ...options]
    setTeamTrackerSelect(mergedArrays)

    await getOptionalTrainingProgress(team)
    await getOverallProgress(team)
    await getComplianceReporting(team)
  }

  useEffect(() => {
    if (optionalShow !== undefined) {
      setIsLoadingOptional(true)
      coursesToCompletionRates(optionalShow)
      setIsLoadingOptional(false)
    }
  }, [optionalShow])

  useEffect(() => {
    getTeams()
    getCategoriesInfo()
    setLoading(false)
  }, [])

  if (loading) {
    <Loading />
  }

  // to update after team change
  useEffect(() => {
    if (selectedOptionTeam !== null) {
      getUserAlerts(categoriesTotal, team)
      getOptionalTrainingProgress(team)
      getComplianceReporting(team)
      getOverallProgress(team, selectedOptionTeamTracker?.label, categoriesTotal)
    }
  }, [selectedOptionTeam])

  function convertToCsv (csvData: any[]) {
    if (csvData) {
      const header = 'Name,Progress'
      const rows = csvData.map(({ name, progress }) => `${name},${progress}%`)
      return [header, ...rows].join('\n')
    }
  }

  const handleDownloadCsv = (csvData: any[]) => {
    if (csvData) {
      const string = convertToCsv(csvData)
      // eslint-disable-next-line no-undef
      const blob = new Blob([string as BlobPart], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'team-data.csv'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }

  function handleSelectChangeAlerts (selectedOptionAlerts: any) {
    setIsLoadingAlerts(true)
    setAlertsShow(userAlertsAllCategories[selectedOptionAlerts.value].users_alerts)
    setSelectedOptionAlerts(selectedOptionAlerts)
    setIsLoadingAlerts(false)
  }

  function handleSelectChangeTeamTracker (selectedOptionTeamTracker: any) {
    setIsLoadingTracker(true)
    getOverallProgress(team, selectedOptionTeamTracker.label, categoriesTotal)
    setSelectedOptionTeamTracker(selectedOptionTeamTracker)
    setIsLoadingTracker(false)
  }

  function handleSelectChangeTeam (selectedOptionTeam: any) {
    setTeam(selectedOptionTeam.label)
    setSelectedOptionTeam(selectedOptionTeam)
  }

  async function handleSendBroadcast () {
    const shareBroadcast = confirm('Do you want to sent a broadcast to this users?')

    if (!shareBroadcast) {
      return
    }

    const organizationId = localStorage.getItem('organizationId')

    const response = await api.postMethod('send_broadcast', {
      organizationId,
      team: team === organizationName ? null : team,
      categoryId: selectedOptionAlerts.value
        ? categoriesTotal[selectedOptionAlerts.value].category.id
        : categoriesTotal[0].category.id
    })

    if (response.status === 200) {
      alert('Broadcast sent successfully!')
    }
  }

  return (
    <Container>
      <Header />
      <PageContainer>
        <DashboardHeader>
          <OrganizationName>
            Organization: {organizationName}
          </OrganizationName>
          <TeamPicker>
            <text>Choose a team to view information:</text>
            <Select
              options={teamSelect}
              styles={customStyles}
              onChange={handleSelectChangeTeam}
              value={selectedOptionTeam === null ? teamSelect[0] : selectedOptionTeam}
              defaultValue={teamSelect[0]}
            />
          </TeamPicker>
        </DashboardHeader>
        <BlocksContainer>
          <TeamTracker>
            <DashboardCard
              label="Team Tracker"
              select={true}
              onChange={handleSelectChangeTeamTracker}
              options={teamTrackerSelect}
              // defaultValue={}
              value={selectedOptionTeamTracker === undefined ? teamTrackerSelect[0] : selectedOptionTeamTracker}
              buttonExists={true}
               button={() => handleDownloadCsv(csvData)}
            >
              <ProgressText>Overall progress:</ProgressText>
              {
                !isLoadingTracker
                  ? (
                    <>
                      <TrackerUserItem userImg={teamIco} percentage={teamTracker.teamProgress} />
                      <ScrollContainerY>
                        {
                          teamTracker &&
                          teamTracker.teamUsersProgress.map((item:any, index:any) => {
                            const name = item.name.split(' ')
                            return (
                              <TrackerUserItem key={index} userImg={item.picture} percentage={item.progress} name={name[0]}/>
                            )
                          })
                        }
                      </ScrollContainerY>
                    </>
                    )
                  : (
                    <LoadingSpinner />
                    )
              }
            </DashboardCard>
          </TeamTracker>
          <Alerts>
            <DashboardCard
              label="Alerts"
              select={true}
              options={alertsSelect}
              onChange={handleSelectChangeAlerts}
              defaultValue={alertsShow}
              value={selectedOptionAlerts.value ? selectedOptionAlerts : alertsSelect[0]}
              buttonExists={false}
              actionButtonExists={true}
              actionButton={() => handleSendBroadcast()}
              actionButtonLabel='Send Broadcast'
            >
              {
                !isLoadingAlerts
                  ? (
                    <ScrollContainerX>
                      { alertsShow
                        ? (
                            alertsShow.map((item:any, index:any) => {
                              return (
                                <AlertsUserItem key={index}
                                  userId={item.user_id}
                                  userImg={item.user_picture}
                                  categoryId={selectedOptionAlerts.value ? categoriesTotal[selectedOptionAlerts.value].category.id : categoriesTotal[0].category.id}
                                  categorySelected={selectedOptionAlerts === null ? alertsSelect[0] : selectedOptionAlerts}
                                  percentage={item.percentage_videos_watched}
                                  dateStarted={formatDate(item.first_video_watched)}
                                  name={item.user_name}
                                />
                              )
                            })
                          )
                        : (
                            <LoadingSpinner />
                          )
                      }
                    </ScrollContainerX>
                    )
                  : (
                    <LoadingSpinner />
                    )
              }
            </DashboardCard>
          </Alerts>
          <ComplianceReporting>
            <DashboardCard label="Compliance Reporting" buttonExists={false}>
              {
                !isLoadingCompliance
                  ? (
                      complianceShow &&
                        <ScrollContainer>
                          <VerticalBarChart data={complianceShow} height={complianceShow.length * 70} />
                        </ScrollContainer>
                    )
                  : (
                    <LoadingSpinner />
                    )
              }
            </DashboardCard>
          </ComplianceReporting>
          <OptionalTraining>
            <DashboardCard label="Optional Training Engagement" buttonExists={false}>
              {
                !isLoadingOptional
                  ? (
                      <ScrollContainer>
                        <HorizontalStackedBarChart data={chartData} courseNames={courseNames} keyNames={keyNames} valueNames={valueNames} height={230} />
                      </ScrollContainer>
                    )
                  : (
                      <LoadingSpinner />
                    )
              }
            </DashboardCard>
          </OptionalTraining>
        </BlocksContainer>
      </PageContainer>
      <MobileWarning>
        <h2>Open this dashboard page on a desktop.<br/>
          <strong>Our Mobile version is coming soon!</strong></h2>
      </MobileWarning>
    </Container>
  )
}

export default Dashboard
