import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
// import { t } from 'react-multi-lang'

import Banner from '../../../components/Auth/Banner'
import Slider from '../../../components/Auth/SliderView/MainSlider/Slider'
import Item from '../../../components/Auth/SliderView/MainSlider/Item'
import Loading from '../../../components/Loading'

import api from '../../../services/api'

import {
  ContainerHome
} from './styled'
import useAuth from '../../../hooks/useAuth'

const Header = React.lazy(() => import('../../../components/Auth/Header'))
const MenuCategories = React.lazy(() => import('../../../components/MenuCategories'))

const Home = () => {
  const [loading, setLoading] = useState(true)
  const [videos, setVideos] = useState([])
  const [subCategories, setSubCategories] = useState([])

  const { userDetails } = useAuth()
  const history = useHistory()

  if (userDetails?.is_change_password) {
    history.push('/change-password')
  }

  async function getVideos () {
    if (Number(localStorage.getItem('logged_in_account')) === 1) {
      const { data }: any = await api.postMethod(`user/${Number(localStorage.getItem('userId'))}/updateFirstLoginInLibraryPush`, {})
      localStorage.setItem('logged_in_account', data?.data?.logged_in_account)
    }
    const responseHome: any = await api.postMethod('home_first_section', {})

    if (!responseHome.data.success) {
      history.push('/logout')
      return
    }

    const homeSection = responseHome?.data?.data?.map((movie: any) => {
      const allMovies = process.env.REACT_APP_ENV === 'PROD' ? movie.data.filter((i: any) => i.in_production) : movie.data.filter((i: any) => i.in_test)
      return {
        ...movie,
        data: allMovies
      }
    })
    setVideos(homeSection)

    const responseSubcategories: any = await api.postMethod('subCategoriesVideos', {})
    if (!responseSubcategories.data.success) {
      history.push('/logout')
      return
    }

    const subcategoriesSection = responseSubcategories?.data?.data?.map((movie: any) => {
      return {
        ...movie,
        data: movie.data.filter((i: any) => i.in_production)
      }
    })
    setSubCategories(subcategoriesSection)
    setLoading(false)
  }

  useEffect(() => {
    getVideos()
  }, [])

  function renderVideoList (main: any, index: number) {
    return (
      <React.Fragment key={index}>
        <div className="main-slidersec">
          <Link
            to={{
              pathname: !main.url_page ? `view-all/${main.title}` : main.url_page
            }}
          >
            <h3>
              {main.title}
              <i className="fas fa-angle-right ml-2" />
            </h3>
          </Link>

          <Slider>
            {main.data.map((movie: any, index: any) => <Item movie={movie} key={movie.admin_video_id} index={index} />)}
          </Slider>
        </div>
      </React.Fragment>
    )
  };

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <React.Suspense fallback={<Loading />}>
        <Header />
      </React.Suspense>
      <ContainerHome>
        <div>
          <Banner />
        </div>
        <React.Suspense fallback={<Loading />}>
          <MenuCategories />
        </React.Suspense>
        {document.documentElement.clientWidth <= 425 && [1, 2, 3, 4, 5, 6].map((br, index) => <br key={index} />)}
        {
          videos.filter((item: any) => item.data.length > 0).map((main, index) => renderVideoList(main, index))
        }
        {
          subCategories.filter((item: any) => item.data.length > 0).map((main, index) => renderVideoList(main, index))
        }
      </ContainerHome>
      {/* <footer className="text-center mb-2 mt-4">
        <span className="footer-copywriter-brand"><a href="https://www.comuniverse.com.br/" className="link-poweredby">{t('poweredBy')}</a><sup>&copy;</sup></span>
      </footer> */}
    </>
  )
}

export default Home
