/* eslint-disable react/prop-types */
import React from 'react'
import image from '../../../../assets/images/arrow-white.png'
import './ShowDetailsButton.scss'

const ShowDetailsButton = ({ onClick }) => (
    <button onClick={onClick} className="show-details-button">
        <div className="text-center thumbarrow-sec">
            <img
                src={image}
                className="thumbarrow thumbarrow-white"
                alt="left-arrow"
            />
            <img
                src={image}
                className="thumbarrow thumbarrow-red"
                alt="right-arrow"
            />
        </div>
    </button>
)

export default ShowDetailsButton
