import styled from 'styled-components'

export const Container = styled.div`
  width: 110px;
  height: 160px;
  border-radius: 6px;
  padding: 8px;
  background-color: var(--clr-neutral-900);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;


  :hover {
    box-shadow: var(--elevation-2);
  }
  &:hover #name {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ImgContainer = styled.div`
  border-radius: 50%;
  width: 86px;
  height: 86px;
  cursor: pointer;
`

export const Name = styled.div.attrs(props => ({
  id: 'name'
}))`
  display: none;
  padding: 2px;
  border-radius: 8px;
  font-size: var(--fs-body-text-medium);
  background-color: var(--clr-secondary-default);
  color: var(--clr-neutral-900);
  position: absolute;
  top: 57%;
  cursor: default;
  left: 50%;
  transform: translate(-50%, -57%);
  cursor: pointer;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  gap: 2px;
  cursor: default;
  font-size: var(--fs-body-text-large);
  font-weight: var(--fw-semibold);
`

export const CategoryPercentage = styled.div`
  cursor: default;

`

export const DateStarted = styled.div`
  cursor: default;

`
