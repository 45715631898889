import styled from 'styled-components'

export const Container = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
`

export const ProfileCertificatesContainer = styled.div`
    width: 580px;
    height: 45vh;
    padding-bottom: 16px;
    overflow-y: scroll;
    display: flex;
    @media (max-width: 1440px) {
        padding-bottom: 8px;

    }
`

export const ProfileContainerContent = styled.div`
    display: flex;
`

export const ProfileContainerCertificatesCards = styled.div`
    width: 580px;
    height: 45vh;
    padding-bottom: 16px;
    padding-right: 4px;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: start;
    text-align: center;
    justify-items: start;
    gap: 14px;

    @media (min-width: 997px) and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
        width: 380px;
    }

    @media (max-width: 997px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 35vh;
    }
`

export const ProfileCertificateCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 8px;
    align-items: center;
    isolation: isolate;
    width: 180px;
    height: 230px;
    border: 1px solid #FCEECC;
    border-radius: 4px;
    background-color: var(--clr-neutral-900);

    @media (max-width: 997px) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;
        height: 162px;
        gap: 24px;
        padding: 16px;
    }
`

export const ProfileCertificateCardText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
    @media (max-width: 997px) {
        align-items: flex-start;
        text-align: left;
    }

`

export const ProfileCertificateCardImage = styled.div`
    box-sizing: border-box;
    width: 164px;
    height: 122px;
    border-radius: 2px;

    @media (max-width: 997px) {
        width: 150px;
        height: 130px;
        min-width: 150px;
        max-width: 150px;
    }
`

export const ProfileCertificateCardTextCertificate = styled.span`
    margin-top: 8px;
    font-weight: var(--fw-medium);
    font-size: var(--fs-body-subtext-large);
    color: var(--clr-neutral-300);
    line-height: var(--lh-tight);

    @media (max-width: 997px) {
        margin-top: 0;
        margin-bottom: 4px;
        font-size: var(--fs-body-text-medium);
        font-weight: var(--fw-semibold);
    }
`

export const ProfileCertificateCardTextDescription = styled.span`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-text-medium);
    color: var(--clr-secondary-default);
    line-height: var(--lh-tight);
    max-width: 160px;
    height: 50px;

    @media (max-width: 997px) {
        font-size: var(--fs-headline-subtitle-medium);
        font-weight: var(--fw-bold);
        height: 94px;
        max-width: 185px;
    }
`

export const ProfileCertificateCardIcoLocked = styled.span`
    position: relative;
    bottom: 45%;
`

export const ProfileCertificateCardIcoApproved = styled.div`
    /* Vector */
    position: relative;
    bottom: 95%;
    left: 80%;
    width: 22px;
    height: 26px;

    @media (max-width: 997px) {
        
    }
`

export const AchievementsCardIssuedIn = styled.div`
    font-family: var(--font-family-body);
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    color: #000000;

    @media (max-width: 997px) {
        font-size: var(--fs-body-subtext-large);
        font-weight: var(--fw-regular);
    }
`
