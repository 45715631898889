import { useEffect, useState } from 'react'
import api from '../services/api'

const useTeams = () => {
  const [organizationUsers, setOrganizationUsers] = useState([])
  const [organizationTeams, setOrganizationTeams] = useState([])
  const [organizationCategories, setOrganizationCategories] = useState([])

  const loadOrganizationTeams = async () => {
    const response = await api.getMethod(`organization/${localStorage.getItem('organizationId')}/teams`)

    if (response.status === 200) {
      setOrganizationTeams(response.data)
    }

    return response
  }

  const loadOrganizationCategories = async () => {
    const response = await api.getMethod(`organization/${localStorage.getItem('organizationId')}/categories`)

    if (response.status === 200) {
      setOrganizationCategories(response.data)
    }

    return response
  }

  const loadOrganizationUsers = async () => {
    const response = await api.getMethod(`organization/${localStorage.getItem('organizationId')}/users`)

    if (response.status === 200) {
      setOrganizationUsers(response.data)
    }

    return response
  }

  useEffect(() => {
    loadOrganizationCategories()
    loadOrganizationUsers()
    loadOrganizationTeams()
  }, [])

  return {
    organizationUsers,
    organizationTeams,
    organizationCategories
  }
}

export default useTeams
