import styled from 'styled-components'

export const UserActivityContainer = styled.div`
    min-width: 300px;
    height: 100%;
    min-height: 100%;
    gap: 8px;
    display: flex;
    flex-direction: column;
`

export const UserActivityContainerInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 300px;
    gap: 12px;
    font-weight: var(--fw-medium);
    font-size: var(--fs-body-text-medium);
    line-height: var(--lh-spaced);
    color: var(--clr-neutral-100);

    @media (max-width: 1440px) {
        font-size: var(--fs-body-subtext-large);
        padding-top: 8px;
    }

`

export const UserActivityContainerIco = styled.span`
    background-color: var(--clr-neutral-800);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    box-shadow: var(--elevation-2);
`

export const ActivityText = styled.div`
    width: 240px;
    max-width: 100%;

    strong {
        font-weight: var(--fw-bold);
    }
`
