import React, { useState } from 'react'
import {
  Container,
  UserImg,
  UserName,
  UserFullName
} from './styled'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import UserInfoModal from '../UserInfoModal'

type Props = {
  userImg: any,
  userName: string,
  userLastName: string,
  userTeam: string,
  userRole: string,
  userBio: string,
  rankingPosition?: any,
  points?: any,
}

export default function UserAvatarImage ({ userImg, userName, userLastName, userTeam, userRole, userBio, rankingPosition, points, ...rest }: Props) {
  const [showModal, setShowModal] = useState<boolean>(false)
  return (
    <>
      {
        showModal
          ? (<UserInfoModal userImg={userImg} userName={`${userName} ${userLastName}`} userTeam={userTeam} userRole={userRole} userBio={userBio} show={showModal} onClick={
            () => setShowModal(false)}
            />)
          : (
            <Container onClick={() => setShowModal(true)} {...rest}>
                <UserImg>
                  <LazyLoadImage src={userImg} width='100%' height='100%' style={{ borderRadius: '50%' }}/>
                </UserImg>
                <UserFullName>
                  <UserName>{userName}</UserName>
                  <UserName>{userLastName}</UserName>
                </UserFullName>
            </Container>)
      }
    </>
  )
}
