/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UserHeader from "../../../components/Auth/Header";
import Item from "../../../components/Auth/SliderView/MainSlider/Item";
import Slider from "../../../components/Auth/SliderView/MainSlider/Slider";
import ToggleButton from "../../../components/ToggleButton";
import Loading from "../../../components/Loading";
import { Video } from "../../../contexts/video";
import api from "../../../services/api";
import { CircularProgressbar } from "react-circular-progressbar";
import "../../../components/Auth/SliderView/MainSlider/ProgressCircle.scss";
import { Container, ProgressCircleContainer } from "./styled";

const SubCategory = (props: any) => {
  const [movies, setMovies] = useState<any>([]);
  const [qtdadeSubcategory, setQtdadeSubcategory] = useState<any>(0);
  const [qtdadeSubcategoryWatch, setQtdadeSubcategoryWatch] = useState<any>(0);
  const [userIsApprovedInChallenge, setUserIsApprovedInChallenge] =
    useState<boolean>(false);

  const history = useHistory();

  function chunkArray(myArray: any[], size: number) {
    const results = [];

    while (myArray.length) {
      results.push(myArray.splice(0, size));
    }

    return results;
  }

  async function verifyIfUserDoesHaveApprovedInChallenge() {
    const { data }: any = await api.getMethod(
      `get_user_micro_certificate_by_sub_category?user_id=${localStorage.getItem(
        "userId",
      )}&sub_category_id=${props.match.params.id}`,
    );
    if (!data.success) {
      return false;
    }
    if (
      parseInt(data.micro_certification.sub_category_id) ===
      parseInt(props.match.params.id)
    ) {
      return true;
    }
    return false;
  }

  async function getProgressVideos() {
    const isAprovedInChallenge =
      await verifyIfUserDoesHaveApprovedInChallenge();
    if (isAprovedInChallenge) {
      setUserIsApprovedInChallenge(true);
      return;
    }
    const SubCategoryId = props.match.params.id;
    const token = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    const { data } = await api.getMethod(
      `get_watch_user_video?SubCategoryId=${SubCategoryId}&token=${token}&id=${userId}`,
    );
    setQtdadeSubcategoryWatch(parseInt(data));
  }

  async function searchMovieById(SubCategoryId: any) {
    const responseSubcategories: any = await api.postMethod(
      "sub_categories_videos",
      { sub_category_id: SubCategoryId, organization_id: localStorage.getItem('organizationId'), team_id: localStorage.getItem('teamId') ?? 0 },
    );

    if (!responseSubcategories.data.success) {
      history.push("/logout");
      return;
    }

    let data = [];

    if (process.env.REACT_APP_ENV === "PROD") {
      data = responseSubcategories.data.data.videos
        .map((movie: any) => {
          return movie.in_production && movie;
        })
        .filter((m: any) => m);
    } else {
      data = responseSubcategories.data.data.videos
        .map((movie: any) => {
          return movie.in_test && movie;
        })
        .filter((m: any) => m);
    }
    setQtdadeSubcategory(parseInt(data.length));

    const result = chunkArray(data, 20);
    for (const videos of result) {
      videos.sort((firstElement: any, secondElement: any) => {
        return firstElement.admin_video_id - secondElement.admin_video_id;
      });
    }

    setMovies(result);
  }

  useEffect(() => {
    getProgressVideos();
    searchMovieById(props.match.params.id);
  }, [props.match.params.id]);

  if (!movies) {
    return <Loading />;
  }

  const percentage = userIsApprovedInChallenge || qtdadeSubcategoryWatch > qtdadeSubcategory
    ? 100
    : Math.floor((qtdadeSubcategoryWatch / qtdadeSubcategory) * 100);

  return (
    <Container>
      <UserHeader />

      <div className="mt-5" />
      <div style={{ marginTop: "7vh" }} className="main-slidersec">
        <h3>{props.match.params.name}</h3>
        <div className="d-flex ml-5">
          <ProgressCircleContainer className="progress-circle-category">
            <CircularProgressbar value={percentage} text={`${percentage}%`} />
          </ProgressCircleContainer>
          <div className="videos-container">
            {movies.map((res: any) => (
              <Slider key={res.index}>
                {res.map((movie: Video, i: number) => (
                  <Item movie={movie} key={movie.admin_video_id} index={i}>
                    item1
                  </Item>
                ))}
              </Slider>
            ))}
          </div>
        </div>
      </div>

      <ToggleButton />
    </Container>
  );
};

export default SubCategory;
