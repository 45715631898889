import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import UserHeader from '../../../components/Auth/Header'
import Item from '../../../components/Auth/SliderView/MainSlider/Item'
import Slider from '../../../components/Auth/SliderView/MainSlider/Slider'
import ToggleButton from '../../../components/ToggleButton'
import Loading from '../../../components/Loading'
import api from '../../../services/api'
import { CircularProgressbar } from 'react-circular-progressbar'
import '../../../components/Auth/SliderView/MainSlider/ProgressCircle.scss'

import { ButtonChange, Container, ProgressCircleContainer } from './styled'

type Props = {
  match: {
    params: {
      id: number,
    }
  }
}

const Category = (props: Props) => {
  const [movies, setMovies] = useState<any>([])
  const [category, setCategory] = useState<any>([])
  const [qtdSubCategoryWatch, setQtdSubCategoryWatch] = useState<number[]>([])
  const [userIsApprovedInChallenge, setUserIsApprovedInChallenge] = useState<boolean>(false)

  const history = useHistory()

  async function verifyIfUserDoesHaveApprovedInChallenge () {
    const { data }: any = await api.getMethod(`get_user_certificate_by_category?user_id=${localStorage.getItem('userId')}&category_id=${props.match.params.id}`)
    if (!data.success) {
      return false
    }
    if (parseInt(data.certification.category_id) === Number(props.match.params.id)) {
      return true
    }
    return false
  }

  async function getProgressVideos (subCategoryIds: Array<number>) {
    const isAprovedInChallenge = await verifyIfUserDoesHaveApprovedInChallenge()
    if (isAprovedInChallenge) {
      setUserIsApprovedInChallenge(true)
      return
    }
    /* eslint-disable prefer-const */
    let dataQtdSubCategoryWatch = []
    for (let subCategoryId of subCategoryIds) {
      const { data }: any = await api.getMethod(`get_watch_user_video?SubCategoryId=${subCategoryId}`)
      dataQtdSubCategoryWatch.push(data)
    }
    setQtdSubCategoryWatch(dataQtdSubCategoryWatch)
  }
  async function getCategory (categoryId: any) {
    const { data } : any = await api.postMethod('get_category_id', {
      page_type: 'CATEGORY',
      category_id: categoryId
    })
    setCategory(data)
  }

  async function searchMovieById (categoryId: any) {
    const { data }: any = await api.postMethod('home_second_section', {
      page_type: 'CATEGORY',
      category_id: categoryId,
      organization_id: localStorage.getItem('organizationId'),
      team_id: localStorage.getItem('teamId') ?? 0
    })

    if (!data.success) {
      history.push('/logout')
      return
    }

    const categoriesSection = data.data.map((movie: any) => {
      const allMovies = process.env.REACT_APP_ENV === 'PROD' ? movie.data.filter((i: any) => i.in_production) : movie.data.filter((i: any) => i.in_test)
      return {
        ...movie,
        data: allMovies
      }
    }).filter((item: any) => item.data.length > 0)

    setMovies(categoriesSection)

    const subCategoryIds = data.data.map((main: any) => {
      return main.url_page_id
    })
    await getProgressVideos(subCategoryIds)
  }
  useEffect(() => {
    searchMovieById(props.match.params.id)
    getCategory(props.match.params.id)
  }, [])
  async function goToLink () {
    await api.postMethod('insert_new_challenge', {
      user_id: localStorage.getItem('userId'),
      challenge_category_id: props.match.params.id,
      challenge_type: 'category',
      challenge_description: localStorage.getItem('userId') + ' - ' + localStorage.getItem('name') + ': ' + localStorage.getItem('active_profile_name'),
      challenge_status: null
    })
    if (category.length > 0 && category[0].challenge) {
      const challenge = category[0].challenge
      if (challenge.indexOf('https://') < 0 && challenge.indexOf('http://') < 0) {
        window.open('https://' + challenge, '_blank')
      } else {
        window.open(challenge, '_blank')
      }
    }
  }
  function renderVideoList ({ main, index }: { main: any; index: number }) {
    const valueByCategory = qtdSubCategoryWatch[index]
    const qtdSubcategoryWatchInt = Number(valueByCategory)
    const qtdSubCategory = main.data.length
    const percentage = userIsApprovedInChallenge || qtdSubcategoryWatchInt > qtdSubCategory ? 100 : Math.floor((qtdSubcategoryWatchInt / qtdSubCategory) * 100)

    return (
      <React.Fragment key={index}>
        <div className="main-slidersec">
          <Link
            to={`/sub-category/${main.url_page_id}/${main.title}`}
          >
            <h3>
              {main.title}
              <i className="fas fa-angle-right ml-2" />
            </h3>
          </Link>
          <div>
          <div className="items d-flex align-items-center ml-5">
            <ProgressCircleContainer className="progress-circle-category">
              <CircularProgressbar value={percentage} text={`${percentage}%`} />
            </ProgressCircleContainer>
            <div className="videos-in-category">
              <Slider className="slider">
                {main.data.map((movie: any, index: any) => <Item className='video-thumb' movie={movie} key={movie.admin_video_id} index={index} />)}
              </Slider>
            </div>
          </div>
          </div>
        </div>
      </React.Fragment>
    )
  };

  if (!movies) {
    return <Loading />
  }

  return (
    <Container>
      <UserHeader />
      <div className="mt-5" />
      <br/>
      <div className='button-challenge-container'>
        <ButtonChange onClick={() => goToLink()}>Challenge</ButtonChange>
      </div>
      {
        movies.length > 0
          ? movies.map((main: any, index: any) => renderVideoList({ main, index }))
          : <div className="main-slidersec"><h3>No video for this category</h3></div>
      }
      <ToggleButton />
    </Container>
  )
}

export default Category
