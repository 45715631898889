import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import useTeams from '../../../hooks/useTeams'
import Loading from '../../Loading'

import api from '../../../services/api'

import {
  ContainerDiv,
  InputDefault,
  InputLabel,
  ContainerOrganizationForm,
  TitleForm
} from './styled'
import { ButtonOrange } from '../../Buttons'

const animatedOptions = makeAnimated()

type TeamId = {
  teamName: string
  teamId: number
  setTeamName?: (teamName: string) => void
}

const EditTeamForm = ({ teamName, teamId }: TeamId) => {
  const { organizationUsers, organizationCategories } = useTeams()
  const [selectedUsers, setSelectedUsers] = useState([])
  const [isChecked, setIsChecked] = useState(false)
  /* const [teamCategories, setTeamCategories] = useState([]) */
  const [teamname, setTeamName] = useState(teamName)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  const editTeam = async () => {
    const formData = {
      name: teamname,
      organizationId: localStorage.getItem('organizationId'),
      /* teamCategories: JSON.stringify(teamCategories), */
      members: JSON.stringify(selectedUsers),
      deleteTeam: isChecked
    }

    const response: any = await api.postMethod(`organization/teams/form/${teamId}`, formData)

    if (response.status === 201) {
      localStorage.setItem('team', response.data.data.name)
      alert('Team updated successfully!')
      window.location.href = '/manage-organization'
    }
  }

  if (!organizationUsers && !organizationCategories) {
    return <Loading />
  }

  return (
    <React.Fragment>
      <ContainerDiv style={{ position: 'absolute', marginLeft: '5%', top: '12%' }}>
        <Link to='/manage-organization/edit-team'>
          <ButtonOrange
            as="button"
            type="button"
            className='btn'
          >
            Back
          </ButtonOrange>
        </Link>
      </ContainerDiv>

      <ContainerDiv style={{ position: 'absolute', marginLeft: '5%', top: '12%' }}>
        <Link to='/manage-organization/edit-team'>
          <ButtonOrange
            as="button"
            type="button"
            className='btn'
          >
            Back
          </ButtonOrange>
        </Link>
      </ContainerDiv>
      <ContainerOrganizationForm>
        <TitleForm>Edit Team</TitleForm>
        <ContainerDiv className="container">
          <ContainerDiv className="form-group mt-2">
            <InputLabel htmlFor="teamName">Team Name</InputLabel>
            <InputDefault
              className="form-control"
              id="teamName"
              name="teamName"
              placeholder="Team Name"
              value={teamname}
              minLength={1}
              onChange={(e: any) => setTeamName(e.target.value)}
            />
          </ContainerDiv>

          <ContainerDiv className="form-group mt-2">
            <InputLabel htmlFor="categories[]">Team Members:</InputLabel>
            <Select
              onChange={(item: any) => setSelectedUsers(item)}
              components={animatedOptions}
              options={organizationUsers}
              closeMenuOnSelect={false}
              className="basic-multi-select"
              classNamePrefix="select"
              name="members_team[]"
              isMulti
              required
            />
          </ContainerDiv>

          <ContainerDiv className="form-group mt-2">
            <input type="checkbox" id="deleteTeam" name="deleteTeam"
              checked={isChecked}
              onChange={handleCheckboxChange} />
            <label htmlFor="deleteTeam">Do you want to remove current members for these?</label>
          </ContainerDiv>

          {/* <ContainerDiv className="form-group mt-2">
            <InputLabel htmlFor="categories[]">Team Categories:</InputLabel>
            <Select
              onChange={(item: any) => setTeamCategories(item)}
              components={animatedOptions}
              options={organizationCategories}
              closeMenuOnSelect={false}
              className="basic-multi-select"
              classNamePrefix="select"
              name="team_categories[]"
              isMulti
              required
            />
          </ContainerDiv> */}

          <ButtonOrange
            as="button"
            type="button"
            className='btn'
            data-dismiss="modal"
            onClick={() => editTeam()}
          >
            Update Team
          </ButtonOrange>
        </ContainerDiv>
      </ContainerOrganizationForm>
    </React.Fragment>
  )
}

export default EditTeamForm
