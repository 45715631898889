/* eslint-disable camelcase */
import React from 'react'
import { Link } from 'react-router-dom'

import {
  ContainerOrganizationTeamsCard,
  TeamsCard,
  FooterDefault,
  ParagraphDefault,
  CenteredDiv
} from './styled'
import { ButtonOrange } from '../../Buttons'
import useTeams from '../../../hooks/useTeams'
import api from '../../../services/api'

type Team = {
  id: number
  organization_id: number
  name: string
  created_at: string
  updated_at: string
}

const OrganizationTeamsCards = () => {
  const { organizationTeams } = useTeams()
  console.log(organizationTeams)

  const deleteTeam = async (teamId: number, teamName: string) => {
    const verifyDeleteTeam = confirm(`This action will delete the ${teamName} team permanently.`)

    if (verifyDeleteTeam) {
      await api.postMethod(`organization/teams/delete/${teamId}`, { teamName })
      window.location.href = '/manage-organization/edit-team'
    }
  }

  return (
    <React.Fragment>
      {organizationTeams.length === 0
        ? <CenteredDiv>There are no teams created yet.</CenteredDiv>
        : <ContainerOrganizationTeamsCard>
            {organizationTeams.map((team: Team, _) => {
              return (
                  <TeamsCard key={team.id}>
                    <ParagraphDefault>
                      <Link to={`/manage-organization/team-overview/${team.name}/${team.id}`}>{ team.name }</Link>
                    </ParagraphDefault>
                    <FooterDefault>
                      <Link to={`/manage-organization/team-form/${team.name}/${team.id}`}>
                        <ButtonOrange
                          as="button"
                          type="button"
                          className='btn'
                        >
                          Edit Team
                        </ButtonOrange>
                      </Link>

                      {/* <Link to={`/manage-organization/team-essential-form/${team.name}/${team.id}`}>
                        <ButtonOrange
                          as="button"
                          type="button"
                          className='btn'
                        >
                          Essential Categories
                        </ButtonOrange>
                      </Link> */}

                      <ButtonOrange
                          as="button"
                          type="button"
                          className='btn'
                          onClick={() => deleteTeam(team.id, team.name)}
                        >
                          Delete Team
                        </ButtonOrange>
                    </FooterDefault>
                  </TeamsCard>
              )
            })
          }
        </ContainerOrganizationTeamsCard>
      }
    </React.Fragment>
  )
}

export default OrganizationTeamsCards
