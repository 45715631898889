import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// import ImgUser from '../../../assets/images/social-image.png'
import {
  Container,
  FilledContainer,
  ImgContainer,
  InfoCompletionContainer,
  Mark,
  Percentage,
  Name
} from './styled'

interface Props {
  userImg: any,
  percentage: number,
  name?: string
}

export function TrackerUserItem ({ userImg, percentage, name }: Props) {
  function correctBarSidesWidth (percentage: number) {
    if (percentage <= 5) {
      return percentage + 3
    }
    if (percentage > 5 && percentage <= 45) {
      return percentage + 2
    }
    if (percentage > 45 && percentage < 55) {
      return percentage
    }
    if (percentage >= 55 && percentage < 85) {
      return percentage - 2
    }
    if (percentage >= 85) {
      return percentage - 3
    }
  }

  const correctPercentage = correctBarSidesWidth(percentage)

  return (
    <Container>
      <InfoCompletionContainer>
        <Mark>
          <text>0</text>
          <div></div>
        </Mark>
        <Mark>
          <text>25</text>
          <div></div>
        </Mark>
        <Mark>
          <text>50</text>
          <div></div>
        </Mark>
        <Mark>
          <text>75</text>
          <div></div>
        </Mark>
        <Mark>
          <text>100</text>
          <div></div>
        </Mark>
      </InfoCompletionContainer>
      <FilledContainer>
        <div id='fill' style={{ height: '100%', width: `${correctPercentage}%`, borderRadius: '250px' }}></div>
        <div id='pointer' style={{ height: '0', width: '0' }}>
          <ImgContainer >
            <LazyLoadImage src={userImg} width='100%' height='100%' />
            <Name id='name' >{name}</Name>

            <Percentage>{percentage}%</Percentage>
          </ImgContainer>

        </div>
      </FilledContainer>
    </Container>
  )
}
