/* eslint-disable */
import { useState, FC } from "react";
import styled, { css } from "styled-components";

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const Arrow = styled.div<{ isUp: boolean }>`
  & > svg {
    fill: black;
    ${({ isUp }) => (isUp ? "" : "transform: rotate(180deg);")}
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface UpDownArrowsProps {
  onToggle: (isUp: boolean) => void;
}

const UpDownArrows: FC<UpDownArrowsProps> = ({ onToggle }) => {
  const [isUp, setIsUp] = useState(false);

  const handleClick = () => {
    const newState = !isUp;
    setIsUp(newState);
    onToggle(newState);
  };

  return (
    <ArrowContainer
      onClick={handleClick}
      aria-label={isUp ? "Up Arrow" : "Down Arrow"}
    >
      <Arrow isUp={isUp}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="22"
          width="22"
          viewBox="0 0 512 512"
        >
          <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
        </svg>
      </Arrow>
    </ArrowContainer>
  );
};

export default UpDownArrows;
