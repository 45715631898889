import styled from 'styled-components'

export const AchievementsCardContainer = styled.div`
    /* Card Certificate Container */
    height: 45vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-right: 4px;
    :empty {
        display: none;
    }

    @media screen and (max-width: 997px) {
        height: 35vh;

    }
`

export const AchievementsCard = styled.div`
    /* Card Certificate */
    display: flex;
    background: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    max-height: 100%;
    padding: 16px;
    gap: 24px;
    font-family: var(--font-family-body);

    @media (min-width: 997px) and (max-width: 1390) {
        width: 400px;
        max-width: 400px;
        min-width: 400px;
    }

    @media screen and (max-width: 1399.98px) {
        max-width: 100%;
    }

    @media (max-width: 997px) {
        width: 100%;
    }
`

export const AchievementsCardDivisor = styled.hr`
    /* Card Certificate Divisor */
    margin: 0px;
`

export const AchievementsCardImage = styled.div`
    box-sizing: border-box;
    background-color: #FFFFFF;
    border: 0.5px solid #C5C0DB;
    border-radius: 2px;
    width: 120px;
    height: 100px;

    @media (max-width: 997px) {
        width: 150px;
        height: 130px;
        min-width: 150px;
        max-width: 150px;
    }
`

export const AchievementsCardInfoContainer = styled.div`
    /* Card Certificate Container */
    background: #FFFFFF;
    border-radius: 4px;
    /* width: 100%; */
    gap: 24px;
    font-family: var(--font-family-body);

    @media screen and (max-width: 1399.98px) {
        max-width: 100%;
    }
`

export const AchievementsCardType = styled.div`
    font-family: var(--font-family-body);
    font-weight: var(--fw-medium);
    font-size: var(--fs-body-subtext-large);
    color: var(--clr-neutral-300);
    line-height: var(--lh-tight);
    margin-bottom: -2px;

    @media (max-width: 997px) {
        margin-top: 0;
        margin-bottom: 4px;
        font-size: var(--fs-body-text-medium);
        font-weight: var(--fw-semibold);
    }
`

export const AchievementsCardTitle = styled.div`
    font-family: var(--font-family-body);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: var(--clr-primary-dark);
    margin-top: 4px;
    height: 68px;

    @media (max-width: 997px) {
        font-size: var(--fs-headline-subtitle-medium);
        font-weight: var(--fw-bold);
        height: 94px;

    }
`

export const AchievementsCardIssuedIn = styled.div`
    font-family: var(--font-family-body);
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    color: #000000;

    @media (max-width: 997px) {
        font-size: var(--fs-body-subtext-large);
        font-weight: var(--fw-medium);
    }
`

export const AchievementsCardDescription = styled.div`
    font-style: normal;
    font-family: var(--font-family-body);
    font-weight: var(--fw-medium);
    font-size: 12px;
    line-height: 100%;
    color: #2D2935;
`
