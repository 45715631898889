import React, { useEffect, useState } from 'react'
import api from '../../../services/api'
import ProfilePictureUpload from '../ProfilePictureUpload'
import { ButtonOrange } from '../../../components/Buttons'

const ProfileUserForm = () => {
  const [user, setUser] = useState([])
  const [userId, setUserId] = useState('')
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [team, setTeam] = useState('')
  const [role, setRole] = useState('')
  const [bio, setBio] = useState('')
  const [mobile, setMobile] = useState('')
  const [isSuperUser, setIsSuperUser] = useState(false)

  const getUser = async () => {
    const { data }: any = await api.getMethod(`user/${Number(localStorage.getItem('userId'))}/infos`)
    if (data.length <= 0) {
      return
    }
    const isSuperUserCurrent = Boolean(Number(localStorage.getItem('isSuperUser')))
    setIsSuperUser(isSuperUserCurrent)
    setUser(data)
  }

  const closeModal = () => {
    setUserId('')
    setTeam('')
    setRole('')
    setBio('')
    setName('')
    setLastName('')
    setMobile('')
  }

  const getUserDetails = (user: any) => {
    setUserId(user.id)
    setTeam(user.team)
    setRole(user.role)
    setBio(user.bio)
    setName(user.name)
    setLastName(user.last_name)
    setMobile(user.mobile)
  }

  const editUser = async () => {
    if (mobile === undefined || mobile === null) {
      setMobile('')
    }

    const formData = {
      userId,
      team,
      role,
      bio,
      name,
      lastName,
      mobile
    }

    await api.postMethod(`user/${Number(localStorage.getItem('userId'))}/infos`, formData)
    getUser()
  }

  const getUserInfo = async () => {
    await getUser()
    getUserDetails(user)
  }

  useEffect(() => {
    getUserInfo()
  }, [userId])

  return (
    <>
      <div className="modal fade" id="modalUser" tabIndex={-1} role="dialog" aria-labelledby="modalUserLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" style={{ fontWeight: '600' }}>Update your information:</h5>
              <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="form-group mt-2">
                  <ProfilePictureUpload />
                </div>
                <div className="form-group mt-2">
                  <input
                    onChange={text => setName(text.target.value)}
                    required
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={name}
                    minLength={2}
                  />
                </div>
                <div className="form-group mt-2">
                  <input
                    onChange={text => setLastName(text.target.value)}
                    required
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    value={lastName}
                    minLength={2}
                  />
                </div>
                {
                  isSuperUser
                    ? <div className="form-group mt-2">
                        <input
                          onChange={text => setTeam(text.target.value)}
                          className="form-control"
                          id="team"
                          name="team"
                          placeholder="Team"
                          value={team}
                          minLength={2}
                        />
                      </div>
                    : <div className="form-group mt-2">
                        <input
                          onChange={text => setTeam(text.target.value)}
                          className="form-control"
                          id="team"
                          name="team"
                          placeholder="Team"
                          value={team}
                          minLength={2}
                          readOnly
                        />
                      </div>
                }
                {
                  isSuperUser
                    ? <div className="form-group mt-2">
                        <input
                          onChange={text => setRole(text.target.value)}
                          className="form-control"
                          id="role"
                          name="role"
                          placeholder="Role"
                          value={role}
                          minLength={2}
                        />
                      </div>
                    : <div className="form-group mt-2">
                        <input
                          onChange={text => setRole(text.target.value)}
                          className="form-control"
                          id="role"
                          name="role"
                          placeholder="Role"
                          value={role}
                          minLength={2}
                          readOnly
                        />
                      </div>
                }
                <div className="form-group mt-2">
                  <input
                    onChange={text => setMobile(text.target.value)}
                    className="form-control"
                    id="mobile"
                    name="mobile"
                    placeholder="Phone"
                    value={mobile}
                    maxLength={16}
                  />
                </div>
                <div className="form-group mt-2">
                  <textarea
                    onChange={text => setBio(text.target.value)}
                    className="form-control"
                    id="bio"
                    name="bio"
                    placeholder="Writing your bio..."
                    value={bio}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <ButtonOrange
                as="button"
                type="button"
                className='btn'
                data-dismiss="modal"
                onClick={() => editUser()}
              >
                Update Info
              </ButtonOrange>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileUserForm
