import React from 'react'
import Header from '../../../../components/Auth/Header'

import { ContainerMainContent } from './styled'
import TeamForm from '../../../../components/Auth/TeamForm'

const CreateTeam = () => {
  return (
    <React.Fragment>
      <Header />
      <ContainerMainContent>
        <TeamForm formTitle='Create a New Team' />
      </ContainerMainContent>
    </React.Fragment>
  )
}

export default CreateTeam
