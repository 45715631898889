/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { AxiosResponse } from 'axios'
import api from '../../../services/api'
import Header from '../../../components/Auth/Header'
import ToggleButton from '../../../components/ToggleButton'
import Loading from '../../../components/Loading'
import Banner from '../../../components/Auth/Banner'
import AchievementsCertificates from '../../../components/Auth/AchievementsCertificates'
// import leftArrow from '../../../assets/images/arrow-left.svg'
import ArrowRightGold from '../../../assets/images/arrow-right-gold.svg'
import {
  // AchievementsButtonGoBack,
  AchievementsTitlePage,
  AchievementsContainer,
  AchievementsContainerCertificates,
  AchievementsContainerBadges,
  AchievementsButtonContinueLearning,
  Container
} from './styled'
import CertificatesPageCards from '../../../components/Auth/CertificatesPageCards'

const Achievements = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [lastVideoWatched, setLastVideoWatched] = useState<any[any]>([])

  const getLinkContinueLearningButton = async () => {
    const data: AxiosResponse<any[any]> = await api.getMethod(`last-video-watched-by-user/${parseInt(localStorage.getItem('userId') as string)}`)
    setLastVideoWatched(data?.data?.id)
    setLoading(false)
  }

  useEffect(() => {
    getLinkContinueLearningButton()
  }, [])

  if (loading) {
    <Loading />
  }

  return (
    <Container>
      <Header />
      <Banner />
      {/* <AchievementsButtonGoBack><Link to='/dashboard'><LazyLoadImage src={leftArrow} width='12px' style={{ marginRight: '8px', marginTop: '-2px' }} /> Back to home</Link></AchievementsButtonGoBack> */}
      <AchievementsContainer>
        <AchievementsContainerCertificates>
            <AchievementsTitlePage>Your certificates</AchievementsTitlePage>
            <CertificatesPageCards />
            {(lastVideoWatched &&
              <Link to={`/video/${lastVideoWatched}`}>
                <AchievementsButtonContinueLearning>
                    Earn more certificates
                  <LazyLoadImage src={ArrowRightGold} width='12px' style={{ marginTop: '1px', paddingLeft: '4px' }} />
                </AchievementsButtonContinueLearning>
              </Link>
            )}
        </AchievementsContainerCertificates>
        <AchievementsContainerBadges>
            <AchievementsTitlePage>Available certificates</AchievementsTitlePage>
            <AchievementsCertificates typeCertificates={'remaining'} />
        </AchievementsContainerBadges>
      </AchievementsContainer>
      <ToggleButton />
    </Container>
  )
}

export default Achievements
