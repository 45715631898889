import React from 'react'
import {
  Container,
  DateStarted,
  Info,
  ImgContainer,
  CategoryPercentage,
  Name
} from './styled'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import api from '../../../services/api'

interface Props {
  userId: number,
  userImg: string,
  categoryId: number,
  categorySelected: any,
  percentage: number,
  dateStarted: string,
  name: string
}

export function AlertsUserItem ({ userId, userImg, categoryId, categorySelected, percentage, dateStarted, name }:Props) {
  function setDataFormat (date:string) {
    const dateParts = date.split('/')
    const day = dateParts[0]
    const month = dateParts[1]
    const year = dateParts[2]

    const formatedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    return formatedDate
  }

  const startDate = setDataFormat(dateStarted)
  const currentDate = new Date().toJSON().slice(0, 10)

  function calculateDiffInDays (current:string, start:string) {
    const diffInMs = new Date(current).valueOf() - new Date(start).valueOf()
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24)
    return diffInDays
  }

  const daysDifference = calculateDiffInDays(currentDate, startDate)

  function setDateColor () {
    if (daysDifference <= 7) {
      const color = '#00C54A'
      return color
    } else if (daysDifference > 7 && daysDifference <= 14) {
      const color = '#FF9C2B'
      return color
    } else if (daysDifference > 14) {
      const color = '#F13C46'
      return color
    }
  }

  function setPercentageColor () {
    if (percentage <= 30) {
      const color = '#F13C46'
      return color
    } else if (percentage > 30 && percentage <= 70) {
      const color = '#FF9C2B'
      return color
    } else if (percentage > 70) {
      const color = '#00C54A'
      return color
    }
  }

  async function sendAlert (userId: number) {
    const sendEmailToUser = confirm('Do you want to send an alert to the user regarding their performance in the category?')

    if (sendEmailToUser) {
      console.log(categoryId)
      const response = await api.getMethod(`dashboard/team/${userId}`, {
        supervisor_name: localStorage.getItem('name'),
        category_id: categoryId,
        category_name: categorySelected.label,
        percentage: percentage
      })

      if (response.status === 200) {
        alert('Alert send successfully!')
      } else {
        alert('Alert send failed.')
      }
    }
  }

  return (
    <Container>
        <ImgContainer onClick={() => sendAlert(userId)}>
          <LazyLoadImage src={userImg} width='100%' height='100%' />
          <Name id='name' >{name}</Name>
        </ImgContainer>
        <Info>
          <CategoryPercentage style={{ color: setPercentageColor() }}>
            {Math.ceil(percentage)}%
          </CategoryPercentage>
          <DateStarted style={{ color: setDateColor() }}>
            {dateStarted}
          </DateStarted>
        </Info>
    </Container>
  )
}
