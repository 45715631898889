import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import Loading from '../../../../components/Loading'
import api from '../../../../services/api'
const SuccessContract = (props: any) => {
  const history = useHistory()

  async function paymentApiCall (params: any) {
    const { data }: any = await api.postMethod('v4/subscriptions_payment', params)
    if (data.success) {
      toast.success(data.message)
      localStorage.setItem('isSuperUser', '1')
      setTimeout(() => {
        history.push('/add-team-member')
      }, 5000)
    } else toast.error(data.error_messages)
  };

  useEffect(() => {
    const paramsInObject: any = {}
    props.location.search.substr(1).split('&').forEach((param: any) => (paramsInObject[param.split('=')[0]] = param.split('=')[1]))
    paymentApiCall(paramsInObject)
  }, [])

  return <Loading />
}

export default SuccessContract
