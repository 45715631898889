import React, { useEffect, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styled from 'styled-components'
import { ButtonOrange } from '../Buttons'
import loadingGif from '../../assets/images/loading.gif'

const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--orange-primary);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--orange-primary) transparent transparent transparent;
    
    :nth-child(1) {
      animation-delay: -0.45s;
    }

    :nth-child(2) {
      animation-delay: -0.3s;
    } 

    :nth-child(3) {
      animation-delay: -0.15s;
    }
  }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`

const Loading = () => {
  const [loading, setLoading] = useState(true)
  const [countDown, setCountDown] = useState(10)

  const location = useLocation()
  const history = useHistory()

  function count () {
    setTimeout(() => {
      if (countDown === 0) {
        setLoading(false)
      } else {
        setCountDown(countDown - 1)
      }
    }, 1000)

    setTimeout(() => {
      localStorage.setItem('first_login', '0')
    }, 3000)
  }

  useEffect(() => {
    if (Number(localStorage.getItem('logged_in_account')) === 1) {
      history.push('/home')
    }

    if (location.pathname === '/login') {
      history.push('/home')
    } else count()
  }, [countDown])

  if (loading) {
    return (
      <LoadingContainer>
        <LazyLoadImage src={loadingGif} />
        {location.pathname === '/home' && localStorage.getItem('first_login') === '1' && <p style={{ color: 'var(--orange-primary)' }}>Your personal video platform access is being built!</p>}
      </LoadingContainer>
    )
  }

  return (
    <LoadingContainer>
      <main className="text-center">
        <h2 className="mb-5">Welcome to Manufacturing Masters, Please sign in</h2>
        <Link to="/logout">
          <ButtonOrange>
            Sign in
          </ButtonOrange>
        </Link>
      </main>
    </LoadingContainer>
  )
}

export default Loading
