import styled from 'styled-components'

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`

export const ModalText = styled.p`
  color: var(--clr-neutral-100);
`

export const FooterModalOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ModalButtonConfirm = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`

export const ModalButtonCancel = styled.button`
  background: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`
