import React from 'react'
import { t } from 'react-multi-lang'
import { Link } from 'react-router-dom'

import Header from '../../../components/Auth/Header'
import ToggleButton from '../../../components/ToggleButton'

import {
  ContainerDiv,
  ContainerLi,
  ContainerManageOrganization,
  ContainerUl,
  TitlePageH1,
  TitlePageH4
} from './styled'

const ManageOrganization = () => {
  return (
    <React.Fragment>
      <Header />
      <ContainerManageOrganization>
        <ContainerDiv className="row">
          <ContainerDiv className="col-sm-12 col-md-12 col-lg-10 col-xl-9 mx-auto mt-5">

            <ContainerDiv className="account-title-sec">
              <TitlePageH1 className="">{t('manage')}</TitlePageH1>
            </ContainerDiv>

            <ContainerDiv className="account-sec">
              <ContainerDiv className="row">
                <ContainerDiv className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                  <TitlePageH4 className="account-sec-head text-blue-logo">
                    {t('organization')}
                  </TitlePageH4>
                </ContainerDiv>
                <ContainerDiv className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                  <ContainerUl className="account-nav-link">
                    <ContainerLi>
                      <Link to="/manage-organization/edit">
                        {t('edit_organization')}
                      </Link>
                    </ContainerLi>
                    {/* <ContainerLi>
                      <Link to="/manage-organization/essentials-categories">
                        {t('edit_essentials_categories')}
                      </Link>
                    </ContainerLi>
                    <ContainerLi>
                      <Link to="/manage-organization/order-categories">
                        {t('edit_order_categories')}
                      </Link>
                    </ContainerLi> */}
                  </ContainerUl>
                </ContainerDiv>
              </ContainerDiv>
            </ContainerDiv>
          </ContainerDiv>

          <ContainerDiv className="col-sm-12 col-md-12 col-lg-10 col-xl-9 mx-auto mt-5">
            <ContainerDiv className="account-sec">
              <ContainerDiv className="row">
                <ContainerDiv className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                  <TitlePageH4 className="account-sec-head text-blue-logo">
                    {t('team')}
                  </TitlePageH4>
                </ContainerDiv>
                <ContainerDiv className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                  <ContainerUl className="account-nav-link">
                    <ContainerLi>
                      <Link to="/manage-organization/create-team">
                        {t('create_team')}
                      </Link>
                    </ContainerLi>
                    <ContainerLi>
                      <Link to="/manage-organization/edit-team">
                        {t('edit_team')}
                      </Link>
                    </ContainerLi>
                  </ContainerUl>
                </ContainerDiv>
              </ContainerDiv>
            </ContainerDiv>
          </ContainerDiv>

          <ContainerDiv className="col-sm-12 col-md-12 col-lg-10 col-xl-9 mx-auto mt-5">
            <ContainerDiv className="account-sec">
              <ContainerDiv className="row">
                <ContainerDiv className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                  <TitlePageH4 className="account-sec-head text-blue-logo">
                    {t('content')}
                  </TitlePageH4>
                </ContainerDiv>
                <ContainerDiv className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                  <ContainerUl className="account-nav-link">
                    <ContainerLi>
                      <Link to="/manage-organization/learning-path-creator">
                        {t('learning-path-creator')}
                      </Link>
                    </ContainerLi>
                  </ContainerUl>
                </ContainerDiv>
              </ContainerDiv>
            </ContainerDiv>
          </ContainerDiv>

          <ContainerDiv className="col-sm-12 col-md-12 col-lg-10 col-xl-9 mx-auto mt-5">
            <ContainerDiv className="account-sec">
              <ContainerDiv className="row">
                <ContainerDiv className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                  <TitlePageH4 className="account-sec-head text-blue-logo">
                    {t('users')}
                  </TitlePageH4>
                </ContainerDiv>
                <ContainerDiv className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                  <ContainerUl className="account-nav-link">
                    <ContainerLi>
                      <Link to="/manage-team">
                        {t('manage_users')}
                      </Link>
                    </ContainerLi>
                  </ContainerUl>
                </ContainerDiv>
              </ContainerDiv>
            </ContainerDiv>
          </ContainerDiv>
        </ContainerDiv>
      </ContainerManageOrganization>
      <ToggleButton />
    </React.Fragment>
  )
}

export default ManageOrganization
