import styled from 'styled-components'

export const Container = styled.button`
  outline: none;
  width: 100%;
  max-width: 352px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 8px;
  background-color: transparent;
  border: none;

  :focus {
    outline: none;
    box-shadow: var(--elevation-2);

  }

  :hover {
    background-color: var(--clr-neutral-800);
    border-radius: 4px;
  }

  div {
    display: flex;
  }
`

export const UserImg = styled.div`
  border-radius: 50%;
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 8px;
`

export const UserName = styled.text`
  font-size: var(--fs-body-text-large);
  font-weight: var(--fw-medium);
  display: inline;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1200px) {
    font-size: var(--fs-body-text-medium);
    max-width: 155px;

  }
`
export const UserJobInfo = styled.div`
  font-size: var(--fs-body-subtext-large);
  font-weight: var(--fw-regular);
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
`

export const UserTeam = styled.text`
  font-size: var(--fs-body-subtext-large);
  font-weight: var(--fw-medium);
  display: inline;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const UserRole = styled.text`
  font-size: var(--fs-body-subtext-large);
  font-weight: var(--fw-medium);
  color: var(--clr-primary-light);
  display: inline;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ListAction = styled.div`
  width: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Points = styled.text`
  font-size: var(--fs-body-subtext-medium);
  font-weight: var(--fw-medium);

`

export const Ranking = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RankingPosition = styled.text`
  font-size: var(--fs-body-text-large);
  font-weight: var(--fw-semibold);
  line-height: var(--lh-tight);

  span {
    font-size: var(--fs-body-text-medium);
    font-weight: var(--fw-semibold);
  }
`
