import React from 'react'
import Header from '../../../../components/Auth/Header'
import TeamOverviewCard from '../../../../components/Auth/TeamOverviewCard'

import {
  ContainerMainContent
} from './styled'

type Props = {
  match: {
    params: {
      name: string,
      id: number
    }
  }
}

const TeamOverview = (props: Props) => {
  return (
    <React.Fragment>
      <Header />
      <ContainerMainContent>
        <TeamOverviewCard teamName={props.match.params.name} teamId={props.match.params.id} />
      </ContainerMainContent>
    </React.Fragment>
  )
}

export default TeamOverview
