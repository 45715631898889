import styled from 'styled-components'

export const ContainerPlan = styled.div`
  margin-top: 6em;
  padding: 0 55px;
  
  .plan-card {
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1em;
  }

  .plan-head{
    padding: 1em;
    font-size: 1.28em;
    text-transform: uppercase;
    color: #333;
  }

  .plan-price{
    background-color: #000;
    color: #fff;
    padding: 1em;
  }

  .plan-price p{
    font-size: 1.14em;
    text-transform: uppercase;
    margin-bottom: .5em;
  }

  .plan-details{
    padding: 1em;
    color: #999;
  }

  .plan-details h4 {
    font-size: 1.14em;
    line-height: 1.14;
    line-height: 1.375;
    margin-bottom: .5em;
    text-transform: capitalize;
  }

  .plan-details h5{
    color:#333;
    margin-bottom: .6em;
  }

  .plan-details p{
    height: 7.5em;
    overflow-y:scroll;
    overflow-x: hidden;
    margin-bottom: 0;
  }
  .plan-price.premium{
    background-color: #F05024;
  }

`
