import styled from 'styled-components'

export const Container = styled.div`
    background-color: var(--gray-bg);
    height: 100%;

`

export const AchievementsButtonGoBack = styled.button`
    position: relative;
    left: 7%;
    top: 0px;
    padding: 13.5px 16px;
    font-family: var(--font-family-body);
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-subtext-large);
    line-height: 130%;
    display: flex;
    text-align: center;
    color: var(--clr-primary-dark);
    background-color: var(--clr-secondary-light);
    border: none;
    border-radius: 14px;

    :hover {
        background-color: var(--clr-secondary-default);
    }

    :focus {
        outline: none;
    }

    @media screen and (max-width: 767.97px) {
        top: -50px;
        color: black;

        img {
            border-radius: 4px;
            padding: 4px 2px 4px 2px;
        }
    }

    @media screen and (min-width: 767.98px) and (max-width: 1399.98px) {
        top: -50px;
    }
`

export const AchievementsTitlePage = styled.h1`
    /* Your certificates and badges */
    width: 350px;
    height: 40px;
    font-family: var(--font-family-headline);
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: #2D2935;

    @media screen and (max-width: 767.97px) {
        width: 300px;
        font-size: var(--fs-headline-subtitle-large);
        margin-top: 20px;
        margin-left: 4px;
    }

    @media screen and (min-width: 767.98px) and (max-width: 1399.98px) {
        width: 100%;
        font-size: 20px;
    }
`

export const AchievementsTitleCertificates = styled.h3`
    /* Certificates */
    width: 220px;
    height: 24px;
    left: 240px;
    top: 70px;
    font-family: var(--font-family-body);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    color: #3A3847;

    @media screen and (max-width: 1399.98px) {
        font-size: 16px;
    }
`

export const AchievementsTitleBadges = styled.h3`
    /* Badges */
    width: 230px;
    height: 24px;
    left: 1060px;
    top: 70px;
    font-family: var(--font-family-body);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    color: #3A3847;
    
    @media screen and (max-width: 1399.98px) {
        font-size: 16px;
    }
`

export const AchievementsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-inline: 100px;
    font-family: var(--font-family-body);
    font-size: var(--fs-body-subtext-large);

    @media screen and (max-width: 997px) {
        flex-direction: column;
        margin-inline: 2vw;
        padding-bottom: 30px;
    }

    @media screen and (min-width: 997px) and (max-width: 1399.98px) {
        
    }
`

export const AchievementsContainerCertificates = styled.div`
    min-width: 45%;
    margin-right: 2%;
    padding: 8px;
    font-family: var(--font-family-body);
    color: #000000;
    margin-top: 16px;


    @media screen and (min-width: 767.98px) and (max-width: 1399.98px) {
        min-width: 50%;
        margin: 0 0 2% 0;
    }
    @media (max-width: 768px) {
        margin: 0;
        padding: 0;
    }
`

export const AchievementsContainerBadges = styled.div`
    min-width: 45%;
    margin-left: 2%;
    padding: 8px;
    font-family: var(--font-family-body);
    color: #000000;
    margin-top: 16px;

    @media screen and (min-width: 767.98px) and (max-width: 1399.98px) {
        min-width: 50%;
        margin: 0 0 2% 0;
    }

    @media (max-width: 768px) {
        margin: 0;
        padding: 0;
    }
`

export const AchievementsButtonContinueLearning = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family-body);
    font-weight: var(--fw-semibold);
    line-height: var(--lh-distant);
    gap: 8px;
    padding: 13.5px 32px 13.5px 32px;
    background-color: var(--clr-primary-default);
    border-radius: 14px;
    border: none;
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--clr-neutral-900);
    margin: 24px auto 0;

    @media screen and (max-width: 767.67px) {
        font-size: 18px;

    }

    :hover {
        background-color: var(--clr-primary-dark);
    }

    :focus {
        outline: none;
    }
`

export const ButtonContainer = styled.div`

`
