import styled from 'styled-components'

export const Container = styled.div`
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--clr-primary-bg);
  border: none;
  z-index: 245565;
  transition: cubic-bezier(0.77, 0, 0.175, 1);

  :focus {
    outline: none;

  }
`

export const UserInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LeftSideInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

export const RightSideInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const BannerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const CloseButton = styled.button`
  position: absolute;
  z-index: 1222 !important;
  top: 5%;
  right: 2%;
  padding: 4px;
  background-color: var(--clr-neutral-800);
  color: var(--clr-primary-default);
  border-radius: 10px;
  border: none;
  box-shadow: var(--elevation-1);
  :focus {
    outline: none;
  }

  :hover {
    background-color: var(--clr-neutral-700);
    box-shadow: var(--elevation-2);
  }
`

export const UserImg = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin-top: -25%;
  background-color: var(--clr-secondary-default);
  padding: 2px;
  z-index: 1111;
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const UserName = styled.div`
  font-size: var(--fs-headline-subtitle-large);
  font-weight: var(--fw-medium);
  line-height: var(--lh-tight);
  text-align: center;
`
export const UserTeam = styled.div`
  font-size: var(--fs-body-text-medium);
  font-weight: var(--fw-medium);
`

export const UserRole = styled.div`
  font-size: var(--fs-body-text-large);
  font-weight: var(--fw-medium);
  color: var(--clr-primary-light);
  margin-bottom: 8px;

`

export const BioLabel = styled.strong`
  font-size: var(--fs-headline-subtitle-medium);
  font-weight: var(--fw-semibold);
`

export const BioText = styled.text`
  font-size: var(--fs-body-text-medium);
  font-weight: var(--fw-regular);
  line-height: var(--lh-spaced);
  margin-bottom: 16px;
  text-align: center;
  padding-inline: 8px;
  height: 77px;
  width: 100%;
`
