import styled from 'styled-components'

export const ContainerDiv = styled.div`
`

export const InputDefault = styled.input`
`

export const InputLabel = styled.label`
`

export const TitleForm = styled.h3`
`

export const TableDefault = styled.table`
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
`

export const TdDefault = styled.td`
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
`

export const ThDefault = styled.th`
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
`

export const TrDefault = styled.tr`
    :nth-child(even) {
        background-color: #dddddd;
    }
`

export const TheadDefault = styled.thead`
`

export const TbodyDefault = styled.tbody`
`

export const ContainerTeamOverview = styled.div`
    text-align: center;
    color: var(--clr-primary-dark);
`
