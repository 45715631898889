import styled from 'styled-components'

export const Container = styled.button`
  outline: none;
  width: 100%;
  max-width: 100px;
  min-width: 100px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 8px;
  background-color: transparent;
  border: none;

  :focus {
    outline: none;
    box-shadow: var(--elevation-2);

  }

  :hover {
    background-color: var(--clr-neutral-800);
    border-radius: 4px;
  }

  div {
    display: flex;
  }

`

export const UserImg = styled.div`
  border-radius: 50%;
  position: relative;
`

export const UserFullName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -20px;
  padding-bottom: 4px;
  background-color: #6b6bb6cc;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  z-index: 2;
  
`

export const UserName = styled.text`
  font-size: var(--fs-body-text-medium);
  font-weight: var(--fw-medium);
  color: var(--clr-neutral-900);
  line-height: var(--lh-tight);
  max-width: 76px;
  z-index: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1200px) {
    font-size: var(--fs-body-text-medium);

  }
`
