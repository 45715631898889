import styled from 'styled-components'

export const ContainerLogin = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
`

export const Image = styled.img`
  width: auto;
  height: 7em;
  margin: 1.5em 4%;
`

export const Form = styled.form`
  flex: 1;
  background: #FFF;
  padding: 4em 2em;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  .forgot-password {
    color: black;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 1.25rem;
  }

  span {
    margin-top: .714em;
    color: #333;

    a { 
      color: var(--orange-primary) !important;
      text-transform: capitalize;
    }
  }
`

export const Input = styled.input`
  background-color: var(--gray-primary);
  color: #FFF !important;
  font-weight: 400;
  font-size: 1.14em;

  border-radius: 4px;
  padding: .65rem 1rem;
  margin-top: .25em;
  height: 60px;

  appearance: none;
  box-shadow: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &::placeholder {
    color: #FFF;
  }

  &:focus {
    background: var(--gray-primary) !important;
    border-color: #80bdff;
    outline: 0;
    box-shadow: none;

    ::-webkit-input-placeholder {
      font-size: .8em;
      position: relative;
      top: -20px; 
    }

    &::-ms-value {
      color: #FFF ;
      background-color: var(--gray-primary);
    }
  }

`

export const Button = styled.button`
  background-color: #FFF;
  border: 4px solid var(--orange-primary);
  border-radius: 4px;
  color: var(--navy);
  line-height: normal;
  padding: 0.5em 1em;
  font-weight: 700;
  font-size: 1.5rem;

  :hover {
    background-color: var(--orange-primary);
    color: #fff;
  }
`
