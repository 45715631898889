import React from 'react'
import Header from '../../../../components/Auth/Header'
import OrganizationTeamsCards from '../../../../components/Auth/OrganizationTeamsCards'

import {
  ContainerMainContent
} from './styled'

const EditTeam = () => {
  return (
    <React.Fragment>
      <Header />
      <ContainerMainContent>
        <OrganizationTeamsCards />
      </ContainerMainContent>
    </React.Fragment>
  )
}

export default EditTeam
