import styled from 'styled-components'

export const ContainerManageTeam = styled.div`
  color: #212C68;
  margin-top: 6em;
  padding: 0 55px;

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
  }

  // mobile
  @media (max-width: 767.2px) {
    padding: 0 20px;

    .page-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1em;
    }

    .table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  }
`

export const NewUserButton = styled.button`
  color: #212C67 !important;
  font-weight: 500;
  padding: .375rem .75rem;
  font-size: 1rem;
  text-transform: capitalize;
`
