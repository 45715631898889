import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Header from '../../../components/Auth/Header'
import ProfilePictureUpload from '../../../components/Auth/ProfilePictureUpload'
import Loading from '../../../components/Loading'
import { ButtonOrange } from '../../../components/Buttons'
import api from '../../../services/api'

const FirstLogin = () => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState([])
  const [userId, setUserId] = useState('')
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [team, setTeam] = useState('')
  const [role, setRole] = useState('')
  const [bio, setBio] = useState('')
  const [mobile, setMobile] = useState('')
  const [isSuperUser, setIsSuperUser] = useState(false)
  const history = useHistory()

  const getUser = async () => {
    const { data }: any = await api.getMethod(`user/${Number(localStorage.getItem('userId'))}/infos`)
    if (data.length <= 0) {
      return
    }
    const isSuperUserCurrent = Boolean(Number(localStorage.getItem('isSuperUser')))
    setIsSuperUser(isSuperUserCurrent)
    setUser(data)
  }

  const getUserDetails = (user: any) => {
    setUserId(user.id)
    setTeam(user.team)
    setRole(user.role)
    setBio(user.bio)
    setName(user.name)
    setLastName(user.last_name)
    setMobile(user.mobile)
  }

  const editUser = async () => {
    if (mobile === undefined || mobile === null) {
      setMobile('')
    }

    const firstLogin = true
    const currentLoginCount = Number(localStorage.getItem('logged_in_account')) + 1

    const formData = {
      userId,
      team,
      role,
      bio,
      name,
      lastName,
      mobile,
      firstLogin
    }

    await api.postMethod(`user/${Number(localStorage.getItem('userId'))}/infos`, formData)
    getUser()

    setTimeout(() => {
      localStorage.setItem('logged_in_account', currentLoginCount.toString())
      history.push('/home')
    }, 1000)
  }

  const getUserInfo = async () => {
    await getUser()
    getUserDetails(user)
    setLoading(false)
  }

  useEffect(() => {
    getUserInfo()
  }, [userId])

  if (loading) {
    <Loading />
  }

  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: '96px' }}>
        <div className="form-group mt-2">
          <ProfilePictureUpload />
        </div>
        <div className="form-group mt-2">
          <input
            onChange={text => setName(text.target.value)}
            required
            className="form-control"
            id="name"
            name="name"
            placeholder="Name"
            value={name}
            minLength={2}
          />
        </div>
        <div className="form-group mt-2">
          <input
            onChange={text => setLastName(text.target.value)}
            required
            className="form-control"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            value={lastName}
            minLength={2}
          />
        </div>
        {
          isSuperUser
            ? <div className="form-group mt-2">
                <input
                  onChange={text => setTeam(text.target.value)}
                  className="form-control"
                  id="team"
                  name="team"
                  placeholder="Team"
                  value={team}
                  minLength={2}
                />
              </div>
            : <div className="form-group mt-2">
                <input
                  onChange={text => setTeam(text.target.value)}
                  className="form-control"
                  id="team"
                  name="team"
                  placeholder="Team"
                  value={team}
                  minLength={2}
                  readOnly
                />
              </div>
        }
        {
          isSuperUser
            ? <div className="form-group mt-2">
                <input
                  onChange={text => setRole(text.target.value)}
                  className="form-control"
                  id="role"
                  name="role"
                  placeholder="Role"
                  value={role}
                  minLength={2}
                />
              </div>
            : <div className="form-group mt-2">
                <input
                  onChange={text => setRole(text.target.value)}
                  className="form-control"
                  id="role"
                  name="role"
                  placeholder="Role"
                  value={role}
                  minLength={2}
                  readOnly
                />
              </div>
        }
        <div className="form-group mt-2">
          <input
            onChange={text => setMobile(text.target.value)}
            className="form-control"
            id="mobile"
            name="mobile"
            placeholder="Phone"
            value={mobile}
            maxLength={16}
          />
        </div>
        <div className="form-group mt-2">
          <textarea
            onChange={text => setBio(text.target.value)}
            className="form-control"
            id="bio"
            name="bio"
            placeholder="Writing your bio..."
            value={bio}
          ></textarea>
        </div>
        <ButtonOrange
          as="button"
          type="button"
          className='btn'
          data-dismiss="modal"
          onClick={() => editUser()}
        >
          Update Info
        </ButtonOrange>
      </div>
    </>
  )
}

export default FirstLogin
