import styled from 'styled-components'

export const RankingContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 352px;
  max-width: 380px;

  @media screen and (max-width: 1399.98px) {
    width: 100%;
    height: 100%;
  }
`

export const RankingHeader = styled.div`
margin-top: 24px;
display: flex;
justify-content: space-between;
align-items: center;
width: 380px;
padding-right: 24px;
padding-left: 6px;
padding-bottom: 24px;

@media (max-width: 1200px) {
  width: 95%;
}
`

export const HeaderTitle = styled.h4`
  font-family: var(--font-family-headline);
  line-height: var(--lh-spaced);
  font-weight: var(--fw-semibold);
  font-size: var(--fs-headline-subtitle-large);
  color: var(--clr-neutral-100);
  margin-bottom: 0;

  
`

export const HeaderActions = styled.div`
  display: flex;
  gap: 8px;
  padding-right: 16px;
  align-items: center;
  font-size: var(--fs-body-subtext-large);
  font-weight: var(--fw-medium);

  .select-border {
    border: 1px solid var(--clr-neutral-600);
    border-radius: 6px;

  }

  span {
    font-size: var(--fs-body-subtext-large);

  }

  select {
    border: none;
    border-radius: 6px;
    background: var(--clr-neutral-800);
    border-right: 8px solid transparent;
    padding: 8px 0 8px 8px;
    font-weight: var(--fw-semibold);

    :focus {
      outline: none;
    }
  }
`

export const RankingContent = styled.div`
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 95%;

    @media (max-width: 1440px) {
      width: 95%;
    }

    @media (max-width: 1200px) {
      width: 85%;

    }

  .is-logged-user {
    background-color: #cbaf63;
    border: 1px solid #cbaf63;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
  }

  .ranking-item {
    width: auto;
    height: 52px;
    border-bottom: 1px solid #cbaf63;
    margin-left: 3%;
    color: white;

    div {
      display: flex;
      flex-direction: row;
      margin-left: 15px;
    }
    
    .ranking-item-user {
      align-items: center;

      .ranking-item-user-avatar {
        width: 36px;
        height: 36px;
        
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }

        .ranking-user-online-icon {
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #00ff00;
          margin-left: 26px;
          margin-top: 26px;
        }
      }

      .ranking-item-user-name {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-top: 5px;

        .raking-item-user-points {
          font-size: 10px;
        }
      }
    }
  }
`

export const RankingContentImage = styled.div`
    overflow-y: scroll;
    display: grid;
    grid-template-columns: 100px 100px 100px;
    gap: 8px;
    column-gap: 12px;
    grid-template-rows: 120px;
    align-items: start;
    justify-items: start;
    position: relative;
    width: 95%;

    @media (max-width: 1440px) {
      width: 95%;
    }

    @media (max-width: 1200px) {
      width: 90%;
      grid-template-columns: 100px 100px 100px;
      column-gap: 4px;
      
    }

`
