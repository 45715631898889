/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Fab, Action } from 'react-tiny-fab'
import 'react-tiny-fab/dist/styles.css'

import CertificateIco from '../../assets/images/certificates-ico.svg'
import profileUserIco from '../../assets/images/profile-user-ico.svg'
import homeIco from '../../assets/images/home-ico.svg'
import expertsIco from '../../assets/images/experts-ico.svg'
import dashboardIco from '../../assets/images/dashboard-ico.svg'

const mainButtonstyle = {
  backgroundColor: '#CBAF63',
  color: '#FFE192',
  border: '1px solid #FFE192',
  outline: 'none'
}

const actionButtonStyle = {
  backgroundColor: '#FFE192',
  color: '#CBAF63',
  border: '1px solid #CBAF63',
  outline: 'none'
}

const ToggleButton = () => {
  const [isSuperUser, setIsSuperUser] = useState<boolean>()

  useEffect(() => {
    const isSuperUserInfo = localStorage.getItem('isSuperUser')
    const superUserCheck = isSuperUserInfo === '1' ? true : false
    setIsSuperUser(superUserCheck)
  }, [])

  return (
    <React.Fragment>
      <Fab
        style={{ bottom: '0', right: '0' }}
        icon={'+'}
        event={'click'}
        alwaysShowTitle={true}
        mainButtonStyles={mainButtonstyle}
      >
        <Action text="View All Certificates" style={actionButtonStyle}>
          <Link to='/achievements'>
            <LazyLoadImage src={CertificateIco} width="24px" height="24px" />
          </Link>
        </Action>
        <Action text="Update User Info" style={actionButtonStyle}>
          <Link to='/account'>
            <LazyLoadImage src={profileUserIco} width="24px" height="24px" />
          </Link>
        </Action>
        {
          isSuperUser && (
            <Action text="Dashboard" style={actionButtonStyle}>
              <Link to='/dashboard'>
                <LazyLoadImage src={dashboardIco} width="24px" height="24px" />
              </Link>
            </Action>
          )
        }
        <Action text="Go to the Experts screen" style={actionButtonStyle}>
          <Link to='/experts'>
            <LazyLoadImage src={expertsIco} width="24px" height="24px" />
          </Link>
        </Action>
        <Action text="Go to the Home screen" style={actionButtonStyle}>
          <Link to='/home'>
            <LazyLoadImage src={homeIco} width="24px" height="23px" />
          </Link>
        </Action>
      </Fab>
    </React.Fragment>
  )
}

export default ToggleButton
