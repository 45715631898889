import styled from 'styled-components'

export const Container = styled.div`
  height: 86px;
  width: 100%;
  padding: 8px;


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`

export const InfoCompletionContainer = styled.div`
  width: 100%;
  padding-inline: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--clr-neutral-100);

`

export const Mark = styled.div`
  display: flex;
  width: 22px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: var(--lh-tight);

  text {
    
    text-align: center;
  }

  div {
    width: 0.5px;
    height: 4px;
    background: var(--clr-neutral-100);
  }
`

export const FilledContainer = styled.div`
  height: 28px;
  width: 100%;
  border-radius: 50px;
  display: flex;
  align-items: center;

  background: linear-gradient(90deg, #525399 0%, #222B6A 50%, #00013F 100%);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

  div#fill {
    background: linear-gradient(90deg, #529966 0%, #286A22 99.99%, #226A36 100%);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  div#pointer {
    position: relative;

  }

`

export const ImgContainer = styled.div`
  border-radius: 50%;
  width: 64px;
  height: 64px;
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;

  background-color: #D3D3D3;

  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  &:hover #name {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Percentage = styled.div`
  padding-inline: 4px;
  font-weight: var(--fw-medium);
  font-size: var(--fs-body-text-medium);
  line-height: var(--lh-spaced);
  color: var(--clr-neutral-900);
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--clr-secondary-default);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);

`

export const Name = styled.div.attrs(props => ({
  id: 'name'
}))`
  display: none;
  padding: 2px;
  border-radius: 8px;
  font-size: 14px;
  background-color: var(--clr-secondary-default);
  color: var(--clr-neutral-900);
  position: absolute;
  top: 65%;
  cursor: default;
  left: 50%;
  transform: translate(-50%, -57%);
`
