/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { t } from 'react-multi-lang'

import Header from '../../../components/Auth/Header'
import Loading from '../../../components/Loading'
import Banner from '../../../components/Auth/Banner'
import ProfileCategoryCard from '../../../components/Auth/ProfileCategoryCard'
import ProfileUserActivity from '../../../components/Auth/ProfileUserActivity'
import ProfileCertificatesCards from '../../../components/Auth/ProfileCertificatesCards'
import ProfileUserForm from '../../../components/Auth/ProfileUserForm'
import ToggleButton from '../../../components/ToggleButton'

import Ranking from '../../../components/Auth/Ranking'
import UserInfoCard from '../../../components/Auth/UserInfoCard'
import api from '../../../services/api'

import {
  Container,
  ProfileContainer,
  ProfileVideos,
  ProfileStats,
  ProfileStatRecentActivity,
  ProfileMainOption,
  ProfileRankings,
  ProfileContainersTitle,
  ProfileContainersSubTitle,
  ProfileVideosCardContainer
} from './styled'

const ProfilePage = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [msgWelcome, setMsgWelcome] = useState<boolean>(true)
  const [categoriesCompleted, setCategoriesCompleted] = useState<number>(0)
  const [categoriesTotal, setCategoriesTotal] = useState<number>(0)
  const [progress, setProgress] = useState<number>(0)

  const getCategoriesInfo = async () => {
    const organizationCategories: AxiosResponse<any[any]> = await api.getMethod(`get_all_organization_categories/${parseInt(localStorage.getItem('organizationId') as string)}`, {
      team_id: localStorage.getItem('teamId')
    })
    setCategoriesTotal(organizationCategories?.data?.data?.length)

    if (organizationCategories?.data?.data.length === 1 && organizationCategories?.data?.data[0].expert_id !== null) {
      localStorage.setItem('expert_organization', '1')
    }

    const certifications: AxiosResponse<any[any]> = await api.getMethod(`get_all_user_certifications/${parseInt(localStorage.getItem('userId') as string)}`)
    const categoriesSort = organizationCategories?.data?.data?.sort((a: any, b: any) => a.category_id < b.category_id ? -1 : (a.category_id > b.category_id ? 1 : 0))
    const categoriesFilterCompleted = categoriesSort.filter((element: any, index: number) => element.category_id === certifications?.data?.data[index]?.category_id)
    setCategoriesCompleted(categoriesFilterCompleted?.length)

    const calculateProgress = categoriesFilterCompleted?.length === 0 ? 0 : Math.floor((categoriesFilterCompleted?.length / organizationCategories?.data?.data?.length) * 100)
    setProgress(calculateProgress)

    setLoading(false)
  }

  useEffect(() => {
    getCategoriesInfo()
    setMsgWelcome(false)
  }, [])

  if (loading) {
    return <Loading />
  }

  if (msgWelcome) {
    toast.success(t('welcome_profile_user', { username: localStorage.getItem('name') as string }))
  }

  return (
    <Container>
        <Header />
        <Banner />
        <ProfileContainer>
          <ProfileStats>
            <ProfileStatRecentActivity>
              <UserInfoCard infoOnLeft={true}/>
              <ProfileUserActivity />
            </ProfileStatRecentActivity>
            <ProfileCertificatesCards />
          </ProfileStats>
          <ProfileMainOption>
            <UserInfoCard infoOnLeft={false}/>
            <ProfileVideos>
              <ProfileContainersTitle>My Learning Path</ProfileContainersTitle>
              <ProfileContainersSubTitle>{ categoriesCompleted } COMPLETED  /  { categoriesTotal } TO GO - {progress}% COMPLETED</ProfileContainersSubTitle>
              <ProfileVideosCardContainer>
                <ProfileCategoryCard />
              </ProfileVideosCardContainer>
            </ProfileVideos>
          </ProfileMainOption>
          <ProfileRankings>
            <Ranking />
          </ProfileRankings>
        </ProfileContainer>
        <ProfileUserForm />
        <ToggleButton />
    </Container>
  )
}
export default ProfilePage
