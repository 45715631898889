/* eslint-disable */
import React, { useEffect, useState } from 'react'
import api from '../../../services/api'

import {
  // rankingIcons,
  UserRanking
  // OnlineUser,
  // UsersOnline
} from './interfaces'

import {
  RankingContainer,
  RankingHeader,
  HeaderTitle,
  HeaderActions,
  RankingContent,
  RankingContentImage
} from './styled'

/* import { socket } from '../../../services/socket' */
import UserListItem from '../UserListItem'
import UserAvatarImage from '../UserAvatarImage'


const Ranking = () => {
  const [usersRanking, setUsersRanking] = useState([] as UserRanking[])

  // const [onlineUsers, setOnlineUsers] = useState([] as OnlineUser[])
  const [organizationId] = useState(localStorage.getItem('organizationId') || 0)
  const [userTeam] = useState(localStorage.getItem('team') || 'No Team')
  const [usersTeam, setUsersTeam] = useState([] as UserRanking[])
  const [usersCompany, setUsersCompany] = useState([] as UserRanking[])
  const [usersList, setUsersList] = useState([] as UserRanking[])
  const [usersRankingList, setUsersRankingList] = useState([] as UserRanking[])

  const [showRankingList, setShowRankingList] = useState<boolean>(false)

  const getRanking = async () => {
    await api.getMethod(`get_all_rankings?organization_id=${organizationId}`).then((res: any) => {
      const { data } = res.data
      setUsersRankingList(data as UserRanking[])
      setUsersRanking(data as UserRanking[])
    })
  }

  const getUsersCompany = async () => {
    await api.getMethod(`get_all_users_organization/${organizationId}`).then((res: any) => {
      const { data } = res.data

      setUsersCompany(data as UserRanking[])
      setUsersList(data as UserRanking[])
    })
  }

  const getUsersTeam = async () => {
    await api.getMethod(`get_all_users_my_team/${parseInt(organizationId as string)}/${userTeam}`).then((res: any) => {
      const { data } = res.data

      setUsersTeam(data as UserRanking[])
    })
  }

  useEffect(() => {
    getRanking()
    getUsersCompany()
    getUsersTeam()

    /* socket.emit('get_users_online')
    socket.on('all_users_online', (usersOnline: UsersOnline) => {
      setOnlineUsers(usersOnline[organizationId])
    }) */
  }, [])

  function handleSelectChange(event: { target: { value: any } }){
    if (event.target.value === 'team') {
      setUsersList(usersTeam)
      setShowRankingList(false)
      return
    }
    if (event.target.value === 'company') {
      setUsersList(usersCompany)
      setShowRankingList(false)
      return
    }
    if (event.target.value === 'ranking') {
      setUsersRankingList(usersRanking)
      
      setShowRankingList(true)
      return
    }
    return
}

  return (
    <RankingContainer>
      <RankingHeader>
        <HeaderTitle>People</HeaderTitle>
        <HeaderActions>
          <span>List users by:</span>
          <div className='select-border'>
            <select name="select" className='list-select' onChange={handleSelectChange}>
              <option value="company">Company</option>
              <option value="team">Team</option> 
              <option value="ranking">Ranking</option>
            </select>
          </div>
        </HeaderActions> 
      </RankingHeader>
      {
        showRankingList === true ?
          <RankingContent>
            {
              usersRankingList.map((user: UserRanking, index: number) => {
                return (
                  <>
                    <UserListItem
                      key={index}
                      userImg={user.picture}
                      userName={`${user.name} ${user.last_name === undefined ? ' ' : user.last_name}`}
                      userTeam={user.team}
                      userRole={user.role}
                      userBio={user.bio}
                      rankingPosition={user.ranking !== undefined ? user.ranking : ''}
                    />
                  </>
                )
              })
            }
          </RankingContent>
        :
          <RankingContentImage>
            {
              usersList.map((user: UserRanking, index: number) => {
                return (
                  <>
                    <UserAvatarImage
                      key={index}
                      userImg={user.picture}
                      userName={user.name}
                      userLastName={user.last_name === undefined ? ' ' : user.last_name}
                      userTeam={user.team}
                      userRole={user.role}
                      userBio={user.bio}
                      rankingPosition={user.ranking !== undefined ? user.ranking : ''}
                    />
                  </>
                )
              })
            }
          </RankingContentImage>
      }
        
              {/* // <div className={isLoggedUser ? 'ranking-item is-logged-user' : 'ranking-item '} key={index}>
              //   <div className='ranking-item-user'>
              //     <div className='ranking-item-user-ranking'>
              //       {
                      user.ranking <= 3
                        ? (
                          <img src={rankingicons[user.ranking]} alt='ranking' />
                          )
                        : (
                          <span>{user.ranking}th</span>
                          )
                    }
                  </div>
                  <div className='ranking-item-user-avatar'>
                    <img className='ranking-user-avatar' src={user.user_picture} alt='avatar' />
                    {
                      onlineUsers.map((onlineUser: OnlineUser) => {
                        if (onlineUser.data.user_id === user.user_id) {
                          return (
                            <span className='ranking-user-online-icon' key={user.user_id}></span>
                          )
                        }
                      })
                    }
                  </div>
                  <div className='ranking-item-user-name'>
                    <span>{ isLoggedUser ? 'You': user.user_name }</span>
                    <span className='raking-item-user-points' >
                      {
                        user.categories_certificates !== undefined && user.sub_categories_certificates !== undefined
                        ? Number(user.categories_certificates) + Number(user.sub_categories_certificates)
                        : 0
                      } points
                    </span>
                  </div>
                </div>
              </div>
          )
        })
      }
      </RankingContent> */}
    </RankingContainer>
  )
}

export default Ranking
