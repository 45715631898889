import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--gray-bg);
  width: 100%;
  min-width: 100%;
  height: calc(100vh - 64px);
  min-height: 100%;
`

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--gray-bg);
  margin-top: 64px;
  width: 100%;
  padding-inline: 7%;
  min-height: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-family-body);
  font-size: var(--fs-body-subtext-large);

  @media (max-width: 769px) {
    display: none;
  }
`

export const CreatorHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  margin-bottom: 24px;
  gap: 90px;
  height: 10%;
  width: 100%;
`

export const TeamPicker = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;

  text {
    font-size: var(--fs-headline-subtitle-medium);
    color: var(--clr-neutral-100);
    font-weight: var(--fw-regular);
    width: fit-content;
  }
`

export const ColumnName = styled.text`
  font-size: var(--fs-headline-subtitle-medium);
  color: var(--clr-neutral-100);
  font-weight: var(--fw-regular);
  margin-bottom: 16px;
  max-width: 90%;
  padding-top: 24px;
  height: 60px;
`

export const ColumnContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 40%;
  max-height: 100%;
  align-items: center;
  justify-content: flex-start;
  span {
    color: aqua;
  }
`

export const ColumnContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 40%;
  max-height: 100%;
  align-items: center;
  justify-content: flex-start;
  span {
    color: aqua;
  }
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  max-width: 600px;
  max-height: 100%;
  align-items: center;
  justify-content: flex-start;
  span {
    color: aqua;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 90%;
  height: 90%;
  justify-content: center;
  align-items: flex-start;
  font-family: var(--font-family-body);
  font-size: var(--fs-body-subtext-large);
  gap: 24px;

  @media (max-width: 769px) {
    display: none;
  }
`

export const AllCategoriesColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3%;
  gap: 16px;
  height: 75%;
  min-height: 60vh;
  max-height: 70%;
  overflow-y: scroll;
  border-radius: 8px;
  flex-grow: 1;
  width: 100%;
  max-width: 600px;
  align-items: center;
  justify-content: flex-start;
  background-color: ${props => (props.isDraggingOver ? 'var(--clr-secondary-light)' : 'var(--gray-bg)')};

`

export const WhiteSpace = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
`

export const OrderedCategoriesColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3%;
  gap: 16px;
  height: 70%;
  min-height: 60vh;
  max-height: 70%;
  overflow-y: scroll;
  width: 100%;
  max-width: 600px;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  border-radius: 8px;
  transition: background-color 0.2s ease;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px 0px inset;
  background-color: ${props => (props.isDraggingOver ? 'var(--clr-secondary-light)' : '#f8f8ffe3')};
`

export const SaveButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
`

export const SaveButton = styled.button`
  margin: 24px;
  padding: 16px 24px;
  border-radius: 9999px;
  font-weight: var(--fw-regular);
  font-size: var(--fs-headline-subtitle-medium);
  border: none;
  box-shadow: var(--elevation-2);
  background-color: var(--clr-primary-default);
  color: var(--clr-primary-bg);
  outline: none;
  :active {
    border: none;
  }
`

export const VideoPlayerColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  min-width: 35%;
  height: 75%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px 0px inset;
  background-color: #f8f8ffe3;
  margin: auto 0 13vh;
  align-items: center;
  video {
    width: 550px;
    height: 340px;
  }
`

export const PreviewText = styled.text`
  font-size: var(--fs-headline-subtitle-medium);
  color: var(--clr-neutral-100);
  padding-top: 32px;
  font-weight: var(--fw-regular);
`

export const NoVideoText = styled.text`
  font-size: var(--fs-headline-subtitle-small);
  color: var(--clr-neutral-100);
  padding-top: 32px;
  font-weight: var(--fw-regular);
`

export const VideoPlayer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 100%;
`
