/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import playIco from '../../../assets/images/videos-ico.svg'
import rankIco from '../../../assets/images/podium-ico.svg'
import calendarIco from '../../../assets/images/calendar-ico.svg'
import certificateIco from '../../../assets/images/certificates-ico.svg'
import api from '../../../services/api'
import {
  UserActivityContainer,
  UserActivityContainerIco,
  UserActivityContainerInfo,
  ActivityText
} from './styled'

const ProfileUserActivity = () => {
  const [userStats, setUserStats] = useState<any>([])
  /* const [userMicroCertifications, setUserMicroCertifications] = useState<number>(0) */
  const [userCertifications, setUserCertifications] = useState<number>(0)
  const [countSubCategories, setCountSubCategories] = useState<number>(0)

  /* const getMicroCertifications = async (subCategories: any) => {
    let countMicroCertifications = 0
    for (let subCategory of subCategories) {
      const { data }: AxiosResponse<any> = await api.getMethod(`get_one_user_micro_certifications/${subCategory.id}`)
      if (data.success) {
        countMicroCertifications++
      }
    }
    setUserMicroCertifications(countMicroCertifications)
  } */

  const getCertifications = async (userId: number) => {
    const { data }: AxiosResponse<any> = await api.getMethod(`get_all_user_certifications/${userId}`)
    setUserCertifications(data?.data?.length)
  }

  const getUserStats = async () => {
    const userId = Number(localStorage.getItem('userId'))
    const { data }: AxiosResponse<any> = await api.getMethod(`get_user_activity/${userId}`)
    setUserStats(data?.data)
    setCountSubCategories(data?.data?.certifications)

    /* await getMicroCertifications(data?.data?.subCategories) */
    await getCertifications(Number(localStorage.getItem('userId')))
    localStorage.setItem('current_rank', data?.data?.rankingUser?.ranking)
  }

  useEffect(() => {
    getUserStats()
  }, [userStats?.length])

  return (
    <React.Fragment>
      <UserActivityContainer>
        <UserActivityContainerInfo>
          <UserActivityContainerIco>
            <LazyLoadImage src={playIco} width='16px' height='16px' />
          </UserActivityContainerIco>
          <ActivityText><strong>{ userStats?.videosLastWeek }</strong> videos watched this week</ActivityText>
        </UserActivityContainerInfo>
        <UserActivityContainerInfo>
          <UserActivityContainerIco>
            <LazyLoadImage src={calendarIco} width='16px' height='16px' />
          </UserActivityContainerIco>
          <ActivityText><strong>{ userStats?.countSequenceDayWatch }</strong> days in a row watching videos everyday</ActivityText>
        </UserActivityContainerInfo>
        <UserActivityContainerInfo>
          <UserActivityContainerIco>
            <LazyLoadImage src={rankIco} width='16px' height='16px' />
          </UserActivityContainerIco>
          <ActivityText>
            { localStorage.getItem('current_rank') === '1' ? `You are in ${userStats?.rankingUser?.ranking}º place, congratulations!` : `You are ${userStats?.rankingUser?.ranking - 1} positions behind the first place` }
          </ActivityText>

        </UserActivityContainerInfo>
        <UserActivityContainerInfo>
          <UserActivityContainerIco>
            <LazyLoadImage src={certificateIco} width='16px' height='16px' />
          </UserActivityContainerIco>
          <ActivityText>You have  <strong>{ userCertifications } of { countSubCategories }</strong> certificates available in your path</ActivityText>
        </UserActivityContainerInfo>
      </UserActivityContainer>
    </React.Fragment>
  )
}

export default ProfileUserActivity
