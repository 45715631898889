import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--clr-neutral-900);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  padding: 16px;
`

export const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  
  div#left-items {
    display: flex;
    align-items: center;
    flex-direction: row;

    gap: 2rem;
  }

  .button {
    outline: none;
    border: none;
    background-color: transparent;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 45%;
    :hover {
      background-color: var(--clr-neutral-800);
    }
  }

  .action-btn {
    border-radius: 6px;
    border: 1px solid #9D9AAD;
    font-weight: 600;
    max-width: 130px;
    min-height: 38px;
    background-color: #F4F3FE;
  }
`

export const Heading = styled.text`
  font-weight: var(--fw-semibold);
  font-size: var(--fs-headline-subtitle-medium);
  color: var(--clr-neutral-100);
  white-space: nowrap;
`
