import styled from 'styled-components'

export const ContainerProfilePictureUpload = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        border: 1px;
        &:hover {
            cursor: pointer;
            background-color:#ccc; 
            color:#333;
            opacity: .5;
        }
    }

    button {
        display: none;
    }

    .input-file {
        display: none;
    }

    .text-bubble {
        color: #333;
        font-size: 16px;
        margin-left: 10px;
        align-self: center;
    }
`
