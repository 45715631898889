/* eslint-disable */
import { FC, useEffect } from "react";
import VideoCard from "../VideoCard";
import styled from "styled-components";

interface Video {
  default_image: string;
  admin_video_id: number;
  duration: string;
  title: string;
  isSelected: boolean;
  onToggleSelect: (
    categoryId: number | string,
    subCategoryId: number,
    videoId: number,
  ) => void;
}

interface SubCategoryCardProps {
  title: string;
  url_page_id: number;
  videos: Video[];
  categoryId: number | string;
  setPreview: (id: number) => void;
  onToggleSelect: (
    categoryId: number | string,
    subCategoryId: number,
    videoId: number,
  ) => void;
  selectedVideoIds: {
    [categoryId: number | string]: {
      [subCategoryId: string]: { [videoId: string]: boolean };
    };
  };
}
const SubcategoryContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
`;

const SubcategoryTitle = styled.h3`
  color: #333;
  margin-bottom: 15px;
`;

const SubcategoryCard: FC<SubCategoryCardProps> = ({
  title,
  categoryId,
  videos,
  onToggleSelect,
  selectedVideoIds,
  url_page_id: subCategoryId,
  setPreview
}) => {
  return (
    <SubcategoryContainer>
      <SubcategoryTitle>{title}</SubcategoryTitle>
      <div>
        {videos.map((video) => (
          <VideoCard
            key={video.admin_video_id}
            id={video.admin_video_id}
            title={video.title}
            thumbnail={video.default_image}
            duration={video.duration}
            setPreview={setPreview}
            isSelected={
              selectedVideoIds[categoryId][subCategoryId][video.admin_video_id]
            }
            onToggleSelect={() =>
              onToggleSelect(categoryId, subCategoryId, video.admin_video_id)
            }
          />
        ))}
      </div>
    </SubcategoryContainer>
  );
};

export default SubcategoryCard;
