import styled from 'styled-components'

export const ButtonOrange = styled.a`
  background-color: #FFF;
  border: 4px solid var(--orange-primary);
  border-radius: 4px;
  color: var(--orange-primary) !important;
  font-weight: 600;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  text-transform: capitalize;

  :hover {
    background-color: var(--orange-primary);
    color: #fff !important;
  }
`

export const ButtonBlueHeader = styled.a`
  background-color: #FFF;
  color: #212c68 !important;
  font-weight: var(--fw-medium);
  font-size: 1rem;
  text-transform: capitalize;

  :hover {
    color: var(--clr-secondary-dark) !important;
  }

  
`

export const ButtonOutlineWhite = styled.button`
  background-color: transparent;
  border: 2px solid #FFF;
  border-radius: 4px;
  color: #212C68 !important;
  font-weight: 500;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  text-transform: capitalize;
  
  @media (max-width: 767.2px) {
    display: none;
  }
`
export const ButtonOutlineWhiteHeader = styled.button`
  color: #212C67 !important;
  font-weight: 500;
  padding: .375rem .75rem;
  font-size: 1rem;
  text-transform: capitalize;

  @media (max-width: 767.2px) {
    display: none;
  }
`

export const ButtonProfilePageCertificate = styled.button`
  /* Button - Big */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family-body);
  font-weight: var(--fw-semibold);
  line-height: var(--lh-distant);
  font-size: var(--fs-body-text-medium);


  gap: 8px;
  padding: 13.5px 32px 13.5px 32px;
  background-color: var(--clr-neutral-800);
  border-radius: 14px;
  border: none;
  letter-spacing: 0.5px;
  text-align: center;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :hover {
    background-color: var(--clr-neutral-700);
    color: var(--clr-primary-default);
  }

  :focus {
    outline: none;
  }

  @media (max-width: 639px) {
    width: 100%;
    display: flex;
    background-color: var(--clr-neutral-800);
    justify-content: stretch;
    align-items: stretch;
    font-size: var(--fs-body-text-large);
  }
`

export const ButtonProfilePageContinue = styled.button`
  /* Button - Big */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs-body-text-medium);

  font-family: var(--font-family-body);
  font-weight: var(--fw-semibold);
  line-height: var(--lh-distant);
  gap: 8px;
  padding: 13.5px 32px;
  background-color: var(--clr-primary-default);
  border-radius: 14px;
  border: none;
  letter-spacing: 0.5px;
  text-align: center;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :hover {
    background-color: var(--clr-primary-dark);
  }

  :focus {
    outline: none;
  }

  @media (max-width: 639px) {
    width: 100%;
    display: flex;
    background-color: var(--clr-primary-default);
    justify-content: stretch;
    align-items: stretch;
    font-size: var(--fs-body-text-large);
  }
`

export const ButtonProfilePageChallenge = styled.button`
  /* Button - Big */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family-body);
  font-weight: var(--fw-semibold);
  line-height: var(--lh-distant);
  gap: 8px;
  padding: 13.5px 32px 13.5px 32px;
  background-color: var(--clr-primary-default);
  border-radius: 14px;
  border: none;
  letter-spacing: 0.5px;
  text-align: center;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :hover {
    background-color: var(--clr-primary-dark);
  }

  :focus {
    outline: none;
  }
`
