
/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Loading from '../../../components/Loading'
import ApprovedIco from '../../../assets/images/approved-ico.svg'
import api from '../../../services/api'
import {
  ProfileContainerCertificatesCards,
  ProfileCertificateCard,
  ProfileCertificateCardImage,
  ProfileCertificateCardTextCertificate,
  ProfileCertificateCardTextDescription,
  ProfileCertificateCardIcoApproved,
  ProfileCertificateCardText,
  AchievementsCardIssuedIn,
  Container
} from './styled'

const CertificatesPageCards = (data: any) => {
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState<any[]>([])
  const [categoriesInfo, setCategoriesInfo] = useState<any>([])
  const [userIsApprovedInChallenge, setUserIsApprovedInChallenge] = useState<boolean[]>([])
  const [userDateApprovedInChallenge, setUserDateApprovedInChallenge] = useState<string[]>([])

  const formaterDate = (date: string, typeFormater: string) => {
    const dateValue: string = date !== '' || date !== undefined || date !== null ? date : '00/00/0000'
    const newDate: string[] = typeFormater === 'simpleDate' ? dateValue.split('-') : dateValue.slice(0, 10).split('-')
    const dayFormater: string = newDate[2].length === 1 ? '0' + newDate[2] : newDate[2]
    const mounthFormater: string = newDate[1].length === 1 ? '0' + newDate[1] : newDate[1]
    const yearFormater: string = newDate[0]

    return newDate === undefined || newDate === null || newDate.length === 0 ? '00/00/0000' : `${mounthFormater}/${dayFormater}/${yearFormater}`
  }

  const verifyIfUserDoesHaveApprovedInChallenge = async (categoryId: number) => {
    const { data }: any = await api.getMethod(`get_user_certificate_by_category?user_id=${localStorage.getItem('userId')}&category_id=${categoryId}`)
    if (!data.success) {
      return { isApproved: false, dateApproved: 'Not yet obtained' }
    }
    if (parseInt(data.certification.category_id) === Number(categoryId)) {
      const dateApproved: string = data?.certification?.created_at.slice(0, 10)
      const dateFormatted = formaterDate(dateApproved.slice(0, 10), 'simpleDate')
      return { isApproved: true, dateApproved: dateFormatted }
    }
    return { isApproved: false, dateApproved: 'Not obtained' }
  }

  const getCategory = async (categoriesParams: any) => {
    const arrCategories: any = []
    const dataUserIsApprovedInChallenge: boolean[] = []
    const dataUserDateApprovedInChallenge: string[] = []
    for (let categoryParam of categoriesParams) {
      const { data }: AxiosResponse<any[any]> = await api.postMethod('get_category_id', {
        page_type: 'CATEGORY',
        category_id: categoryParam.category_id
      })

      const verifyCertificate: any = await verifyIfUserDoesHaveApprovedInChallenge(categoryParam.category_id)
      if (verifyCertificate.isApproved) {
        dataUserIsApprovedInChallenge.push(true)
        dataUserDateApprovedInChallenge.push(verifyCertificate.dateApproved)
      } else {
        dataUserIsApprovedInChallenge.push(false)
        dataUserDateApprovedInChallenge.push(verifyCertificate.dateApproved)
      }

      arrCategories.push(data[0])
    }
    setCategoriesInfo(arrCategories)
    setUserIsApprovedInChallenge(dataUserIsApprovedInChallenge)
    setUserDateApprovedInChallenge(dataUserDateApprovedInChallenge)
  }

  const getCategoriesInfo = async () => {
    const organizationCategories: AxiosResponse<any[any]> = await api.getMethod(`get_all_organization_categories/${parseInt(localStorage.getItem('organizationId') as string)}`, {
      team_id: localStorage.getItem('teamId')
    })
    setCategories(organizationCategories?.data?.data)

    await getCategory(categories)

    setLoading(false)
  }

  useEffect(() => {
    getCategoriesInfo()
  }, [categories.length])

  if (loading) {
    <Loading />
  }

  return (
      <Container>
            <ProfileContainerCertificatesCards>
              {
                categoriesInfo.map((category: any, index: number) => {
                  return (
                    userIsApprovedInChallenge[index] &&
                      <ProfileCertificateCard key={index}>
                        <ProfileCertificateCardImage>
                          <LazyLoadImage src={ category.picture } width='100%' height='100%' />
                          <ProfileCertificateCardIcoApproved>
                            <LazyLoadImage src={ ApprovedIco } width='100%' height='100%'/>
                          </ProfileCertificateCardIcoApproved>
                        </ProfileCertificateCardImage>
                        <ProfileCertificateCardText>
                          <ProfileCertificateCardTextCertificate>
                            Certificate
                          </ProfileCertificateCardTextCertificate>
                          <ProfileCertificateCardTextDescription>
                            {category.name}
                          </ProfileCertificateCardTextDescription>
                          <AchievementsCardIssuedIn>Issued:  <strong>{ userDateApprovedInChallenge[index] }</strong></AchievementsCardIssuedIn>
                        </ProfileCertificateCardText>
                      </ProfileCertificateCard>
                  )
                })
              }
            </ProfileContainerCertificatesCards>
      </Container>
  )
}

export default CertificatesPageCards
