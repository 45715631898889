import styled from 'styled-components'

export const Container = styled.div`

  .button-challenge-container {
    display: flex;
    align-items: center;
    justify-content: center;

  }

  //for mobile
  @media (max-width: 479.67px) {
    /* font-size: 16px; */
    .items {
      .videos-in-category {
        margin-left: -3rem;


        .slider-wrapper {
          margin-top: 8px;
        }

        .slider {
          .video-thumb {
            width: 60%;
            height: 60%;
          }

        }
      }

      .content {
        width: 100vw;
        margin-left: -60px;
      }

      
    }

  }

  @media (min-width: 480px) and (max-width: 767.67px) {
    
    
    .progress-circle-category {
        margin-top: 2rem;
        width: 40%;
        height: 40%;
        padding: 10px;
      }
  }

  //for tablet
  @media (min-width: 768px) and (max-width: 1023px){
    .items {
      .videos-in-category {
        margin-left: -3rem;

        .slider {
          .video-thumb {
            width: 60%;
            height: 60%;
          }
        }
      }

      .content {
        width: 100vw;
        margin-left: -60px;
      }

      .progress-circle-category {
        margin-top: 2rem;
        width: 20%;
        height: 20%;
      }
    }
  }

  //for ipad pro
  @media (min-width: 1024px) and (max-width: 1366px){
    .items {
      .videos-in-category {
        margin-left: -3rem;

        .slider {
          .video-thumb {
            width: 60%;
            height: 60%;
          }
        }
      }

      .content {
        width: 100vw;
        margin-left: -60px;
      }

      .progress-circle-category {
        margin-top: 2rem;
        width: 20%;
        height: 20%;
      }
    }

    .progress-circle-category {
      width: 150px;
      height: 150px;
    }
  }

  @media (min-width: 1366px) {
    .progress-circle-category {
        margin-top: 2rem;
        width: 16%;
        height: 16%;
      }
  }
`

export const ProgressCircleContainer = styled.div`
  width: 20%;
  height: 20%;

  @media (max-width: 1980px) {
    margin-left: 8px;

    svg {
      width: 160px;
      height: 160px;
    }
  }

  @media (max-width: 1440px) {
    margin-left: 8px;

    svg {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 479.67px) {
    width: 20vw;
    height: 20vh;
  }

  @media (max-width: 425px) {
    width: 80px;
    height: 126px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;

    svg {
      width: 80px;
      height: 80px;
    }
  }
`

export const ButtonChange = styled.button`
  //for mobile
  @media (max-width: 480px) {
    font-size: 16px;
    padding: 0.5em 1em;
  }

  //for tablet
  @media (min-width: 768px) and (max-width: 1023px){
    font-size: 18px;
    padding: 0.5em 1em;
  }

  background-color: #FFF;
  border: 4px solid var(--orange-primary);
  border-radius: 4px;
  color: var(--navy);
  line-height: normal;
  padding: 0.5em 1em;
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 3rem;

  :hover {
    background-color: var(--orange-primary);
    color: #fff;
  }
`
