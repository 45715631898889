import styled from 'styled-components'

export const ProfileCard = styled.div`
    font-family: var(--font-family-body);
    display: flex;

    position: relative;
    background: var(--clr-neutral-900);
    border: 1px solid var(--clr-neutral-700);
    border-radius: 4px;
    min-width: 537px;
    max-width: 100%;
    max-height: 203px;
    padding: 24px;
    gap: 24px;
    box-shadow: var(--elevation-1);

    :hover {
        box-shadow: var(--elevation-3);
    }

    @media (max-width: 639px) {
        flex-direction: column;
        max-height: 100%;
        max-width: 100%;
        min-width: 90%;
    }

    @media (max-width: 370px) {
        padding: 16px;
    }
    @media (max-width: 330px) {
        padding: 10px;
    }
`

export const ProfileCardLocked = styled.div`
    /* Card */
    font-family: var(--font-family-body);
    display: flex;

    position: relative;
    background: #F4F4F4;
    border: 1px solid var(--clr-neutral-800);
    border-radius: 4px;
    min-width: 540px;
    max-width: 100%;
    max-height: 203px;
    padding: 24px;
    gap: 24px;

    

    @media (max-width: 639px) {
        flex-direction: column;
        max-height: 100%;
        max-width: 100%;
        min-width: 90%;
    }
`

export const ProfileCardTitleLocked = styled.h3`
    /* IT and Cybersecurity */
    /* Headline/Subtitle2x */
    font-family: var(--font-family-headline);
    font-weight: var(--fw-semibold);
    font-size: var(--fs-headline-subtitle-medium);
    line-height: var(--lh-spaced);
    color: #D3D3D3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 8px;
    width: 100%;
`

export const ProfileCardInfoLocked = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #D3D3D3;
    margin-bottom: 8px;
`

export const ProfileCardInfoSubItemsLocked = styled.span`
    display: flex;
    height: 16px;
    gap: 4px;
    color: #D3D3D3;
    font-size: var(--fs-body-subtext-large);
    text-transform: uppercase;
    font-weight: var(--fw-regular);
    line-height: var(--lh-tight);
    align-items: center;
`

export const ProfileCardInfoProgressBarLocked = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
`

export const ProfileContainerProgressBarLocked = styled.div`
    width: 100%;
    height: 8px;
    border-radius: 10px;
    background-color: #D3D3D3;
`

export const ProfileProgressBarLocked = styled.div`
    height: 100%;
    border-radius: 10px;
    background-color: #D3D3D3;
    width: 25%;
`

export const ProfileCardSubInfoProgressBarFirstLocked = styled.span`
    display: flex;
    gap: 4px;
    color: #D3D3D3;
    font-family: var(--font-family-body);
    font-size: var(--fs-body-subtext-medium);
    font-weight: var(--fw-semibold);
    letter-spacing: 0.3px;
    line-height: 100%;
    text-transform: uppercase;
    
`

export const ProfileCardSubInfoProgressBarSecondLocked = styled.span`
    display: flex;
    gap: 4px;
    color: #D3D3D3;
    font-family: var(--font-family-body);
    font-size: var(--fs-body-subtext-medium);
    font-weight: var(--fw-semibold);
    letter-spacing: 0.3px;
    line-height: 100%;
    text-transform: uppercase;
`

export const CardContainerLocked = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;

    div#learning-path-track {
        width: 4px;
        height: 150px;
        background-color: #737185;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 639px) {
            max-height: 100%;
        }
    }
    @media (max-width: 639px) {
        width: 100%;
        justify-content: flex-end;
        gap: 16px;
    }
`

export const ButtonDivLocked = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 24px;
    position: absolute;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    margin-inline: auto;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    margin-top: 0px;
    z-index: 2222545;
    

    @media (max-width: 639px) {
        justify-content: center;
        align-items: center;
        border-radius: 14px;
        flex-direction: row-reverse;
        transform: translate(-30%, -50%);

    }

    @media (max-width: 500px) {
        transform: translate(-40%, -50%);

    }

    @media (max-width: 425px) {
        flex-direction: row;
        transform: translate(-40%, -50%);
        gap: 4px;

    }
`

export const CertificateImgContainer = styled.div`
    width: 10%;
    height: 60%;

    @media (max-width: 425px) {

        height: 18%;
        width: 12%;
        align-self: flex-start;
        margin-top: 6px;
    }
`

export const InfoCompletedContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
`

export const CertificateAwardedTitle = styled.text`
    font-weight: var(--fw-bold);
    font-size: var(--fs-body-text-large);
    color: #2D2935;
    backdrop-filter: blur(2px);

    @media (max-width: 425px) {
        font-size: var(--fs-body-subtext-large);
        font-weight: var(--fw-semibold);
        text-transform: uppercase;
        align-self: flex-end;
        padding-bottom: 2.3rem;
        padding-left: 6.4rem;
        backdrop-filter: none;

    }
    @media (max-width: 376px) {
        font-size: var(--fs-body-subtext-large);
        align-self: flex-end;
        padding-bottom: 2.3rem;
        padding-left: 4rem;
    }
    @media (max-width: 321px) {
        font-size: var(--fs-body-subtext-large);
        align-self: flex-end;
        padding-bottom: 2.3rem;
        padding-left: 2.4rem;
    }
`

export const CardContainer = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;

    div#learning-path-track {
        width: 4px;
        height: 227px;
        background-color: #737185;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 639px) {
            height: 401px;
            max-height: 100%;
        }

        @media (max-width: 426px) {
            height: 51vh;
            max-height: 100%;

        }
    }
    @media (max-width: 639px) {
        width: 100%;
        justify-content: flex-end;
        gap: 16px;
    }
`

export const ProfileCardImage = styled.img`
    box-sizing: border-box;
    background-color: #FFFFFF;
    height: 40px;
    border: 0.1px solid var(--clr-neutral-600);
    border-radius: 4px;
    width: 176px;
    height: 153px;

    @media (max-width: 639px) {
        width: 100%;
    }
`

export const ProfileCardContainer = styled.div`
    display: grid;
    width: 290px;
    @media (max-width: 639px) {
        width: 100%;
    }
`

export const ProfileCardTitle = styled.h3`
    /* IT and Cybersecurity */
    /* Headline/Subtitle2x */
    font-family: var(--font-family-headline);
    font-weight: var(--fw-semibold);
    font-size: var(--fs-headline-subtitle-medium);
    line-height: var(--lh-spaced);
    color: var(--clr-neutral-200);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 8px;
    width: 100%;
`

export const ProfileCardInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--clr-neutral-200);
    margin-bottom: 8px;

    #middle-info-subitem {
        @media (max-width: 374px) {
            display: none;
        }
    }
`

export const ProfileCardInfoSubItems = styled.span`
    display: flex;
    height: 16px;
    gap: 4px;
    font-size: var(--fs-body-subtext-large);
    text-transform: uppercase;
    font-weight: var(--fw-regular);
    line-height: var(--lh-tight);
    align-items: center;
    @media (max-width: 425px) {
        font-weight: var(--fw-semibold);

    }
`

export const ProfileCardInfoProgressBar = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
`

export const ButtonDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    @media (max-width: 639px) {
        justify-content: center;
        align-items: center;
        border-radius: 14px;
    }
`

export const ProfileContainerProgressBar = styled.div`
    width: 100%;
    height: 8px;
    border-radius: 10px;
    background-color: #ccc;
`

export const ProfileProgressBar = styled.div`
    height: 100%;
    border-radius: 10px;
    background-color: var(--clr-primary-light);
    width: 25%;
`

export const ProfileCardSubInfoProgressBarFirst = styled.span`
    display: flex;
    gap: 4px;
    color: var(--clr-primary-default);
    font-family: var(--font-family-body);
    font-size: var(--fs-body-subtext-medium);
    font-weight: var(--fw-semibold);
    letter-spacing: 0.3px;
    line-height: 100%;
    text-transform: uppercase;

    @media (max-width: 425px) {
        align-self: center;
        font-size: var(--fs-body-subtext-large);
        margin-top: 4px;
    }
    
`

export const ProfileCardSubInfoProgressBarSecond = styled.span`
    display: flex;
    gap: 4px;
    color: var(--clr-primary-default);
    font-family: var(--font-family-body);
    font-size: var(--fs-body-subtext-medium);
    font-weight: var(--fw-semibold);
    letter-spacing: 0.3px;
    line-height: 100%;
    text-transform: uppercase;

    @media (max-width: 425px) {
        display: none;
    }
`
