import styled from 'styled-components'

export const Container = styled.div`
  margin:0;
  padding: 0; 
  min-height: 100vh;
  margin: 50px auto;

  .main-sec-content {
    min-height: 60vh;
    margin-top: 10em;
  }
`

export const ContainerPage = styled.div`
  padding: 0em 1em;
  padding-bottom: 2.85em;
  text-transform: capitalize;
  color:#212C68;

  h1{
    margin-top:0;
    margin-bottom:.5em;
    text-transform: capitalize;
  }

  p { 
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 1em;
  }
`
