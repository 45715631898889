import React, { useState } from 'react'
import {
  Container,
  UserImg,
  ListAction,
  UserInfo,
  UserName,
  UserTeam,
  Ranking,
  Points,
  RankingPosition,
  UserRole,
  UserJobInfo
} from './styled'
import {
  rankingIcons
} from '../Ranking/interfaces'

import rankingIcon1 from '../../../assets/images/ranking-1-place.svg'
import rankingIcon2 from '../../../assets/images/ranking-2-place.svg'
import rankingIcon3 from '../../../assets/images/ranking-3-place.svg'
import teamIco from '../../../assets/images/team-16-ico.svg'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import UserInfoModal from '../UserInfoModal'

type Props = {
  userImg: any,
  userName: string,
  userTeam: string,
  userRole: string,
  userBio: string,
  rankingPosition?: any,
  points?: any,
}

export default function UserListItem ({ userImg, userName, userTeam, userRole, userBio, rankingPosition, points, ...rest }: Props) {
  const [rankingIcons] = useState({
    1: rankingIcon1,
    2: rankingIcon2,
    3: rankingIcon3
  } as rankingIcons)

  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
      {
        showModal
          ? (
            <UserInfoModal userImg={userImg} userName={userName} userTeam={userTeam} userRole={userRole} userBio={userBio} show={showModal} onClick={
            () => setShowModal(false)}
            />
            )
          : (
            <Container onClick={() => setShowModal(true)} {...rest}>
              <div>
                <UserImg>
                  <LazyLoadImage src={userImg} width='55px' height='55px' style={{ borderRadius: '50%' }}/>
                </UserImg>
                <UserInfo>
                  <UserName>{userName}</UserName>
                  <UserJobInfo>
                    <UserRole>{userRole}</UserRole>
                    <UserTeam><LazyLoadImage src={teamIco} width='12px' style={{ marginRight: '2px' }}/>{userTeam}</UserTeam>
                  </UserJobInfo>
                </UserInfo>
              </div>
              <ListAction>
                {
                  rankingPosition === ''
                    ? <text></text>
                    : (
                  <>
                    <Ranking>
                      {
                        rankingPosition <= 3
                          ? <LazyLoadImage src={`${rankingIcons[rankingPosition]}`} />
                          : <RankingPosition>{rankingPosition}<span>th</span></RankingPosition>
                      }
                    </Ranking>
                    <Points>
                      {points}{ points ? ' points' : null}
                    </Points>
                  </>
                      )}
              </ListAction>
            </Container>
            )
      }
    </>
  )
}
