import styled from 'styled-components'

export const Container = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
`

export const ProfileCertificatesContainer = styled.div`
    width: 300px;
    padding-bottom: 4px;
    overflow-y: hidden;
    overflow-x: scroll;
    display: flex;
    @media (max-width: 1440px) {
        padding-bottom: 4px;

    }
`

export const ProfileContainerContent = styled.div`
    display: flex;
`

export const ProfileCertificateTitle = styled.h1`
    font-family: var(--font-family-headline);
    font-weight: var(--fw-semibold);
    font-size: var(--fs-headline-subtitle-medium);
    line-height: var(--lh-spaced);
    color: var(--clr-neutral-100);
    width: 300px;

    padding-top: 2vh;
    @media (max-width: 1440px) {
        font-size: 20px;
    }
`

export const ProfileContainerCertificatesCards = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    gap: 24px;
`

export const ProfileCertificateCard = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 8px;
    align-items: center;
    isolation: isolate;
    width: 112px;
    height: 160px;
    border: 1px solid #C5C0DB;
    border-radius: 4px;
    background-color: var(--clr-neutral-900);
`

export const ProfileCertificateCardText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
`

export const ProfileCertificateCardImage = styled.div`
    box-sizing: border-box;
    width: 96px;
    height: 84px;
    border-radius: 2px;
`

export const ProfileCertificateCardImageLocked = styled.div`
    filter: brightness(50%);
`

export const ProfileCertificateCardTextCertificate = styled.span`
    margin-top: 8px;
    font-weight: var(--fw-medium);
    font-size: var(--fs-body-subtext-medium);
    color: var(--clr-neutral-300);
    line-height: var(--lh-tight);

`

export const ProfileCertificateCardTextDescription = styled.span`
    /* Onboarding */
    font-style: normal;
    margin-bottom: 8px;
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-subtext-large);
    color: var(--clr-primary-dark);
    line-height: var(--lh-tight);
    max-width: 96px;
`

export const TextLocked = styled.div`
    color: var(--clr-neutral-400);
    margin-bottom: 8px;
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-subtext-large);
    line-height: var(--lh-tight);
    max-width: 96px;
`

export const ProfileCertificateCardLocked = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 8px;
    align-items: center;
    isolation: isolate;
    width: 112px;
    height: 160px;
    border: 1px solid #C5C0DB;
    border-radius: 4px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.5) 0%, rgba(8, 8, 8, 0.5) 20.31%, rgba(40, 40, 40, 0.38) 100%);
`

export const ProfileCertificateCardIcoLocked = styled.span`
    position: relative;
    bottom: 45%;
`

export const ProfileCertificateCardTextLocked = styled.span`
    font-weight: var(--fw-regular);
    font-size: var(--fs-body-text-large);
    line-height: var(--lh-distant);
    display: flex;
    align-items: center;
    justify-content: center;
    /* Feedback/disabled */
    color: var(--clr-feedback-disabled);
`

export const ProfileCertificateCardIcoApproved = styled.span`
    /* Vector */
    position: relative;
    bottom: 95%;
    left: 35%;
`

export const ProfileCertificateButton = styled.button`
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #525399;
    border-radius: 10px;
    align-self: center;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;

    &:hover {
        background-color: #525399;
        color: #FFFFFF;
    }
`

export const CertificateButtonContainer = styled.div`
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`
