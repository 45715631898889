/* eslint-disable */
import { useState, useEffect, Fragment } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Arrow from "../Arrow";
import SubCategoryCard from "../SubCategoryCard";
import SkeletonLoader from "../VideoCard/Skeleton";
import {
  CategoryImage,
  CategoryName,
  Container,
  ImageContainer,
  InfoContainer,
  CardContainer,
  SubCategoriesContainer,
} from "./styled";
import api from "../../../services/api";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

interface CategoryProps {
  index: number;
  categoryId: string;
  thumbImage: string;
  name: string;
  initialValue: boolean;
  switchLabel: string;
  columnNumber: string;
  teamSelect: any;
  teamSelected: any;
  setPreview: (id: number) => void;
  onUpdate: (
    categoryId: string,
    newValue: boolean,
    columnNumber: string,
  ) => void;
  propUnselectedVideosIds: {
    [categoryId: string]: {
      [subCategoryId: string]: { [videoId: string]: boolean };
    };
  };
  subCategory: SubCategory;
  onUpdateUnselectedVideoIds: (
    categoryId: string,
    subCategoryId: string,
    videoId: string,
  ) => void;
}

interface VideoIds {
  [categoryId: string]: {
    [subCategoryId: string]: { [videoId: string]: boolean };
  };
}

interface Video {
  title: string;
  default_image: string;
  duration: string;
  admin_video_id: number;
  isSelected: boolean;
  onToggleSelect: (
    categoryId: number | string,
    subCategoryId: number,
    videoId: number,
  ) => void;
}

interface SubCategory {
  title: string;
  url_page_id: number;
  data: Video[];
}

interface HiddenVideos {
  id: number;
  category_id: number;
}

const CategoryCard = ({
  index,
  categoryId,
  thumbImage,
  name,
  initialValue,
  switchLabel,
  columnNumber,
  teamSelect,
  teamSelected,
  onUpdate,
  onUpdateUnselectedVideoIds,
  propUnselectedVideosIds,
  setPreview,
}: CategoryProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isUp, setIsUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryVideos, setCategoryVideos] = useState<{
    [key: string]: SubCategory[];
  }>({});
  const [selectedVideoIds, setSelectedVideoIds] = useState<VideoIds>({});

  const checkIfIsTheLastVideo = (categoryId: string) => {
    const selectedVideos = selectedVideoIds[categoryId];
    const allVideos = Object.values(selectedVideos)
      .map((subCategory) => Object.values(subCategory))
      .flat();
    const selectedVideosCount = allVideos.filter((video) => video).length;

    if (selectedVideosCount === 1) {
      toast.error("At least one video must be selected");
      return true;
    }
  };

  const toggleVideoSelection = (
    categoryId: number | string,
    subCategoryId: number,
    videoId: number | undefined,
  ) => {
    if (videoId === undefined || !categoryId) return;

    const categoryKey = categoryId.toString();
    const videoKey = videoId.toString();
    const subCategoryKey = subCategoryId.toString();

    setSelectedVideoIds((prev) => {
      const updated = { ...prev };
      if (!updated[categoryKey]) {
        updated[categoryKey] = {};
      }
      if (!updated[categoryKey][subCategoryKey]) {
        updated[categoryKey][subCategoryKey] = {};
      }

      if (updated[categoryKey][subCategoryKey][videoKey] == true) {
        if (checkIfIsTheLastVideo(categoryKey)) {
          return updated;
        }
        if (updated[categoryKey][subCategoryKey]) {
          if (updated[categoryKey][subCategoryKey][videoKey] == true) {
            updated[categoryKey] = {
              ...updated[categoryKey],
              [subCategoryKey]: {
                ...updated[categoryKey][subCategoryKey],
                [videoKey]: false,
              },
            };
          }
        }

        return updated;
      }

      if (updated[categoryKey][subCategoryKey][videoKey] == false) {
        if (updated[categoryKey][subCategoryKey]) {
          if (updated[categoryKey][subCategoryKey][videoKey] == false) {
            updated[categoryKey] = {
              ...updated[categoryKey],
              [subCategoryKey]: {
                ...updated[categoryKey][subCategoryKey],
                [videoKey]: true,
              },
            };
          }
        }
        return updated;
      }

      return updated;
    });

    onUpdateUnselectedVideoIds(categoryKey, subCategoryKey, videoKey);
  };

  const handleToggle = () => {
    const newValue = !initialValue;
    onUpdate(categoryId, newValue, columnNumber);
  };

  const handleArrowToggle = async () => {
    setIsLoading(true);

    if (!isExpanded && !categoryVideos[categoryId]) {
      await fetchVideosForCategory(categoryId);
    }
    setIsUp(!isUp);
    setIsExpanded(!isExpanded);
    setIsLoading(false);
  };

  const getHiddenVideosPerCategory = async (
    categoryId: string,
    subCategoryId: string,
  ) => {
    try {
      const organizationId = localStorage.getItem("organizationId");
      let teamId = 0;
      for (const team of teamSelect) {
        if (team.value === teamSelected.value) {
          teamId = team.teamId;
        }
      }
      const { data }: AxiosResponse<any> = await api.getMethod(
        `get_hidden_videos/${organizationId}/${categoryId}`, {
          teamId: teamId ?? 0
        }
      );

      setSelectedVideoIds((prev) => {
        const updated = { ...prev };
        data.data.forEach((video: HiddenVideos) => {
          if (!updated[categoryId]) {
            updated[categoryId] = {};
          }
          onUpdateUnselectedVideoIds(
            categoryId,
            subCategoryId,
            video.id.toString(),
          );
          updated[categoryId][subCategoryId][video.id.toString()] = false;
        });
        return updated;
      });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchVideosForCategory = async (categoryId: string) => {
    const { data }: any = await api.postMethod("home_second_section", {
      page_type: "CATEGORY",
      category_id: categoryId,
    });
    const subcategories = data.data;

    for (const subcategory of subcategories) {
      getHiddenVideosPerCategory(categoryId, subcategory.url_page_id);
    }

    if (subcategories.length === 0) {
      toast.success("No subcategories approved found for this category");
      return null;
    }

    setCategoryVideos((prev) => ({ ...prev, [categoryId]: subcategories }));

    setSelectedVideoIds((prevSelectedVideoIds) => {
      const newSelectedVideoIds = { ...prevSelectedVideoIds };

      subcategories.forEach((subCategory: SubCategory) => {
        const videoIds = subCategory.data.map(
          (video: Video) => video.admin_video_id,
        );

        if (!newSelectedVideoIds[categoryId]) {
          newSelectedVideoIds[categoryId] = {};
        }

        if (!newSelectedVideoIds[categoryId][subCategory.url_page_id]) {
          newSelectedVideoIds[categoryId][subCategory.url_page_id] = {};
        }

        videoIds.forEach((videoId: number) => {
          newSelectedVideoIds[categoryId][subCategory.url_page_id][
            videoId.toString()
          ] = propUnselectedVideosIds[categoryId]?.[subCategory.url_page_id]?.[
            videoId.toString()
          ]
            ? false
            : true;
        });
      });
      return newSelectedVideoIds;
    });
  };

  return (
    <Draggable
      key={String(categoryId)}
      draggableId={String(categoryId)}
      index={index}
    >
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          isExpanded={isExpanded}
        >
          <CardContainer>
            <ImageContainer>
              <CategoryImage src={thumbImage} />
            </ImageContainer>
            <InfoContainer>
              <CategoryName>{name}</CategoryName>
              <div
                className="custom-control custom-switch"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={initialValue}
                  onChange={handleToggle}
                  id={`customSwitch${categoryId}`}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`customSwitch${categoryId}`}
                  style={{
                    marginLeft: "6px",
                    paddingTop: "2px",
                    color:
                      switchLabel === "Required Category"
                        ? "#2196F3"
                        : "#1e1e1f",
                    fontWeight: switchLabel === "Required Category" ? 600 : 400,
                  }}
                >
                  {switchLabel}
                </label>
              </div>
            </InfoContainer>
            <Arrow onToggle={handleArrowToggle} />
          </CardContainer>
          {isExpanded && (
            <SubCategoriesContainer>
              {isLoading ? (
                <Fragment>
                  {[...Array(5)].map((_, idx) => (
                    <SkeletonLoader key={idx} />
                  ))}
                </Fragment>
              ) : (
                categoryVideos[categoryId]?.map((subCategory: SubCategory) => (
                  <SubCategoryCard
                    key={subCategory.title}
                    title={subCategory.title}
                    categoryId={categoryId}
                    selectedVideoIds={selectedVideoIds}
                    videos={subCategory.data}
                    onToggleSelect={toggleVideoSelection}
                    url_page_id={subCategory.url_page_id}
                    setPreview={setPreview}
                  />
                ))
              )}
            </SubCategoriesContainer>
          )}{" "}
        </Container>
      )}
    </Draggable>
  );
};
export default CategoryCard;
