import styled from 'styled-components'
export const Image = styled.div`
  flex: 0 0 20%;
  transition: transform 300ms ease 50ms;
  margin: 0 4px;
  position: relative;
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 25%;
  background-image: url(${(props) => props.image});
  border-radius: 8px;
  border: ridge 1px #303030;
  display: flex;
  justify-content: center

  img {
    width: 100%;
    vertical-align: top;
    border-radius: 8px;
  }
  .slider-content-box {
    font-size: 0.7em;
    padding: 1em;
    position: absolute;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5), transparent);
    display: none;
    // z-index: 3;
  }
  &:hover {
    .slider-content-box {
      display: block;
      border-radius: 8px;
    }
  }
  .thumbarrow-sec {
    .thumbarrow-white {
      display: none;
    }
    .thumbarrow-red {
      display: block;
    }
  }
  .show-details-button {
    span {
      display: none !important;
    }
  }
  .slider--open {
    .item {
      &:hover {
        .slider-content-box {
          display: none;
        }
      }
    }
  }
  @media(max-width: 480px) {
    .slider-content-box {
      font-size: 14px !important;
    }
  }

  @media (min-width: 480px) and (max-width: 768px) {
    height: 157px !important;
    width: 255px !important;
    flex: 0 0 20.7%;
  }

  @media (min-width: 769px) and (max-width: 1440px) {
    height: 160px !important;
    width: 240px !important;
    flex: 0 0 20.7%;
  }

  @media (min-width: 1700px) {
    height: 240px !important;
    width: 320px;
    flex: 0 0 20.7%;
  }
  @media (min-width: 1850px) {
    width: 320px;
    height: 250px !important;
    flex: 0 0 20.7%;
  }

  @media (min-width: 1920px) {
    height: 260px !important;
    width: 320px;
    flex: 0 0 20.7%;
  }
  @media (max-width: 425px) {
    background-image: url(${(props) => props.imageMobile});
    height: 20vh;
    width: 50vw;
  }

  @media (max-width: 321px) {
    background-image: url(${(props) => props.imageMobile});
    height: 16vh;
    width: 50vw;
  }
  `
