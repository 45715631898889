import React from 'react'

import {
  Container,
  UserImg,
  UserInfo,
  UserName,
  UserTeam,
  UserRole,
  BannerContainer,
  UserInfoContainer,
  LeftSideInfo,
  RightSideInfo,
  BioLabel,
  BioText,
  CloseButton
} from './styled'

import roleIco from '../../../assets/images/role-ico.svg'
import teamIco from '../../../assets/images/team-16-ico.svg'

import closeIco from '../../../assets/images/close-ico.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

type Props = {
  userImg: any,
  userName: string,
  userTeam: string,
  userRole: string,
  userBio: string,
  show: boolean,
  onClick: () => void,
  points?: any
}

export default function UserInfoModal ({ userImg, userName, userTeam, userRole, userBio, show, onClick, points }: Props) {
  if (!show) {
    return null
  }

  return (
      <Container>
        <BannerContainer>
          <CloseButton onClick={onClick}><LazyLoadImage src={closeIco} width='100%' height='100%'/></CloseButton>
        </BannerContainer>
        <UserInfoContainer>
          <LeftSideInfo>
            <UserImg>
              <LazyLoadImage src={userImg} width='156px' height='156px' style={{ borderRadius: '50%' }}/>
            </UserImg>
            <UserInfo>
              <UserName>{userName}</UserName>
              <UserRole><LazyLoadImage src={roleIco} width='18px' style={{ marginRight: '6px' }}/>{userRole}</UserRole>
              <UserTeam><LazyLoadImage src={teamIco} width='18px' style={{ marginRight: '6px' }}/>{userTeam}</UserTeam>
            </UserInfo>
            <RightSideInfo>
              <BioLabel>Bio:</BioLabel>
              <BioText>
                {userBio}
              </BioText>
          </RightSideInfo>
          </LeftSideInfo>
        </UserInfoContainer>
      </Container>
  )
}
