/* eslint-disable camelcase */
import React, { createContext, ReactNode, useState } from 'react'

type VideoProviderProps = {
  children: ReactNode
}

type VideoContextData = {
  video: Video | null,
  setCurrentVideo: (video: Video) => void
}

export type Video = {
  admin_unique_id: string
  admin_video_id: number
  age: string
  banner_image: string
  cast_crews: [{
    cast_crew_id: number,
    name: string
  }]
  category_id: number
  currency: string
  default_image: string
  description: string
  details: string
  dislikes: string
  download_button_status: number
  download_status: number
  download_status_text: string
  download_urls: [{
    link: string,
    title: string,
    type: string
  }]
  downloading_video_status: number
  duration: string
  genre_id: number
  history_status: number
  hls_main_video: string
  images: []
  is_kids_video: number
  is_liked: number
  is_pay_per_view: number
  is_series: number
  is_user_need_subscription: number
  likes: string
  main_resolutions: any
  main_video: string
  mobile_banner_image: string
  mobile_image: string
  next_admin_video_id: number
  next_player_json: string
  playbook_url: string
  player_json: string
  position: number
  ppv_amount: number
  ppv_page_type: number
  ppv_page_type_content: []
  publish_time: Date
  ratings: string
  reviews: string
  seek_time_in_seconds: string
  share_link: string
  share_message: string
  should_display_ppv: number
  sub_category_id: number
  title: string
  trailer_duration: string
  trailer_resize_path: string
  trailer_subtitle: string
  trailer_subtitle_vtt: string
  trailer_video: string
  trailer_video_resolutions: null
  type_of_subscription: number
  type_of_user: number
  user_type: number
  video: string
  video_play_duration: string
  video_resize_path: string
  video_resolutions: string
  video_subtitle: string
  video_subtitle_vtt: string
  video_type: number
  video_upload_type: number
  watch_count: number
  wishlist_status: number
}

export const VideoContext = createContext<VideoContextData>({} as VideoContextData)

export function VideoProvider ({ children }: VideoProviderProps) {
  const [video, setVideo] = useState<Video | null>(null)

  function setCurrentVideo (video: Video) {
    setVideo(video)
  }

  return (
    <VideoContext.Provider
      value={{
        video,
        setCurrentVideo
      }}
    >
      {children}
    </VideoContext.Provider>
  )
}
