/* eslint-disable */
import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--clr-neutral-900);
  transition: border 0.2s ease;
  border: ${(props) =>
    props.isDragging ? "2px solid var(--clr-secondary-light)" : "none"};
  padding: 14px;
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: var(--elevation-4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease; // Smooth transition for expanding/collapsing
  height: ${(props) => (props.isExpanded ? "auto" : "9rem")};
  max-height: ${(props) => (props.isExpanded ? "auto" : "9rem")};
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 8.24rem;
  max-height: 8.24rem;
`;

export const ImageContainer = styled.div`
  box-sizing: border-box;
  width: auto;
  height: 100%;
`;

export const CategoryImage = styled.img`
  box-sizing: border-box;
  border: 0.1px solid var(--clr-neutral-600);
  width: auto;
  height: 8rem;
  border-radius: 4px;
  object-fit: cover;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  margin-left: 16px;
  max-width: 100%;
  overflow: hidden;
`;

export const CategoryName = styled.h3`
  font-family: var(--font-family-headline);
  font-weight: var(--fw-semibold);
  font-size: var(--fs-body-text-medium);
  line-height: var(--lh-spaced);
  color: var(--clr-neutral-200);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const CategoryVideos = styled.p`
  font-family: var(--font-family-headline);
  font-weight: var(--fw-regular);
  font-size: var(--fs-body-subtext-large);
  line-height: var(--lh-medium);
  color: var(--clr-neutral-200);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: 0;
`;

export const SubCategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;
