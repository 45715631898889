/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { AxiosResponse } from 'axios'
import api from '../../../services/api'
import Loading from '../../Loading'
import ApprovedIco from '../../../assets/images/approved-ico.svg'
import LockIco from '../../../assets/images/lock-ico.png'
import { Props } from './interfaces'
import {
  AchievementsCard,
  AchievementsCardImage,
  AchievementsCardInfoContainer,
  AchievementsCardType,
  AchievementsCardTitle,
  AchievementsCardIssuedIn,
  AchievementsCardContainer
} from './styled'

const AchievementsCertificates = ({ typeCertificates }: Props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [categories, setCategories] = useState<any[]>([])
  const [categoriesInfo, setCategoriesInfo] = useState<any>([])
  const [userIsApprovedInChallenge, setUserIsApprovedInChallenge] = useState<boolean[]>([])
  const [userDateApprovedInChallenge, setUserDateApprovedInChallenge] = useState<string[]>([])

  const verifyIfUserDoesHaveApprovedInChallenge = async (categoryId: number) => {
    const { data }: any = await api.getMethod(`get_user_certificate_by_category?user_id=${localStorage.getItem('userId')}&category_id=${categoryId}`)
    if (!data.success) {
      return { isApproved: false, dateApproved: 'Not yet obtained' }
    }
    if (parseInt(data.certification.category_id) === Number(categoryId)) {
      const dateApproved: string = data?.certification?.created_at.slice(0, 10).replace(/-/g, '/')
      return { isApproved: true, dateApproved: dateApproved }
    }
    return { isApproved: false, dateApproved: 'Not yet obtained' }
  }

  const getCategory = async (categoriesParams: any) => {
    const arrCategories: any = []
    const dataUserIsApprovedInChallenge: boolean[] = []
    const dataUserDateApprovedInChallenge: string[] = []
    for (let categoryParam of categoriesParams) {
      const { data }: AxiosResponse<any[any]> = await api.postMethod('get_category_id', {
        page_type: 'CATEGORY',
        category_id: categoryParam.category_id
      })

      const verifyCertificate: any = await verifyIfUserDoesHaveApprovedInChallenge(categoryParam.category_id)
      if (verifyCertificate.isApproved) {
        dataUserIsApprovedInChallenge.push(true)
        dataUserDateApprovedInChallenge.push(verifyCertificate.dateApproved)
      } else {
        dataUserIsApprovedInChallenge.push(false)
        dataUserDateApprovedInChallenge.push(verifyCertificate.dateApproved)
      }

      arrCategories.push(data[0])
    }
    setCategoriesInfo(arrCategories)
    setUserIsApprovedInChallenge(dataUserIsApprovedInChallenge)
    setUserDateApprovedInChallenge(dataUserDateApprovedInChallenge)
  }

  const getCertificatesInfo = async () => {
    const organizationCategories: AxiosResponse<any[any]> = await api.getMethod(`get_all_organization_categories/${parseInt(localStorage.getItem('organizationId') as string)}`, {
      team_id: localStorage.getItem('teamId')
    })
    setCategories(organizationCategories?.data?.data)

    await getCategory(categories)

    setLoading(false)
  }

  useEffect(() => {
    getCertificatesInfo()
  }, [categories.length])

  if (loading) {
    <Loading />
  }

  return (
    <React.Fragment>
      <AchievementsCardContainer>
        {categoriesInfo.map((category: any, index: number) => {
          return (
            typeCertificates === 'obtained' && userIsApprovedInChallenge[index] === true
              ? <React.Fragment key={index}>
                  <AchievementsCard>
                    <AchievementsCardImage><LazyLoadImage src={ category.picture } style={{ width: '100%', height: '100%' }}/></AchievementsCardImage>
                    <AchievementsCardInfoContainer>
                      <AchievementsCardType>
                        Certificate<LazyLoadImage src={ ApprovedIco } width='12px' />
                      </AchievementsCardType>
                      <AchievementsCardTitle>{ category.name }</AchievementsCardTitle>
                      <AchievementsCardIssuedIn>Issued { userDateApprovedInChallenge[index] }</AchievementsCardIssuedIn>
                      {/* <AchievementsCardDescription>Issued to all new users who complete the { category.name } Course</AchievementsCardDescription> */}
                    </AchievementsCardInfoContainer>
                  </AchievementsCard>
                </React.Fragment>
              : (typeCertificates === 'remaining' && userIsApprovedInChallenge[index] === false
                  ? <React.Fragment key={index}>
                      <AchievementsCard>
                        <AchievementsCardImage><LazyLoadImage src={ category.picture } style={{ width: '100%', height: '100%' }}/></AchievementsCardImage>
                        <AchievementsCardInfoContainer>
                          <AchievementsCardType>
                            Certificate<LazyLoadImage src={ LockIco } width='12px' style={{ marginLeft: '4px', marginBottom: '4px' }} />
                          </AchievementsCardType>
                          <AchievementsCardTitle>{ category.name }</AchievementsCardTitle>
                          <AchievementsCardIssuedIn>{ userDateApprovedInChallenge[index] }</AchievementsCardIssuedIn>
                          {/* <AchievementsCardDescription>Issued to all new users who complete the { category.name } Course</AchievementsCardDescription> */}
                        </AchievementsCardInfoContainer>
                      </AchievementsCard>
                    </React.Fragment>
                  : null
                )
          )
        })}
      </AchievementsCardContainer>
    </React.Fragment>
  )
}

export default AchievementsCertificates
