import styled from 'styled-components'

// import certificatesMobileIco from '../../../assets/images/certificates-mobile-ico.svg'
// import recentInfoIco from '../../../assets/images/recent-info-ico.svg'

// const recentIco = new URL('../../../assets/images/recent-info-ico.svg', import.meta.url)

export const Container = styled.div`
  width: 100%;
  height: fit-content;

  @media (max-width: 388.67px) {
    width: 95%;
    height: 95%;
  }
`
export const UserImgContainerLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 116px;
  height: 116px;
  background-color: var(--clr-secondary-default);
  border-radius: 50%;
  margin-top: -80px;
`

export const UserImgContainerMiddle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--clr-secondary-default);
  border-radius: 50%;

  @media (min-width: 426px) and (max-width: 1400px) {
    width: 160px;
    height: 160px;
  }

  @media  (max-width: 426px) {
    width: 111px;
    height: 111px;
  }

  @media (max-width: 376px) {
    width: 90px;
    height: 90px;
  }

`

export const MobileVersion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 65px;
  height: 100%;
  min-width: fit-content;
  width: calc(100% - 160px);
  max-width: 100%;

  @media (max-width: 689px) {
    gap: 85px;
  }

  @media (max-width: 426px) {
    width: 72%;
    gap: 64px;

  }

  @media (max-width: 376px) {
    width: 68%;
    gap: 64px;
  }
`

export const FirstMobileButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-neutral-900);
  padding: 11.5px 24px;
  border: 1px solid var(--clr-secondary-default);
  border-radius: 14px;
  font-weight: var(--fw-regular);
  font-size: var(--fs-body-text-medium);
  color: var(--clr-secondary-default);
  box-shadow: var(--elevation-2);

  ::before {
    content: 'Recent info'
  }

  :hover {
    background-color: var(--clr-secondary-light);
    color: var(--clr-secondary-dark);
    font-weight: var(--fw-medium);
  }

  :focus {
    outline: none;
  }

  @media (max-width: 689px) {
    ::before {
      content: url('./recent-info-ico.svg');
      width: 24px;
      height: 24px;
      
    }
    padding: 11.5px 12px;

    }
`

export const SecondMobileButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-neutral-900);
  padding: 11.5px 24px;
  border: 1px solid var(--clr-secondary-default);
  border-radius: 14px;
  font-weight: var(--fw-regular);
  font-size: var(--fs-body-text-medium);
  color: var(--clr-secondary-default);
  box-shadow: var(--elevation-2);

  ::before {
    content: 'Certificates'
  }

  :hover {
    background-color: var(--clr-secondary-light);
    color: var(--clr-secondary-dark);
    font-weight: var(--fw-medium);
  }

  :focus {
    outline: none;
  }

  @media (max-width: 689px) {
    ::before {
      content: url('./certificates-mobile-ico.svg');
      width: 24px;
      height: 24px;
      
    }
    padding: 11.5px 12px;

  }
`

export const ThirdMobileButton = styled.button`
  display: none;
  
  @media (max-width: 993px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-neutral-900);
    padding: 11.5px 24px;
    border: 1px solid var(--clr-secondary-default);
    border-radius: 14px;
    font-weight: var(--fw-regular);
    font-size: var(--fs-body-text-medium);
    color: var(--clr-secondary-default);
    box-shadow: var(--elevation-2);

    ::before {
      content: 'People'
    }

    :hover {
      background-color: var(--clr-secondary-light);
      color: var(--clr-secondary-dark);
      font-weight: var(--fw-medium);
    }

    :focus {
      outline: none;
    }

    @media (max-width: 689px) {
      ::before {
        content: url('./people-list-ico.svg');
        width: 24px;
        height: 24px;
        
      }
      padding: 11.5px 12px;

    }
  }
`

export const MobileButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  visibility: hidden;
`

export const UserName = styled.text`
  font-weight: var(--fw-semibold);
  color: var(--clr-neutral-200);
  font-family: var(--font-family-headline);
  font-size: var(--fs-headline-subtitle-medium);
  line-height: var(--lh-tight);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  text {
    color: var(--clr-primary-light);
    font-size: var(--fs-body-subtext-large);
    font-weight: var(--fw-medium);
    margin-top: -2px;
  }

  @media (max-width: 1399px) {
    font-size: var(--fs-headline-subtitle-large);

  }
`

export const UserInfoProfileLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const UserInfoProfileMiddle = styled.div`

  display: none;

  @media (min-width: 689px) and (max-width: 1400px) {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: fit-content;
    
    color: var(--clr-neutral-200);
    margin-top: -80px;

  }

  @media (max-width: 689px) {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: fit-content;
    width: 100%;
    margin-top: -100px;
    padding: 0 4vw;
    color: var(--clr-neutral-200);

  }
`
export const UserTeam = styled.text`
  font-size: var(--fs-body-text-medium);
  font-weight: var(--fw-medium);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  line-height: var(--lh-medium);
  margin-top: -2px;
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
`

export const UserLoginInfo = styled.div`
  font-size: var(--fs-body-subtext-large);
  font-weight: var(--fw-medium);
`

export const UserInfoMiddle = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 8px;

  @media (max-width: 573px) {
    align-self: flex-start;
  }
`
