import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import api from '../../../services/api'
import './styles.css'

const Banner = () => {
  const BANNER_INTERVAL = 30000 // 30s
  const [currentImage, setCurrentImage] = useState<any>({})

  function getRandom (min: number, max: number) {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min))) + Math.ceil(min)
  }

  useEffect(() => {
    api.getMethod(`get_banner_organization/${localStorage.getItem('organizationId')}`).then((res: any) => {
      const { data } = res
      if (localStorage.getItem('organizationId') === undefined) {
        setCurrentImage(data[getRandom(0, data.length)])
        setInterval(() => {
          setCurrentImage(data[getRandom(0, data.length)])
        }, BANNER_INTERVAL)
      } else {
        setCurrentImage(data[0].banner_url)
      }
    })
  }, [])

  return <LazyLoadImage
    alt="banner"
    effect="blur"
    src={currentImage}
    className="banner-logged"
  />
}

export default Banner
