import React, { useEffect, useRef, useState } from 'react'
import api from '../../../services/api'
import { ContainerProfilePictureUpload } from './style'

const ProfilePictureUpload = () => {
  const inputFileRef = useRef<any>()
  const [file, setFile] = useState<File | null>(null)
  const [userPicture, setUserPicture] = useState<string>(() => {
    const user = localStorage.getItem('user')
    return user ? JSON.parse(user).picture : ''
  })

  const setFilehandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files?.[0] as File)
  }

  const openSelectImageHandler = () => {
    const input = inputFileRef.current
    input?.click()
  }

  const uploadImage = async (picture: File) => {
    const { data }: any = await api.postMethod('v4/update_profile_image', { picture, user_id: localStorage.getItem('userId') })

    if (data.user) {
      localStorage.setItem('user', JSON.stringify(data.user))
      setUserPicture(data.user.picture)
    }
  }

  useEffect(() => {
    if (file) {
      uploadImage(file)
    }
  }, [file])

  return (
    <ContainerProfilePictureUpload>
      <img src={userPicture} title='Click on the image above to upload your picture' alt='user picture' onClick={() => openSelectImageHandler()}/>
      <input ref={inputFileRef} className='input-file' type='file' onChange={setFilehandler} />
      <p className='text-bubble' style={{ fontWeight: '500' }}>Click on the image above to upload your picture!</p>
    </ContainerProfilePictureUpload>
  )
}
export default ProfilePictureUpload
