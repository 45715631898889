/* eslint-disable */
import styled, { keyframes } from "styled-components";

interface SkeletonTextProps {
  width?: string;
  marginTop?: string;
}

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(100% + 200px) 0;
  }
`;

const SkeletonContainer = styled.div`
  background-color: var(--clr-neutral-700);
  border-radius: 6px;
  padding: 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const SkeletonElement = styled.div`
  background-color: var(--clr-neutral-600);
  border-radius: 4px;
  background-image: linear-gradient(
    to right,
    var(--clr-neutral-600) 0%,
    var(--clr-neutral-500) 20%,
    var(--clr-neutral-600) 40%,
    var(--clr-neutral-600) 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;

const SkeletonThumbnail = styled(SkeletonElement)`
  min-width: 80px;
  max-width: 80px;
  min-height: 45px;
  max-height: 45px;
`;

const SkeletonText = styled(SkeletonElement)<SkeletonTextProps>`
  height: 10px;
  width: ${({ width }) => width || "50%"};
  margin-top: ${({ marginTop }) => marginTop || "0"};
`;

const SkeletonCheckbox = styled(SkeletonElement)`
  min-width: 15px;
  max-width: 15px;
  min-height: 15px;
  max-height: 15px;
`;

const SkeletonContent = styled.div`
  width: 100%;
`;

const SkeletonLoader = () => (
  <SkeletonContainer aria-hidden="true">
    <SkeletonCheckbox />
    <SkeletonThumbnail />
    <SkeletonContent>
      <SkeletonText width="60%" />
      <SkeletonText width="30%" marginTop="5px" />
    </SkeletonContent>
  </SkeletonContainer>
);

export default SkeletonLoader;
