import React from 'react'
import Header from '../../../../components/Auth/Header'
import OrganizationForm from '../../../../components/Auth/OrganizationForm'

import {
  ContainerMainContent
} from './styled'

const EditOrganization = () => {
  return (
    <React.Fragment>
      <Header />
      <ContainerMainContent>
        <OrganizationForm />
      </ContainerMainContent>
    </React.Fragment>
  )
}

export default EditOrganization
