import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import { t as translate } from 'react-multi-lang'

import logoMark from '../../../assets/images/MM-Logomark-2C.png'
import wordMark from '../../../assets/images/MM-Wordmark-Reversed.png'

import useAuth from '../../../hooks/useAuth'
import apiUrl, { apiConstants } from '../../../services/api'
import Footer from '../../../components/Footer'
import {
  ContainerHeader,
  Header,
  Video,
  Banner,
  GetStarted,
  SmallVideo,
  VideoSection,
  FaqContainer,
  FaqList
} from './styled'
import Loading from '../../../components/Loading'

export default function Home () {
  const [homeSettings, setHomeSettings] = useState<any>([])
  const [answer, setAnswer] = useState<any>()
  const [faqData, setFaqData] = useState<any>(null)
  const videoRef = useRef(null)

  const { signIn } = useAuth()

  async function fetchConfig () {
    const { data } = await Axios.get(apiConstants.homeSettingsUrl)
    setHomeSettings(data)
  }

  async function getFaqs () {
    const { data }: any = await apiUrl.getMethod('faqs/list')
    setFaqData(data.data)
  }

  const handleGuestUser = () => {
    const loginGuest: string = (process.env.REACT_APP_GUEST_LOGIN as string)
    const passGuest: string = (process.env.REACT_APP_GUEST_PASS as string)

    signIn(loginGuest, passGuest)
  }

  useEffect(() => {
    fetchConfig()
    getFaqs()
  }, [])

  if (!faqData) {
    return <Loading />
  }

  return (
    <>
      <ContainerHeader>
        <Video
          src={homeSettings.banner_video}
          autoPlay
          controls
          muted
          ref={videoRef}
          controlsList="nodownload"
        />

        <Header>
          <div>
            <img
              src={logoMark}
              className="new-logo"
              alt="logo manufacturing masters"
            />
            <img
              src={wordMark}
              className="new-logo-word"
              alt="logo manufacturing masters"
            />
          </div>
          <Link to="/login" className="signin-btn">
            {translate('signin')}
          </Link>
        </Header>

        <Banner>
          <h1 className="banner-title">
            {homeSettings.banner_title}
          </h1>
        </Banner>

        <GetStarted>
          <Link to="#" onClick={handleGuestUser}>
              {translate('guestAccess')}
          </Link>
        </GetStarted>
      </ContainerHeader>

      <VideoSection>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <SmallVideo
                src={homeSettings.section1_video}
                controls
                controlsList="nodownload"
              />
            </div>
            <div className="col-md-1 my-3" />
            <div className="col-md-6">
              <h2>{homeSettings.section1_title}</h2>
              <h4>{homeSettings.section1_description}</h4>
            </div>
          </div>
        </div>
      </VideoSection>

      <VideoSection>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2>{homeSettings.section2_title}</h2>
              <h4>{homeSettings.section2_description}</h4>
            </div>
            <div className="col-md-1 my-3" />
            <div className="col-md-5">
              <SmallVideo
                src={homeSettings.section2_video}
                controls
                controlsList="nodownload"
                className="mobile-img"
              />
            </div>
          </div>
        </div>
      </VideoSection>

      <VideoSection>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <SmallVideo
                src={homeSettings.section3_video}
                controls
                controlsList="nodownload"
                className="mobile-img" />
            </div>
            <div className="col-md-1 my-3" />
            <div className="col-md-6">
              <h2>{homeSettings.section3_title}</h2>
              <h4>{homeSettings.section3_description}</h4>
            </div>
          </div>
        </div>
      </VideoSection>

      <FaqContainer>
        <img
          src={logoMark}
          className="new-logo"
          alt="logo manufacturing masters"
        />
        <h2>{translate('faq_title')}</h2>
        <h4>{translate('faq_subtitle')}</h4>

        <FaqList>
          {faqData.map((faq: any) => (
            <>
              <div
                className="card text-center"
                key={faq.faq_id}
                data-toggle="modal"
                data-target="#modalFaq"
                onClick={() => setAnswer(faq)}
              >
                <div className="card-body d-flex align-items-center justify-content-center">
                  {faq.question}
                </div>
              </div>
            </>
          ))}
          {
            !!answer && (
              <div className="modal fade" id="modalFaq" tabIndex={-1} role="dialog" aria-labelledby="modalFaq" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h3 className="modal-title w-100 text-center">{answer.question}</h3>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body small" dangerouslySetInnerHTML={{
                      __html: answer.answer
                    }}>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </FaqList>
        <div className="d-flex w-100 justify-content-center">
          <Link to="/register">
            {translate('getting_started')}
          </Link>
        </div>
      </FaqContainer>

      <Footer />
    </>
  )
}
