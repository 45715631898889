import React from 'react'
import { Link } from 'react-router-dom'

import Logo from '../Logo'
import { ContainerStaticHeader } from './styled'

const StaticHeader = () => {
  return (
    <Link to="/login">
      <ContainerStaticHeader>
        <Logo />
      </ContainerStaticHeader>
    </Link>
  )
}

export default StaticHeader
