import React, { useState } from 'react'
import { t } from 'react-multi-lang'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import Header from '../../../../components/Auth/Header'
import ToggleButton from '../../../../components/ToggleButton'
import useAuth from '../../../../hooks/useAuth'
import api from '../../../../services/api'
import { Button } from '../../../Common/ForgotPassword/styled'

import {
  ContainerChangePassword
} from './styled'

const ChangePassword = () => {
  const [buttonDisable, setButtonDisable] = useState(false)
  const [loadingContent, setLoadingContent] = useState<string | null>(null)
  const [old, setOld] = useState('')
  const [newPassword, setNew] = useState('')
  const [confirm, setConfirm] = useState('')

  const history = useHistory()
  const { userDetails, me } = useAuth()

  async function changePassword (event: any) {
    event.preventDefault()
    setLoadingContent(t('button_loading'))
    setButtonDisable(true)

    const { data }: any = await api.postMethod('changePassword', {
      old_password: old,
      password_confirmation: confirm,
      password: newPassword
    })

    if (data.success) {
      toast.success(data.message)
      me()
      setTimeout(() => {
        history.push('/home')
      }, 500)
    } else toast.error(data.error_messages)

    setLoadingContent(null)
    setButtonDisable(false)
  }

  return (
    <>
      {!userDetails?.is_change_password && <Header />}
      <ContainerChangePassword>
        <div className="row mt-5">
          <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4 mx-auto">
            <h3 className="register-box-head">
              {t('change_password')}
            </h3>
            <form
              onSubmit={changePassword}
              className="auth-form"
            >
              <div className="form-group">
                <label htmlFor="old" className="text-blue-logo">
                  {t('old_password')}
                </label>
                <input
                  type="password"
                  onChange={text => setOld(text.target.value)}
                  className="form-control"
                  id="old"
                  name="old"
                  value={old}
                />
              </div>
              <div className="form-group">
                <label htmlFor="new" className="text-blue-logo">
                  {t('new_password')}
                </label>
                <input
                  type="password"
                  onChange={text => setNew(text.target.value)}
                  className="form-control"
                  id="new"
                  name="new"
                  value={newPassword}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirm" className="text-blue-logo">
                  {t('confirm_password')}
                </label>
                <input
                  type="password"
                  onChange={text => setConfirm(text.target.value)}
                  className="form-control"
                  id="confirm"
                  name="confirm"
                  value={confirm}
                />
              </div>
              <Button
                className="btn mt-4 w-100"
                disabled={buttonDisable}
              >
                {loadingContent != null
                  ? loadingContent
                  : t('save')}
              </Button>
            </form>
          </div>
        </div>
      </ContainerChangePassword>
      <ToggleButton />
    </>
  )
}

export default ChangePassword
