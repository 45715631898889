import axios from 'axios'

import { getLanguage } from 'react-multi-lang'

export const apiConstants = {
  // Common variables

  LOGIN_BY: 'manual',

  DEVICE_TYPE: 'web',

  DEVICE_TOKEN: '123456',

  // Song download status

  DOWNLOAD_INITIATE_STAUTS: 1,

  DOWNLOAD_PROGRESSING_STAUTS: 2,

  DOWNLOAD_PAUSE_STAUTS: 3,

  DOWNLOAD_COMPLETE_STAUTS: 4,

  DOWNLOAD_CANCEL_STAUTS: 5,

  // Wishlist status

  WISHLIST_CLEAR_ALL: 1,

  WISHLIST_CLEAR_SINGLE_AUDIO: 0,

  // History status

  HISTORY_CLEAR_ALL: 1,

  HISTORY_CLEAR_SINGLE_AUDIO: 0,

  // PAGE TYPES

  PRIVACY_PAGE_TYPE: 'privacy',

  TERMS_PAGE_TYPE: 'terms',

  // Hisotry status

  HISTORY_INITIATE_STATUS: 1,

  HISTORY_COMPLETE_STATUS: 2,

  HISTORY_DELETE_STATUS: 3,

  USER_API_CALL: '/user',

  HOST_API_CALL: '/provider',

  SERIES: 21,

  MOVIES: 25,

  RECENTLY_ADDED: 33,

  CATEGORY: 54,

  KIDS: 12,

  GROWTH: 13,

  OPERATIONS: 14,

  TECHNICAL: 15,

  LEADERSHIP: 17,

  // Social Login Credentials

  GOOGLE_CLIENT_ID: '',

  FACEBOOK_APP_ID: '',

  JWPLAYER_KEY: 'M2NCefPoiiKsaVB8nTttvMBxfb1J3Xl7PDXSaw==',

  settingsUrl: (process.env.REACT_APP_API_URL || 'http://localhost:5000/userApi/') + 'get_settings_json',

  homeSettingsUrl: (process.env.REACT_APP_API_URL || 'http://localhost:5000/userApi/') + 'get_home_settings_json',

  socketUrl: process.env.REACT_APP_SOCKET_URL || 'http://localhost:3003/'
}

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000/userApi/'

const api = {
  postMethod (url: string, formDataParam: any) {
    const userId: string | null = localStorage.getItem('userId') !== '' &&
    localStorage.getItem('userId') !== null &&
    localStorage.getItem('userId') !== undefined
      ? localStorage.getItem('userId')
      : ''

    const accessToken: string | null = localStorage.getItem('accessToken') !== '' &&
    localStorage.getItem('accessToken') !== null &&
    localStorage.getItem('accessToken') !== undefined
      ? localStorage.getItem('accessToken')
      : ''

    const formData = new FormData()

    // By Default Id and token
    formData.append('id', userId as string)
    formData.append('token', accessToken as string)
    formData.append('language', getLanguage())
    formData.append('sub_profile_id', localStorage.getItem('active_profile_id') as string)

    let socialLoginUser = 0

    // append your data
    for (const key in formDataParam) {
      formData.append(key, formDataParam[key])

      if (key === 'social_unique_id') {
        socialLoginUser = 1
      }
    }

    // By Default added device type and login type in future use
    if (!socialLoginUser) {
      formData.append('login_by', apiConstants.LOGIN_BY)
    }

    formData.append('device_type', apiConstants.DEVICE_TYPE)
    formData.append('device_token', apiConstants.DEVICE_TOKEN)

    return axios.post(apiUrl + url, formData)
  },

  getMethod (url: string, formDataParam: any = {}) {
    const userId: string | null = localStorage.getItem('userId') !== '' &&
    localStorage.getItem('userId') !== null &&
    localStorage.getItem('userId') !== undefined
      ? localStorage.getItem('userId')
      : ''

    const accessToken: string | null = localStorage.getItem('accessToken') !== '' &&
    localStorage.getItem('accessToken') !== null &&
    localStorage.getItem('accessToken') !== undefined
      ? localStorage.getItem('accessToken')
      : ''

    const organizationId: string | null = localStorage.getItem('organizationId') !== '' &&
    localStorage.getItem('organizationId') !== null &&
    localStorage.getItem('organizationId') !== undefined
      ? localStorage.getItem('organizationId')
      : ''

    // By Default values
    formDataParam.id = (userId as string)
    formDataParam.token = (accessToken as string)
    formDataParam.organizationId = (organizationId as string)
    formDataParam.language = getLanguage()
    formDataParam.login_by = apiConstants.LOGIN_BY
    formDataParam.device_type = apiConstants.DEVICE_TYPE
    formDataParam.device_token = apiConstants.DEVICE_TOKEN

    // Refactor axios.get as it works differently than post
    return axios.get(apiUrl + url, {
      params: formDataParam
    })
  },

  putMethod (url: string, formDataParam: any) {
    const userId: string | null = localStorage.getItem('userId') !== '' &&
    localStorage.getItem('userId') !== null &&
    localStorage.getItem('userId') !== undefined
      ? localStorage.getItem('userId')
      : ''

    const accessToken: string | null = localStorage.getItem('accessToken') !== '' &&
    localStorage.getItem('accessToken') !== null &&
    localStorage.getItem('accessToken') !== undefined
      ? localStorage.getItem('accessToken')
      : ''

    const formData = new FormData()

    // By Default Id and token
    formData.append('id', userId as string)
    formData.append('token', accessToken as string)
    formData.append('language', getLanguage())
    formData.append('sub_profile_id', localStorage.getItem('active_profile_id') as string)

    let socialLoginUser = 0

    // append your data
    for (const key in formDataParam) {
      formData.append(key, formDataParam[key])

      if (key === 'social_unique_id') {
        socialLoginUser = 1
      }
    }

    // By Default added device type and login type in future use
    if (!socialLoginUser) {
      formData.append('login_by', apiConstants.LOGIN_BY)
    }

    formData.append('device_type', apiConstants.DEVICE_TYPE)
    formData.append('device_token', apiConstants.DEVICE_TOKEN)

    return axios.put(apiUrl + url, formData)
  },

  deleteMethod (url: string, formDataParam: any) {
    const userId: string | null = localStorage.getItem('userId') !== '' &&
    localStorage.getItem('userId') !== null &&
    localStorage.getItem('userId') !== undefined
      ? localStorage.getItem('userId')
      : ''

    const accessToken: string | null = localStorage.getItem('accessToken') !== '' &&
    localStorage.getItem('accessToken') !== null &&
    localStorage.getItem('accessToken') !== undefined
      ? localStorage.getItem('accessToken')
      : ''

    const team: string | null = localStorage.getItem('team') !== '' &&
    localStorage.getItem('team') !== null &&
    localStorage.getItem('team') !== undefined
      ? localStorage.getItem('team')
      : ''

    const organizationId: string | null = localStorage.getItem('organizationId') !== '' &&
    localStorage.getItem('organizationId') !== null &&
    localStorage.getItem('organizationId') !== undefined
      ? localStorage.getItem('organizationId')
      : ''

    // Refactor axios.get as it works differently than post
    return axios.delete(apiUrl + url, {
      params: {
        id: userId,
        token: accessToken,
        organizationId: organizationId,
        team: team,
        language: getLanguage(),
        login_by: apiConstants.LOGIN_BY,
        device_type: apiConstants.DEVICE_TYPE,
        device_token: apiConstants.DEVICE_TOKEN,
        data: formDataParam
      }
    })
  }
}

export default api
