/* eslint-disable camelcase */
/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

// Components
import UserHeader from '../../../components/Auth/Header'
import { ButtonOrange } from '../../../components/Buttons'
import Loading from '../../../components/Loading'
import useAuth from '../../../hooks/useAuth'
import useTeams from '../../../hooks/useTeams'
import api from '../../../services/api'
import { SmallVideo } from '../../Common/Landpage/styled'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const animatedOptions = makeAnimated()

const ShareEmails = (props: any) => {
  const isSuperUser = Boolean(Number(localStorage.getItem('isSuperUser')))
  const isGuestUser = Boolean(Number(localStorage.getItem('isGuestUser')))
  const [email, setEmail] = useState('')
  const [content, setContent] = useState('')
  const [emails, setEmails] = useState<string[]>([])
  const [video, setVideo] = useState<any>()
  const [option, setOption] = useState<string>('' as string)
  const [team, setTeam] = useState<string>('' as string)
  const [teamMembers, setTeamMembers] = useState<any[]>([])
  const [teamMembersSelected, setTeamMembersSelected] = useState<any[]>([])
  const { organizationTeams } = useTeams()
  const history = useHistory()
  const { user } = useAuth()

  const selectOptions = [
    { label: 'Select a Option' },
    { value: '1', label: 'Share with team members' },
    { value: '2', label: 'Share with all users in the organization' },
    { value: '3', label: 'Share with a e-mail' }
  ]

  async function searchVideo (admin_video_id: string) {
    const { data }: any = await api.postMethod('videos/view', {
      admin_video_id,
      skip: 0
    })

    if (data.data.is_user_need_subscription) {
      toast.error('To share this video you need to hire a plan. Contact your boss or sign-up.')
      history.push('/plans')
    } else {
      setVideo(data.data)
    }
  }

  const getTeamMembers = async () => {
    const response = await api.getMethod(`organization/teams/${team}/users`)

    if (response.status === 200) {
      setTeamMembers([...response.data])
    }
  }

  useEffect(() => {
    searchVideo(props.location.state?.videoDetailsFirst.admin_video_id as string)
  }, [])

  useEffect(() => {
    if (team !== '') {
      getTeamMembers()
    }
  }, [team])

  useEffect(() => {
    console.log(isSuperUser && !isGuestUser)
    setTeamMembersSelected([])
    setEmails([])
    setTeam('')
  }, [option])

  async function share () {
    if (emails.length > 0 || option === '2' || teamMembersSelected.length > 0) {
      const { data }: any = await api.postMethod('videos/share', {
        emails: JSON.stringify(emails),
        team_members: JSON.stringify(teamMembersSelected.map((item: any) => item.value)),
        send_to_all_organization_users: option === '2',
        share_link: video.share_link,
        organization_id: localStorage.getItem('organizationId'),
        sub_category_id: video.sub_category_id,
        name: user?.name,
        content
      })

      if (data.success) {
        toast.success('Video shared')
        history.push('/')
      }
    } else toast.error('Please, enter with email or click in add new email')
  }

  const renderTeamMembersSelect = () => {
    return (
      <Select
        onChange={(item: any) => setTeamMembersSelected(item)}
        components={animatedOptions}
        options={teamMembers}
        closeMenuOnSelect={false}
        className="basic-multi-select mb-3 text-dark"
        classNamePrefix="select"
        name="teamMembers[]"
        isMulti
      />
    )
  }

  const renderTeamsSelect = () => {
    return (
      <div>
        <select
          className="form-control mb-3"
          placeholder='Select a Team'
          onChange={text => setTeam(text.target.value)}
        >
          <option value=''>Select a team</option>
          { organizationTeams.map((team: any) => (
            <option key={team.id} value={team.id}>{team.name}</option>
          ))}
        </select>
        {
          team !== '' && renderTeamMembersSelect()
        }
      </div>
    )
  }

  if (!video) {
    return <Loading />
  }

  return (
    <>
      <UserHeader />
      <br /><br /><br />
      <div className="container mt-5">
        <h2 className="text-center" style={{ color: 'blue' }}>To share a video, enter emails below.</h2>
        <div className="row mt-5">
          <div className="col-md-5">
            <div className="mb-3">
              <textarea
                className="mb-3 form-control"
                placeholder="Add a personalized message"
                value={content}
                onChange={text => setContent(text.target.value)}
                rows={3}
              />
              { isSuperUser && !isGuestUser && (
                <select
                  className="form-control mb-3"
                  placeholder='Select a Option'
                  onChange={text => setOption(text.target.value)}
                >
                  {selectOptions.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                  ))}
                </select>
              )
              }
              {
                option === '1' && isSuperUser && !isGuestUser && renderTeamsSelect()
              }
              {
                option === '3' || !isSuperUser && !isGuestUser && (
                  <div className='input-group mb-3'>
                    <input type="email"
                      onChange={text => setEmail(text.target.value)}
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter e-mail"
                      value={email}
                    />
                    <div
                      onClick={() => { setEmails([...emails, email]); setEmail('') }}
                      className="input-group-append"
                      style={{ cursor: 'pointer' }}
                    >
                      <span className="input-group-text" id="basic-addon2">Add</span>
                    </div>
                  </div>
                )
              }
            </div>
            {
              emails.map((emailItem: any, index: number) => (
                <div key={index} className="mb-2 d-flex justify-content-between">
                  <p className='text-dark'>{emailItem}</p>
                  <button className="btn btn-danger btn-sm" onClick={() => { setEmails(emails.filter(email => email !== emailItem)) }}>
                    <i className="fa fa-trash" />
                  </button>
                </div>
              ))
            }
            {emails.length > 0 || teamMembersSelected.length > 0 || option === '2'
              ? (<ButtonOrange as="button" onClick={share}>Share</ButtonOrange>)
              : (<div></div>)}
          </div>
          <div className="col-md-1" />
          {
            video?.main_video &&
            (
              <div className="col-md-6">
                <h4 className="mb-3" style={{ color: 'blue' }}>Preview of the video to be shared</h4>
                <SmallVideo
                  src={video.main_video}
                  controls
                />
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

export default ShareEmails
