/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { AxiosResponse } from 'axios'
import requiredDisabledIco from '../../../assets/images/required-disabled-ico.svg'
import requiredIco from '../../../assets/images/required-gold-ico.svg'
import optionalIco from '../../../assets/images/required-blue-ico.svg'

import timeIco from '../../../assets/images/time-ico.svg'
import timeDisabledIco from '../../../assets/images/time-disabled-ico.svg'

import videoIco from '../../../assets/images/video-ico.svg'
import videoDisabledIco from '../../../assets/images/video-disabled-ico.svg'
import ApprovedIco from '../../../assets/images/approved-ico.svg'
import progressDoingIco from '../../../assets/images/progress-doing-ico.svg'
import progressDoneIco from '../../../assets/images/completed-ico.svg'
import progressNotStartedIco from '../../../assets/images/not-started-ico.svg'
import arrowRightGoldIco from '../../../assets/images/arrow-right-gold.svg'
import arrowRightBlueIco from '../../../assets/images/arrow-right-blue.svg'
import { ButtonProfilePageCertificate, ButtonProfilePageContinue, ButtonProfilePageChallenge } from '../../../components/Buttons'
import Loading from '../../../components/Loading'
import api from '../../../services/api'
import {
  ProfileCard,
  ProfileCardImage,
  ProfileCardContainer,
  ProfileCardTitle,
  ProfileCardInfo,
  ProfileContainerProgressBar,
  ProfileCardInfoProgressBar,
  ProfileCardSubInfoProgressBarFirst,
  ProfileCardSubInfoProgressBarSecond,
  ProfileCardInfoSubItems,
  ButtonDiv,
  CardContainer,
  CardContainerLocked,
  ProfileCardLocked,
  ProfileCardTitleLocked,
  ProfileCardInfoLocked,
  ProfileCardInfoSubItemsLocked,
  ProfileContainerProgressBarLocked,
  ProfileCardSubInfoProgressBarFirstLocked,
  ProfileCardSubInfoProgressBarSecondLocked,
  ProfileCardInfoProgressBarLocked,
  ButtonDivLocked,
  CertificateImgContainer,
  InfoCompletedContainer,
  CertificateAwardedTitle
} from './styled'

const ProfileCategoryCard = () => {
  const [loading, setLoading] = useState(true)
  const [teamsSettings, setTeamsSettings] = useState<any[]>([])
  const [categories, setCategories] = useState<any[]>([])
  const [categoriesInfo, setCategoriesInfo] = useState<any>([])
  const [subCategories, setSubCategoriesInfo] = useState<any>([])
  const [countVideos, setCountVideos] = useState<any>([])
  const [countTimeVideos, setCountTimeVideos] = useState<string[]>([])
  const [qtdSubCategoryWatch, setQtdSubCategoryWatch] = useState<Array<any>>([])
  const [userIsApprovedInChallenge, setUserIsApprovedInChallenge] = useState<boolean[]>([])
  const [lastVideoWatched, setLastVideoWatched] = useState<any[any]>([])
  const scrollToRef = useRef<HTMLDivElement>(null)
  const teamId = localStorage.getItem('teamId') !== 'null' ? localStorage.getItem('teamId') : 0
  const organizationId = localStorage.getItem('organizationId') !== 'null' ? localStorage.getItem('organizationId') : null

  const verifyIfUserDoesHaveApprovedInChallenge = async (categoryId: number) => {
    const { data }: any = await api.getMethod(`get_user_certificate_by_category?user_id=${localStorage.getItem('userId')}&category_id=${categoryId}`)
    if (!data.success) {
      return false
    }
    if (parseInt(data.certification.category_id) === Number(categoryId)) {
      return true
    }
    return false
  }

  const sortCategories = (categories: any[]) => categories.sort((a: any, b: any) => a.sort - b.sort)
  const findTeamSetting = (categoryId: any) => teamsSettings.find((teamSetting) => teamSetting.category_id === categoryId)

  const fetchData = async (categoryId: any) => {
    const { data }: AxiosResponse<any[any]> = await api.postMethod('get_category_id', {
      page_type: 'CATEGORY',
      category_id: categoryId
    })
    return data
  }

  const updateCategoryData = (data: any, categoryId: any) => {
    const teamSetting = findTeamSetting(categoryId)
    if (teamSetting) {
      Object.assign(data[0], {
        category_id: teamSetting.category_id,
        essential: teamSetting.essential,
        essentialText: teamSetting.essential ? 'Required' : 'Optional',
        essentialStyleText: teamSetting.essential ? '#988036' : '#222B6A',
        essentialIco: teamSetting.essential ? requiredIco : optionalIco,
        sort: teamSetting.sort
      })
    }
  }

  const getCategory = async (categoriesParams: any) => {
    const arrCategories: any = []
    const dataUserIsApprovedInChallenge: boolean[] = []
    const sortedCategoriesParams = sortCategories(categoriesParams)

    for (const categoryParam of sortedCategoriesParams) {
      const data = await fetchData(categoryParam.category_id)

      const verifyCertificate: boolean = await verifyIfUserDoesHaveApprovedInChallenge(categoryParam.category_id)
      dataUserIsApprovedInChallenge.push(verifyCertificate)

      updateCategoryData(data, categoryParam.category_id)
      arrCategories.push(data[0])
    }

    const sortedArr = sortCategories(arrCategories)
    setCategoriesInfo(sortedArr)
    setUserIsApprovedInChallenge(dataUserIsApprovedInChallenge)
  }

  const getSubCategories = async (categoriesParams: any) => {
    const arrSubCategories: any = []
    /* eslint-disable prefer-const */
    for (let categoryParam of categoriesParams) {
      const { data }: AxiosResponse<any[any]> = await api.postMethod('categoryVideos', {
        page_type: 'SUB_CATEGORY',
        category_id: categoryParam.category_id,
        organization_id: organizationId
      })
      arrSubCategories.push(data)
    }
    setSubCategoriesInfo(arrSubCategories)
  }

  const getCountVideos = () => {
    const arrCountVideos: any = []
    /* eslint-disable prefer-const */
    let auxCountVideos: number = 0
    for (let i = 0; i < subCategories.length; i++) {
      for (let subCategory of subCategories[i].data) {
        auxCountVideos += subCategory.videos_count
      }
      arrCountVideos.push(auxCountVideos)
      auxCountVideos = 0
    }
    setCountVideos(arrCountVideos)
  }

  const getProgressVideos = async (subCategoriesParams: any) => {
    /* eslint-disable prefer-const */
    let dataQtdSubCategoryWatch: Array<any> = []
    let arrAux: Array<any> = []
    for (let i = 0; i < subCategoriesParams.length; i++) {
      for (let subCategory of subCategoriesParams[i].data) {
        const { data }: any = await api.getMethod(`get_watch_user_video?SubCategoryId=${subCategory.key}`)
        arrAux.push(data)
      }
      dataQtdSubCategoryWatch[i] = arrAux
      arrAux = []
    }
    setQtdSubCategoryWatch(dataQtdSubCategoryWatch)
  }

  const getTimeVideos = () => {
    const arrCountTimeVideos: any = []
    /* eslint-disable prefer-const */
    let auxCountTimeCurrent: string = ''
    for (let i = 0; i < subCategories.length; i++) {
      for (let subCategory of subCategories[i].data) {
        for (let video of subCategory.videos) {
          if (auxCountTimeCurrent === '') {
            auxCountTimeCurrent = video.duration
          } else {
            let array1 = auxCountTimeCurrent.split(':')
            let tempoSeg1 = (parseInt(array1[0]) * 3600) + (parseInt(array1[1]) * 60) + parseInt(array1[2])
            let array2 = video.duration.split(':')
            let tempoSeg2 = (parseInt(array2[0]) * 3600) + (parseInt(array2[1]) * 60) + parseInt(array2[2])
            let tempofinal = tempoSeg1 + tempoSeg2

            let hours = Math.floor(tempofinal / (60 * 60))
            let divisorMinutos = tempofinal % (60 * 60)
            let minutes = Math.floor(divisorMinutos / 60)
            let divisorSeconds = divisorMinutos % 60
            let seconds = Math.ceil(divisorSeconds)
            let contador = ''

            if (hours < 10) { contador = '0' + hours + 'h:' } else { contador = hours + 'h:' }
            if (minutes < 10) { contador += '0' + minutes + 'min:' } else { contador += minutes + 'min:' }
            if (seconds < 10) { contador += '0' + seconds + 's' } else { contador += seconds + 's' }
            auxCountTimeCurrent = contador
          }
        }
      }
      arrCountTimeVideos.push(auxCountTimeCurrent.slice(0, 9))
      auxCountTimeCurrent = ''
    }
    setCountTimeVideos(arrCountTimeVideos)
  }

  const goToLink = async (categoryId: number, categoryChallenge: string) => {
    if (categoryChallenge === '') {
      return
    }

    const res = await api.postMethod('insert_new_challenge', {
      user_id: localStorage.getItem('userId'),
      challenge_category_id: categoryId,
      challenge_type: 'category',
      challenge_description: localStorage.getItem('userId') + ' - ' + localStorage.getItem('name') + ': ' + localStorage.getItem('active_profile_name'),
      challenge_status: 'null'
    })

    if (res.status === 201) {
      if (categoryChallenge.indexOf('https://') < 0 && categoryChallenge.indexOf('http://') < 0) {
        window.open('https://' + categoryChallenge, '_blank')
      } else {
        window.open(categoryChallenge, '_blank')
      }
    }
  }

  const verifyLastCategoryEssential = (index: number): number => {
    if (index === 0) {
      return 0
    }

    for (let i = 1; i <= teamsSettings.length; i++) {
      if (teamsSettings[index - i]?.essential === 1) {
        return index - i
      }
    }

    return -1
  }

  const verifyPermissionContinueLearning = (index: number) => {
    /* eslint-disable operator-linebreak */
    if (index !== 0) {
      let counterQtdVideosWatch = 0
      for (let i = 0; i < qtdSubCategoryWatch[index - 1]?.length; i++) {
        if (qtdSubCategoryWatch[index - 1][i] !== 0) {
          counterQtdVideosWatch += qtdSubCategoryWatch[index - 1][i]
        }
      }
      let counterQtdVideosWatch2 = 0
      for (let i = 0; i < qtdSubCategoryWatch[verifyLastCategoryEssential(index)]?.length; i++) {
        if (qtdSubCategoryWatch[verifyLastCategoryEssential(index)][i] !== 0) {
          counterQtdVideosWatch2 += qtdSubCategoryWatch[verifyLastCategoryEssential(index)][i]
        }
      }
      const percentage = userIsApprovedInChallenge[index - 1] ? 100 : Math.floor((Number(counterQtdVideosWatch) / Number(countVideos[index - 1])) * 100)
      const percentageLastEssentialCategory = userIsApprovedInChallenge[verifyLastCategoryEssential(index)] ? 100 : Math.floor((Number(counterQtdVideosWatch2) / Number(countVideos[verifyLastCategoryEssential(index)])) * 100)
      if (percentage === 100) {
        return true
      } else if (percentage !== 0 && teamsSettings[index - 1]?.essential === 1 && teamsSettings[index]?.essential === 0) {
        return true
      } else if (percentage !== 0 && teamsSettings[index - 1]?.essential === 0 && teamsSettings[index]?.essential === 0) {
        return true
      } else if (percentage !== 0 && percentageLastEssentialCategory === 100 && teamsSettings[index - 1]?.essential === 0 && teamsSettings[index]?.essential === 1) {
        return true
      } else if (percentage !== 0 && teamsSettings[index - 1]?.essential === 0 && teamsSettings[index]?.essential === 0) {
        return true
      } else if (percentage === 0 && percentageLastEssentialCategory === 100 && teamsSettings[index - 1]?.essential === 0 && teamsSettings[index]?.essential === 1) {
        return true
      } else if (percentage === 0 && teamsSettings[index - 1]?.essential === 0 && teamsSettings[index]?.essential === 0) {
        return true
      }
    }

    if (userIsApprovedInChallenge[index - 1]
        || index === 0
        || (!teamsSettings[index - 1]?.essential && teamsSettings[index]?.essential && userIsApprovedInChallenge[verifyLastCategoryEssential(index)])
        || (!teamsSettings[index - 1]?.essential && !teamsSettings[index]?.essential && userIsApprovedInChallenge[verifyLastCategoryEssential(index)])
        || (teamsSettings[index - 1]?.essential && teamsSettings[index]?.essential && userIsApprovedInChallenge[verifyLastCategoryEssential(index)])
        || verifyLastCategoryEssential(index) === -1) {
      return true
    }

    return false
  }

  const getLinkContinueLearningButton = async ($categoryId: number) => {
    const { data }: any = await api.getMethod(`last-video-watched-by-category/${$categoryId}`)

    if (data?.success) {
      return data?.data?.id
    }

    return false
  }

  const getCategoriesInfo = async () => {
    const organizationCategories: AxiosResponse<any[any]> = await api.getMethod(`get_all_organization_categories/${parseInt(localStorage.getItem('organizationId') as string)}`, {
      team_id: teamId
    })

    setCategories(organizationCategories?.data?.data)

    await getCategory(categories)
    await getSubCategories(categories)

    const { data, status }: any = await api.getMethod(`organization/teams-settings/${Number(teamId)}`)

    if (status === 200) {
      setTeamsSettings(data)
    } else {
      setTeamsSettings(organizationCategories?.data?.data)
    }

    await getProgressVideos(subCategories)

    const arrLastVideosWatched: any = []
    for (let category of categories) {
      let result = await getLinkContinueLearningButton(category.category_id)
      arrLastVideosWatched.push(result)
    }

    setLastVideoWatched(arrLastVideosWatched)

    getCountVideos()
    getTimeVideos()
  }

  useEffect(() => {
    getCategoriesInfo()
    setLoading(false)
  }, [categories?.length, subCategories?.length])

  useLayoutEffect(() => {
    scrollToRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'end'
    })
  }, [categories?.length, subCategories?.length])

  if (loading) {
    <Loading />
  }

  return (
    <React.Fragment>
      {
        categoriesInfo.map((category: any, index: number) => {
          let counterQtdSubCategoryWatch = 0
          let counterQtdVideosWatch = 0
          for (let i = 0; i < qtdSubCategoryWatch[index]?.length; i++) {
            if (qtdSubCategoryWatch[index][i] !== 0) {
              counterQtdSubCategoryWatch++
              counterQtdVideosWatch += qtdSubCategoryWatch[index][i]
            }
          }
          const percentage = userIsApprovedInChallenge[index] ? 100 : Math.floor((Number(counterQtdVideosWatch) / Number(countVideos[index])) * 100)

          return (
            <React.Fragment key={index}>
              {
                userIsApprovedInChallenge[index]
                  ? <Link
                      to={`/category/${category.category_id}`}
                      onClick={ verifyPermissionContinueLearning(index) ? () => {} : (event) => event.preventDefault() }
                    >
                      <CardContainerLocked>
                        <div id='learning-path-track'>
                          <LazyLoadImage src={progressDoneIco} className='ico-elevation'/>
                        </div>
                        <ProfileCardLocked>
                        <ProfileCardContainer>
                          <ProfileCardTitleLocked>{ category.name }</ProfileCardTitleLocked>
                          <ProfileCardInfoLocked>
                            <ProfileCardInfoSubItemsLocked>
                              <LazyLoadImage src={requiredDisabledIco} width='14px' /><text>{ category.essentialText }</text>
                            </ProfileCardInfoSubItemsLocked>
                            <ProfileCardInfoSubItemsLocked>
                              <LazyLoadImage src={videoDisabledIco} width='16px' />
                              { countVideos[index] } videos
                            </ProfileCardInfoSubItemsLocked>
                            <ProfileCardInfoSubItemsLocked>
                              <LazyLoadImage src={timeDisabledIco} width='14px' />
                              { countTimeVideos[index] }
                            </ProfileCardInfoSubItemsLocked>
                          </ProfileCardInfoLocked>
                          <ProfileContainerProgressBarLocked>
                            <div style={{ height: '100%', width: '100%', borderRadius: '10px', backgroundColor: '#D3D3D3' }}></div>
                          </ProfileContainerProgressBarLocked>
                          <ProfileCardInfoProgressBarLocked>
                            <ProfileCardSubInfoProgressBarFirstLocked style={{ color: '#D3D3D3' }}>
                              <span style={{ fontWeight: '700' }}>{ Number(percentage) }%</span> <span>Completed</span>
                            </ProfileCardSubInfoProgressBarFirstLocked>
                            <ProfileCardSubInfoProgressBarSecondLocked style={{ color: '#D3D3D3' }}>
                              <span style={{ fontWeight: '700' }}>{ counterQtdSubCategoryWatch } of { subCategories[index]?.data?.length }</span> <span>Topics Completed</span>
                            </ProfileCardSubInfoProgressBarSecondLocked>
                          </ProfileCardInfoProgressBarLocked>
                            <ButtonDivLocked>
                              <InfoCompletedContainer >
                                <CertificateAwardedTitle>
                                  <Link
                                    to={`/category/${category.category_id ?? category.id}`}
                                    onClick={ verifyPermissionContinueLearning(index) ? () => {} : (event) => event.preventDefault() }
                                  >
                                    Certificate Awarded!
                                  </Link>
                                </CertificateAwardedTitle>
                                <Link to={'/achievements'}>
                                  <ButtonProfilePageCertificate style={{ boxShadow: '0px 1px 2px rgba(142, 141, 208, 0.3), 0px 2px 6px 2px rgba(142, 141, 208, 0.15)' }} ref={(index === 0 && !userIsApprovedInChallenge[index + 1]) || (index !== 0 && userIsApprovedInChallenge[index - 1] && !userIsApprovedInChallenge[index + 1]) ? scrollToRef : null}>
                                    <span style={{ color: '#525399', height: '21px' }}>See Certificate</span> <LazyLoadImage src={arrowRightBlueIco} width='10px' />
                                  </ButtonProfilePageCertificate>
                                </Link>
                              </InfoCompletedContainer>
                              <CertificateImgContainer>
                                <LazyLoadImage src={ ApprovedIco } width='100%' height='100%'/>
                              </CertificateImgContainer>
                            </ButtonDivLocked>
                          </ProfileCardContainer>
                        </ProfileCardLocked >
                      </CardContainerLocked>
                    </Link>
                  : <CardContainer>
                      <div id='learning-path-track'>
                        {
                          percentage === 100
                            ? <LazyLoadImage src={progressDoneIco} className='ico-elevation'/>
                            : (percentage === 0
                                ? <LazyLoadImage src={progressNotStartedIco} className='ico-elevation'/>
                                : <LazyLoadImage src={progressDoingIco} className='ico-elevation'/>
                              )
                        }
                      </div>
                      <ProfileCard style={{ border: percentage === 0 ? '1px solid #FFFFFF' : '1px solid #525399', filter: verifyPermissionContinueLearning(index) ? 'opacity(100%)' : 'opacity(15%)' }} title={ verifyPermissionContinueLearning(index) ? 'Category available to start or continue.' : 'Category not available yet. You must complete the last released "Required Category" to unlock the learning path.' }>
                        <Link
                          to={() => lastVideoWatched[index] ? `/video/${lastVideoWatched[index]}` : `/category/${category.category_id}`}
                          onClick={ verifyPermissionContinueLearning(index) ? () => {} : (event) => event.preventDefault() }
                        >
                          <ProfileCardImage src={ category.picture } />
                        </Link>
                        <ProfileCardContainer>
                          <ProfileCardTitle>
                            <Link
                              to={`/category/${category.category_id}`}
                              onClick={ verifyPermissionContinueLearning(index) ? () => {} : (event) => event.preventDefault() }
                            >
                              { category.name }
                            </Link>
                          </ProfileCardTitle>
                          <ProfileCardInfo>
                            <ProfileCardInfoSubItems>
                              <LazyLoadImage src={category.essentialIco} width='15px' />
                              <text style={{ color: category.essentialStyleText }}>{ category.essentialText }</text>
                            </ProfileCardInfoSubItems>
                            <ProfileCardInfoSubItems>
                              <LazyLoadImage src={videoIco} width='16px' />
                              { countVideos[index] } videos
                            </ProfileCardInfoSubItems>
                            <ProfileCardInfoSubItems>
                              <LazyLoadImage src={timeIco} width='14px' />
                              { countTimeVideos[index] }
                            </ProfileCardInfoSubItems>
                          </ProfileCardInfo>
                          <ProfileContainerProgressBar>
                            <div style={{ height: '100%', width: `${percentage}%`, borderRadius: '10px', backgroundColor: percentage === 100 ? '#00013F' : '#222B6A' }}></div>
                          </ProfileContainerProgressBar>
                          <ProfileCardInfoProgressBar>
                            <ProfileCardSubInfoProgressBarFirst style={{ color: percentage === 100 ? '#00013F' : '#222B6A' }}>
                              <span style={{ fontWeight: '700' }}>{ percentage }%</span> <span>Completed</span>
                            </ProfileCardSubInfoProgressBarFirst>
                            <ProfileCardSubInfoProgressBarSecond style={{ color: percentage === 100 ? '#00013F' : '#222B6A' }}>
                              <span style={{ fontWeight: '700' }}>{ counterQtdSubCategoryWatch } of { subCategories[index]?.data?.length }</span> <span>Topics Completed</span>
                            </ProfileCardSubInfoProgressBarSecond>
                          </ProfileCardInfoProgressBar>
                          {
                            percentage === 100
                              ? <ButtonDiv>
                                  <ButtonProfilePageChallenge onClick={ verifyPermissionContinueLearning(index) ? () => goToLink(category.category_id, category.challenge) : () => {} } style={{ filter: category.challenge === '' ? 'opacity(15%)' : 'opacity(100%)', cursor: category.challenge === '' ? 'not-allowed' : 'pointer' }}>
                                    <span style={{ color: '#FFFFFF', height: '21px' }}>Go to challenge</span> <LazyLoadImage src={arrowRightGoldIco} width='10px' />
                                  </ButtonProfilePageChallenge>
                                </ButtonDiv>
                              : (percentage === 0
                                  ? <ButtonDiv>
                                      <Link
                                        to={() => lastVideoWatched[index] ? `/video/${lastVideoWatched[index]}` : `/category/${category.category_id}`}
                                        onClick={ verifyPermissionContinueLearning(index) ? () => {} : (event) => event.preventDefault() }>
                                        <ButtonProfilePageCertificate style={{ border: '1px solid #525399' }}>
                                          <span style={{ color: '#525399', height: '20px' }}>Start course</span> <LazyLoadImage src={arrowRightGoldIco} width='10px' />
                                        </ButtonProfilePageCertificate>
                                      </Link>
                                    </ButtonDiv>
                                  : <ButtonDiv>
                                        <Link
                                          to={() => lastVideoWatched[index] ? `/video/${lastVideoWatched[index]}` : `/category/${category.category_id}`}
                                          onClick={ verifyPermissionContinueLearning(index) ? () => {} : (event) => event.preventDefault() }>
                                          <ButtonProfilePageContinue>
                                            <span style={{ color: '#FFFFFF', height: '21px' }}>Continue learning</span> <LazyLoadImage src={arrowRightGoldIco} width='10px' />
                                          </ButtonProfilePageContinue>
                                        </Link>
                                    </ButtonDiv>
                                )
                            }
                        </ProfileCardContainer>
                      </ProfileCard>
                    </CardContainer>
                }
              </React.Fragment>
          )
        })
      }
    </React.Fragment>
  )
}

export default ProfileCategoryCard
