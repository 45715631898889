import styled from 'styled-components'

export const Container = styled.div`
    background-color: var(--gray-bg);
    min-height: 100%;
    width: 100%;
`

export const ProfileTitle = styled.span`
    position: relative;
    width: 220px;
    height: 40px;
    left: 80px;
    top: -60px;
    font-family: var(--font-family-body);
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
`

export const ProfileContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100%;
    justify-content: center;
    align-items: flex-start;
    font-family: var(--font-family-body);
    font-size: var(--fs-body-subtext-large);
    position: relative;

    @media (max-width: 1200px) {
        justify-content: center;

    }

    @media (max-width: 769px) {
        justify-content: center;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100vw;
        min-width: 100vw;
        max-width: 100vw;
        margin-inline: 0;

    }

`

export const ProfileMainOption = styled.div`
    width: 100%;
    min-width: 658px;
    max-width: 658px;
    display: flex;
    padding-inline: 24px;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: var(--clr-neutral-900);
    margin-inline: 32px;
    height: calc(100vh - 266px);
    padding-bottom: 36px;
    border-right: 1px solid var(--gray-secondary);
    border-left: 1px solid var(--gray-secondary);
    box-shadow: var(--elevation-6);
    clip-path: inset(-123px -20px 0px -20px);
    float: center;

    @media (max-width: 1200px) and (max-width: 1400px) {
        height: calc(100vh - 247px);
        padding-bottom: 0px;

    }

    @media (min-width: 769px) and (max-width: 1200px) {
        border-left: 0.5px solid var(--gray-secondary);
        border-right: 1px solid var(--gray-secondary);
        box-shadow: var(--elevation-6);
        clip-path: inset(-84px -20px 0px -10px);
        padding-inline: 12px;
        margin-inline: 0px;
        margin-right: 16px;
        min-width: 640px;
        max-width: 32vw;
        height: calc(100vh - 266px);
        padding-bottom: 41px;
    }

    @media (min-width: 479.67px) and (max-width: 769px) {
        height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 0;
        padding-inline: 10vw;
        border: none;
        box-shadow: none;
        padding-bottom: 0px;

    }

    @media (min-width: 424.67px) and (max-width: 480px) {
        height: 100%;
        width: 100%;
        padding-bottom: 0px;

        min-width: 100%;
        max-width: 100%;

    }

    @media (max-width: 425px) {
        height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }

    @media (max-width: 689px) {
        width: 100%;
        min-width: 100%;
        margin-inline: 0px;
        padding-inline: 0px;
    }
`

export const ProfileVideos = styled.div`
    padding-top: 24px;
    width: 100%;
    height: calc(100vh - 300px);
    padding-bottom: 38px;

    @media (min-width: 1200px) and (max-width: 1400px) {
        height: calc(98vh - 370px);
    }

    @media (min-width: 769px) and (max-width: 1200px) {
        height: calc(98vh - 370px);
    }

    @media (min-width: 479.67px) and (max-width: 769px) {
        height: 100%;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    @media (min-width: 424.67px) and (max-width: 480px) {
        height: 100%;
        padding-bottom: 0px;
        margin-bottom: 0px;

    }

    @media (max-width: 425px) {
        height: 100%;
        padding-bottom: 0px;
        margin-bottom: 0px;

        
    }

    @media (max-width: 689px) {
        width: 100%;
        height: 100%;
    }
`

export const ProfileVideosCardContainer = styled.div`
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 14px;
    margin-bottom: 32px;
    background-color: var(--clr-neutral-900);

    @media (min-width: 769px) and (max-width: 998px) {
        height: 61vh;
    }
    @media (max-width: 769px) {
        height: 54vh;
    }

    @media (max-width: 689px) {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        padding-right: 12px;

    }
`

export const ProfileStats = styled.div`
    float: left;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    justify-content: space-between;
    max-width: 24vw;
    align-items: flex-end;
    min-height: 100%;

    @media (max-width: 1399px) {
        display: none;
        width: 0px;
        height: 0px;
    }
`

export const ProfileStatRecentActivity = styled.div`
    padding-top: 10px;
    color: #000000;
    max-height: 410px;
    min-height: 100%;
    max-width: 310px;
    font-family: var(--font-family-body);

`

export const ProfileRankings = styled.div`
    float: right;
    min-width: 20vw;
    height: calc(86vh - 300px);
    padding-bottom: 18px;

    font-family: var(--font-family-body);
    color: var(--clr-neutral-200);
    width: 100%;
    display: flex;
    align-self: flex-start;
    justify-self: flex-start;
    max-width: 24vw;
    background-color: transparent;

    @media (max-width: 1399.98px) {
        width: 100%;
        color: #000000;
        clear: both;
        max-width: 310px;
        height: 100%;
    }

    @media (max-width: 992px) {
        display: none;
    }
`

export const ProfileContainersTitle = styled.h1`
    /* My learning path */

    /* Headline/Subtitle */
    font-family: var(--font-family-headline);
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 120%;
    color: var(--clr-neutral-200);

    @media (max-width: 689px) {
        width: 100%;
        padding: 0 4vw;
    }

`

export const ProfileContainersSubTitle = styled.h3`
    width: 100%;
    height: 15px;
    margin-top: 8px;
    font-family: var(--font-family-body);
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-subtext-large);
    line-height: var(--lh-tight);
    display: flex;
    align-items: center;
    color: var(--clr-neutral-300);

    @media (max-width: 689px) {
        width: 100%;
        padding: 0 4vw;
        justify-content: flex-start;
    }
`
