import React from 'react'
import useAuth from '../hooks/useAuth'
import AuthRoutes from './auth.routes'

import CommonRoutes from './common.routes'

const Routes = () => {
  const { signed, loading } = useAuth()

  if (loading) {
    <p>Loading...</p>
  }

  return signed ? <AuthRoutes /> : <CommonRoutes />
}

export default Routes
