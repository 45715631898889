import styled from 'styled-components'

export const ContainerDiv = styled.div`
`

export const InputDefault = styled.input`
`

export const InputLabel = styled.label`
`

export const TitleForm = styled.h3`
`

export const ContainerOrganizationForm = styled.div`
    text-align: center;
    color: var(--clr-primary-dark);
`
