import styled from 'styled-components'

export const ContainerDiv = styled.div`
`

export const InputDefault = styled.input`
`

export const InputLabel = styled.label`
`

export const TitleForm = styled.h3`
`

export const FooterDefault = styled.footer`
`

export const ParagraphDefault = styled.p`
    font-weight: bold;
    font-size: 16px;

    :hover {
        filter: drop-shadow(0 0 0.75rem #4444dd);
    }
`

export const ContainerOrganizationTeamsCard = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-gap: 20px;
    text-align: center;
    color: var(--clr-primary-dark);
    margin-left: 10%;
    margin-right: 10%;
`

export const TeamsCard = styled.div`
    display: grid;
    grid-template-rows: max-content 160px 1fr;
    border: 1px solid #FCEECC;
    border-radius: 4px;
    padding: 8px;
    align-items: center;
    isolation: isolate;
`

export const CenteredDiv = styled.div`
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
    margin: 0 auto;
    color: black;
`
