import React, { ReactNode } from 'react'
import Select from 'react-select'
import { ActionMeta } from 'react-select/dist/declarations/src/types'
import csvIco from '../../../assets/images/csv-icon.svg'
import {
  Container,
  LabelDiv,
  Heading
} from './styled'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const customStyles = {
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#CBAF63' : '#fff',
    color: '#2D2935',
    fontWeight: state.isSelected ? 600 : 500,
    '&:hover': { backgroundColor: '#D8DCF3' }
  }),
  control: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    borderRadius: '6px',
    borderColor: state.isFocused ? '#CBAF63' : '#9D9AAD',
    backgroundColor: '#F4F3FE',
    fontWeight: 600,
    maxWidth: '130px',
    '&:hover': { borderColor: '#222B6A' }
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: 'transparent'
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#2D2935'
  })
}

interface SelectOption {
  value: string,
  label: string
}

interface DashboardCardProps {
  label: string,
  children: ReactNode,
  buttonExists: boolean,
  button?: () => void,
  actionButton?: () => void,
  actionButtonExists?: boolean,
  actionButtonLabel?: string,
  select?: boolean,
  options?: any,
  onChange?: (option: SelectOption | null, actionMeta: ActionMeta<SelectOption>) =>void,
  value?: any,
  defaultValue?: any
}

export function DashboardCard ({ label, children, select, button, buttonExists, actionButton, actionButtonExists, actionButtonLabel, options, onChange, value, defaultValue, ...rest }: DashboardCardProps) {
  return (
    <Container {...rest}>
      <LabelDiv>
        <div id='left-items'>
          <Heading>
            {label}
          </Heading>
          {
            select && (
              <Select options={options} onChange={onChange} value={value} defaultValue={defaultValue} styles={customStyles}/>
            )
          }
          {
            actionButtonExists && (
              <button className='action-btn' onClick={actionButton}>
                {actionButtonLabel}
              </button>
            )
          }
        </div>
        {
          buttonExists && (
            <button className='button' onClick={button}>
              <LazyLoadImage src={csvIco} height='30px' />
            </button>
          )
        }
      </LabelDiv>
      {children}
    </Container>
  )
}
