
/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Loading from '../../../components/Loading'
import LockIco from '../../../assets/images/lock-ico.png'
import ApprovedIco from '../../../assets/images/approved-ico.svg'
import api from '../../../services/api'
import {
  ProfileCertificatesContainer,
  ProfileCertificateTitle,
  ProfileContainerCertificatesCards,
  ProfileCertificateCard,
  ProfileCertificateCardImage,
  ProfileCertificateCardTextCertificate,
  ProfileCertificateCardTextDescription,
  ProfileCertificateCardLocked,
  ProfileCertificateCardImageLocked,
  ProfileCertificateCardIcoLocked,
  ProfileCertificateCardTextLocked,
  ProfileCertificateCardIcoApproved,
  ProfileContainerContent,
  ProfileCertificateCardText,
  TextLocked,
  ProfileCertificateButton,
  CertificateButtonContainer,
  Container
} from './styled'
import { Link } from 'react-router-dom'

const ProfileCertificatesCards = (data: any) => {
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState<any[]>([])
  const [categoriesInfo, setCategoriesInfo] = useState<any>([])
  const [userIsApprovedInChallenge, setUserIsApprovedInChallenge] = useState<boolean[]>([])

  const verifyIfUserDoesHaveApprovedInChallenge = async (categoryId: number) => {
    const { data }: any = await api.getMethod(`get_user_certificate_by_category?user_id=${localStorage.getItem('userId')}&category_id=${categoryId}`)
    if (!data.success) {
      return false
    }
    if (parseInt(data.certification.category_id) === Number(categoryId)) {
      return true
    }
    return false
  }

  const getCategory = async (categoriesParams: any) => {
    const arrCategories: any = []
    const dataUserIsApprovedInChallenge: boolean[] = []
    const sortedCategoriesParams = categoriesParams.sort((a: any, b: any) => a.sort < b.sort ? -1 : (a.sort > b.sort ? 1 : 0))
    for (let categoryParam of sortedCategoriesParams) {
      const { data }: AxiosResponse<any[any]> = await api.postMethod('get_category_id', {
        page_type: 'CATEGORY',
        category_id: categoryParam.category_id
      })

      const verifyCertificate: boolean = await verifyIfUserDoesHaveApprovedInChallenge(categoryParam.category_id)
      if (verifyCertificate) {
        dataUserIsApprovedInChallenge.push(true)
      } else {
        dataUserIsApprovedInChallenge.push(false)
      }

      arrCategories.push(data[0])
    }
    const sortedArr = arrCategories.sort((a: any, b: any) => a.sort < b.sort ? -1 : (a.sort > b.sort ? 1 : 0))
    setCategoriesInfo(sortedArr)
    setUserIsApprovedInChallenge(dataUserIsApprovedInChallenge)
  }

  const getCategoriesInfo = async () => {
    const organizationCategories: AxiosResponse<any[any]> = await api.getMethod(`get_all_organization_categories/${parseInt(localStorage.getItem('organizationId') as string)}`, {
      team_id: localStorage.getItem('teamId')
    })
    setCategories(organizationCategories?.data?.data)

    await getCategory(categories)

    setLoading(false)
  }

  useEffect(() => {
    getCategoriesInfo()
  }, [categories.length])

  if (loading) {
    <Loading />
  }

  return (
      <Container>
        <ProfileCertificateTitle>Certificates</ProfileCertificateTitle>
        <ProfileContainerContent>
          <ProfileCertificatesContainer>
            <ProfileContainerCertificatesCards>
              {
                categoriesInfo.map((category: any, index: number) => {
                  return (
                    userIsApprovedInChallenge[index]
                      ? <ProfileCertificateCard key={index}>
                        <Link to={'/achievements'}>
                          <ProfileCertificateCardImage>
                            <LazyLoadImage src={ category.picture } width='96px' height='84px' />
                            <ProfileCertificateCardIcoApproved>
                              <LazyLoadImage src={ ApprovedIco } width='19.5px' height='24px'/>
                            </ProfileCertificateCardIcoApproved>
                          </ProfileCertificateCardImage>
                          <ProfileCertificateCardText>
                            <ProfileCertificateCardTextCertificate>
                              Certificate
                            </ProfileCertificateCardTextCertificate>
                            <ProfileCertificateCardTextDescription>
                              {category.name}
                            </ProfileCertificateCardTextDescription>
                          </ProfileCertificateCardText>
                        </Link>
                        </ProfileCertificateCard>
                      : <ProfileCertificateCardLocked key={index}>
                          <ProfileCertificateCardImage>
                            <ProfileCertificateCardImageLocked>
                              <LazyLoadImage src={ category.picture } width='96px' height='84px' />
                            </ProfileCertificateCardImageLocked>
                            <ProfileCertificateCardIcoLocked>
                              <LazyLoadImage src={ LockIco } /> <ProfileCertificateCardTextLocked>Locked</ProfileCertificateCardTextLocked>
                            </ProfileCertificateCardIcoLocked>
                          </ProfileCertificateCardImage>
                          <ProfileCertificateCardText>
                            <ProfileCertificateCardTextCertificate>
                              Certificate
                            </ProfileCertificateCardTextCertificate>
                            <ProfileCertificateCardTextDescription>
                              <TextLocked>{category.name}</TextLocked>
                            </ProfileCertificateCardTextDescription>
                          </ProfileCertificateCardText>
                        </ProfileCertificateCardLocked>
                  )
                })
              }
            </ProfileContainerCertificatesCards>
          </ProfileCertificatesContainer>
        </ProfileContainerContent>
        <CertificateButtonContainer>
          <Link to='/achievements'><ProfileCertificateButton>View certificates</ProfileCertificateButton></Link>
        </CertificateButtonContainer>
      </Container>
  )
}

export default ProfileCertificatesCards
