import React from 'react'
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

interface Props {
  data: Array<object>,
  height: number
}

export function VerticalBarChart ({ data, height }: Props) {
  const labelListFormatter = (value:any) => `${value}%`

  return (
    <ResponsiveContainer width='100%' height={height}>
      <BarChart
        layout='vertical'
        width={400}
        height={600}
        data={data}
        barCategoryGap='15%'
        barSize={30}
        margin={{
          top: 5,
          right: 0,
          left: 70,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis type="number" axisLine={false} orientation='top' ticks={[0, 25, 50, 75, 100]} />
        <YAxis type="category" dataKey="name" axisLine={false} style={{ color: '#2D2935', fontWeight: 600, minWidth: '100px' }}/>
        <Tooltip labelStyle={{ color: '#2D2935', fontWeight: 600, fontSize: 16 }} cursor={{ fill: '#F4F3FE' }}/>
        <Bar
          dataKey="completion"
          fill=" #529966"
          background={{ fill: '#e3e3ee' }}
          unit='%'
          name='Completion rate'
          radius={[0, 10, 10, 0]}
        >
          <LabelList dataKey="completion" position="bottom" style={{ fontWeight: 600, color: '#2D2935' }} formatter={labelListFormatter} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
