/* eslint-disable camelcase */
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { toast } from 'react-toastify'

import Header from '../../../components/Auth/Header'
import ToggleButton from '../../../components/ToggleButton'
import { ButtonOrange } from '../../../components/Buttons'
import Loading from '../../../components/Loading'
import api from '../../../services/api'

import {
  ContainerManageTeam, NewUserButton
} from './styled'

const Home = () => {
  const super_user_id = localStorage.getItem('userId')
  const organization_id = localStorage.getItem('organizationId')

  const [users, setUsers] = useState([])

  const [user_id, setUserId] = useState('')
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [team, setTeam] = useState('')
  const [role, setRole] = useState('')

  async function getUsers () {
    const { data }: any = await api.postMethod('v4/list_users', { super_user_id })
    if (data.length <= 0) {
      return toast.error('No user registered yet')
    }
    const removeUserParent = data.filter((user: any) => {
      return user.name !== localStorage.getItem('name') && user.id !== Number(localStorage.getItem('userId'))
    })
    setUsers(removeUserParent)
  }

  function closeModal () {
    setUserId('')
    setName('')
    setLastName('')
    setEmail('')
    setTeam('')
    setRole('')
  }

  function getUserDetails (user: any) {
    setUserId(user.id)
    setName(user.name)
    setLastName(user.last_name)
    setEmail(user.email)
    setTeam(user.team)
    setRole(user.role)
  }

  async function editUser () {
    const formData = {
      user_id,
      name,
      last_name: lastName,
      email,
      team,
      role
    }

    await api.postMethod('v4/update_user', formData)

    getUsers()
  }

  async function createUser () {
    const formData = {
      name,
      last_name: lastName,
      email,
      organization_id,
      team,
      role,
      super_user_id,
      is_super_user: 0
    }

    const { data }: any = await api.postMethod('v4/create_user', formData)

    if (data.success) {
      toast.success(data.message)
      getUsers()
    } else toast.error(data.error_messages)
  }

  async function deleteUser (id: any) {
    await api.postMethod('v4/delete_user', { user_id: id })

    const filteredUsers = users.filter((user: any) => user.id !== id)

    setUsers(filteredUsers)
  }

  useLayoutEffect(() => {
    // redirect to home if user is not super user
    if (localStorage.getItem('isSuperUser') !== '1') {
      window.location.href = '/home'
    }
  }, [])

  useEffect(() => {
    getUsers()
  }, [])

  if (!users) {
    return <Loading />
  }

  return (
    <>
      <Header />
      <ContainerManageTeam>
        <div className="page-header">
          <h1>Manage Team</h1>
          <NewUserButton className="btn" data-toggle="modal" data-target="#modalUser">New User</NewUserButton>
        </div>
        <div className="modal fade" id="modalUser" tabIndex={-1} role="dialog" aria-labelledby="modalUserLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark">{user_id ? 'Edit User' : 'New User'}</h5>
                <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="form-group mt-2">
                    <input
                      onChange={text => setName(text.target.value)}
                      required
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={name}
                      minLength={2}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <input
                      onChange={text => setLastName(text.target.value)}
                      className="form-control"
                      id="lastName"
                      required
                      name="lastName"
                      placeholder="Last Name"
                      value={lastName}
                      minLength={2}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <input
                      onChange={text => setEmail(text.target.value)}
                      className="form-control"
                      id="email"
                      name="email"
                      type="email"
                      required
                      placeholder="E-mail"
                      value={email}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <input
                      onChange={text => setTeam(text.target.value)}
                      className="form-control"
                      id="team"
                      name="team"
                      required
                      placeholder="team"
                      value={team}
                      minLength={2}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <input
                      onChange={text => setRole(text.target.value)}
                      className="form-control"
                      id="role"
                      name="role"
                      required
                      placeholder="role"
                      value={role}
                      minLength={2}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <ButtonOrange
                  as="button"
                  type="button"
                  className="btn"
                  disabled={!name && !lastName && !email && !team}
                  data-dismiss="modal"
                  onClick={
                    user_id
                      ? editUser
                      : createUser
                  }
                >
                  {
                    user_id
                      ? 'Save Changes'
                      : 'Create User'
                  }
                </ButtonOrange>
              </div>
            </div>
          </div>
        </div>
        {
          users.length > 0
            ? (
              <table className="table mt-5 text-blue-logo">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">team</th>
                    <th scope="col">role</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    users.map((user: any, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{user.name}</td>
                        <td>{user.last_name}</td>
                        <td className="no-capitalize">{user.email}</td>
                        <td className="no-capitalize">{user.team}</td>
                        <td className="no-capitalize">{user.role}</td>
                        <td>
                          <button className="btn btn-primary btn-sm mr-3" data-toggle="modal" data-target="#modalUser" onClick={() => getUserDetails(user)}>
                            <i className="fa fa-edit" />
                          </button>
                          <button id="lambda-test-btn-delete-user" className="btn btn-danger btn-sm" onClick={() => deleteUser(user.id)}>
                            <i className="fa fa-trash" />
                          </button>
                        </td>
                      </tr>
                    ))
                  }

                </tbody>
              </table>
              )
            : <h4 className="mt-3 text-center">Register new user</h4>
        }
      </ContainerManageTeam>
      <ToggleButton />
    </>
  )
}

export default Home
