import React, { useEffect, useState } from 'react'

import Footer from '../../../components/Footer'
import Loading from '../../../components/Loading'
import StaticHeader from '../../../components/StaticHeader'
import StaticSidebar from '../../../components/StaticSidebar'

import api from '../../../services/api'
import {
  Container,
  ContainerPage
} from './styled'

const PageCustom = (props: any) => {
  const [html, setHtml] = useState<any>()

  async function getPageForId (id: string) {
    const { data }: any = await api.getMethod(`pages/list?unique_id=${id}`)
    setHtml(data.data)
  }

  useEffect(() => {
    getPageForId(props.match.params.id)
  }, [props.match.params.id])

  if (!html) {
    return <Loading />
  }

  return (
    <Container>
      <StaticHeader />
      <div className="main-sec-content pl-5 pr-5">
        <div className="row">
          <div className="col-sm-12 col-md-3 col-lg-2">
            <StaticSidebar />
          </div>
          <div className="col-sm-12 col-md-9 col-lg-10">
            <ContainerPage>
              <h1>{html.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: html.description }} />
            </ContainerPage>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  )
}

export default PageCustom
