import React from 'react'
import Header from '../../../../components/Auth/Header'
import EditTeamForm from '../../../../components/Auth/EditTeamForm'

import {
  ContainerMainContent
} from './styled'

type Props = {
  match: {
    params: {
      name: string,
      id: number
    }
  }
}

const TeamForm = (props: Props) => {
  console.log(props)
  return (
    <React.Fragment>
      <Header />
      <ContainerMainContent>
        <EditTeamForm teamName={props.match.params.name} teamId={props.match.params.id} />
      </ContainerMainContent>
    </React.Fragment>
  )
}

export default TeamForm
