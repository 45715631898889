/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Loading from '../../../components/Loading'
import VideoPlayer from '../../../components/VideoPlayer'
import arrow from '../../../assets/images/left-arrow.png'

import { BackPage, ContainerVideo } from './styled'

const Trailer = () => {
  const [trailer, setTrailer] = useState<any | null>(null)
  const location: any = useLocation()
  const btnBack = () => window.history.back()
  useEffect(() => {
    setTrailer(location.state.trailer)
  }, [])

  if (!trailer) {
    return <Loading />
  }

  return (
    <ContainerVideo>
      <VideoPlayer
        poster={trailer?.default_image}
        main_resolutions={trailer?.resolutions}
        main_video={trailer?.resolutions.original}
        full={true}
      />
      <div onClick={btnBack} style={{ cursor: 'pointer' }}>
        <BackPage>
          <img
            src={arrow}
            alt="arrow"
          />
          {trailer?.name}
        </BackPage>
      </div>
    </ContainerVideo>
  )
}
export default Trailer
