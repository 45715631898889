/* eslint-disable */
import { AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import CategoryCard from "../../../components/Auth/CategoryCard";
import Header from "../../../components/Auth/Header";
import { ButtonOrange } from "../../../components/Buttons";
import LoadingSpinner from "../../../components/LoadingSpinner";
import api from "../../../services/api";
import {
  FooterModalOptions,
  ModalButtonCancel,
  ModalButtonConfirm,
  ModalContent,
  ModalText,
  ModalWrapper,
} from "./modalStyled";
import {
  AllCategoriesColumn,
  ColumnContainer,
  ColumnName,
  Container,
  ContentContainer,
  CreatorHeader,
  NoVideoText,
  OrderedCategoriesColumn,
  PageContainer,
  PreviewText,
  SaveButton,
  SaveButtonContainer,
  TeamPicker,
  VideoPlayer,
  VideoPlayerColumnContainer,
} from "./styled";
const customStyles = {
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#CBAF63" : "#fff",
    color: "#2D2935",
    fontWeight: state.isSelected ? 600 : 500,
    "&:hover": { backgroundColor: "#D8DCF3" },
  }),
  control: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    borderRadius: "6px",
    width: "260px",
    borderColor: state.isFocused ? "#CBAF63" : "#9D9AAD",
    backgroundColor: "#F4F3FE",
    fontWeight: 600,
    "&:hover": { borderColor: "#222B6A" },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#2D2935",
  }),
};

interface VideoIds {
  [categoryId: string]: {
    [subCategoryId: string]: { [videoId: string]: boolean };
  };
}

interface Video {
  title: string;
  default_image: string;
  duration: string;
  admin_video_id: number;
  isSelected: boolean;
  onToggleSelect: (
    categoryId: number,
    subCategoryId: number,
    videoId: number,
  ) => void;
}

interface SubCategory {
  title: string;
  data: Video[];
}

interface HiddenVideos {
  id: number;
  category_id: number;
}

const CategorySorter = () => {
  const [categoriesTotal, setCategoriesTotal] = useState<any[]>([]);
  const [teamSelect, setTeamSelect] = useState<any[]>([]);
  const [selectedOptionTeam, setSelectedOptionTeam] = useState<any>(null);
  const [showAlerts, setShowAlerts] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryVideos, setCategoryVideos] = useState<{
    [key: string]: SubCategory[];
  }>({});
  const [selectedVideoIds, setSelectedVideoIds] = useState<VideoIds>({});
  const [unselectedVideoIds, setUnselectedVideoIds] = useState<VideoIds>({});
  const [videoPreview, setVideoPreview] = useState<string | null>(null);
  const [isPreviewPending, setIsPreviewPending] = useState(false);

  const updateUnselectedVideos = (
    categoryId: string,
    subCategoryId: string,
    videoId: string,
  ) => {
    const updatedUnselectedVideoIds = { ...unselectedVideoIds };

    if (!updatedUnselectedVideoIds[categoryId]) {
      updatedUnselectedVideoIds[categoryId] = {};
    }

    if (!updatedUnselectedVideoIds[categoryId][subCategoryId]) {
      updatedUnselectedVideoIds[categoryId][subCategoryId] = {};
    }

    if (!updatedUnselectedVideoIds[categoryId][subCategoryId][videoId]) {
      updatedUnselectedVideoIds[categoryId][subCategoryId][videoId] = true;
      return setUnselectedVideoIds(updatedUnselectedVideoIds);
    }

    if (updatedUnselectedVideoIds[categoryId][subCategoryId][videoId] == true) {
      if (updatedUnselectedVideoIds[categoryId][subCategoryId]) {
        if (
          updatedUnselectedVideoIds[categoryId][subCategoryId][videoId] == true
        ) {
          updatedUnselectedVideoIds[categoryId] = {
            ...updatedUnselectedVideoIds[categoryId],
            [subCategoryId]: {
              ...updatedUnselectedVideoIds[categoryId][subCategoryId],
              [videoId]: false,
            },
          };
        }
      }
      return setUnselectedVideoIds(updatedUnselectedVideoIds);
    }

    if (
      updatedUnselectedVideoIds[categoryId][subCategoryId][videoId] == false
    ) {
      if (updatedUnselectedVideoIds[categoryId][subCategoryId]) {
        if (
          updatedUnselectedVideoIds[categoryId][subCategoryId][videoId] == false
        ) {
          updatedUnselectedVideoIds[categoryId] = {
            ...updatedUnselectedVideoIds[categoryId],
            [subCategoryId]: {
              ...updatedUnselectedVideoIds[categoryId][subCategoryId],
              [videoId]: true,
            },
          };
        }
      }
      return setUnselectedVideoIds(updatedUnselectedVideoIds);
    }
  };

  const updateHiddenVideos = async (selection: any) => {
    try {
      for (const [categoryId, subCategories] of Object.entries(
        unselectedVideoIds,
      )) {
        if (
          !itemsInSecondColumn.some(
            (category) => category.id.toString() === categoryId,
          )
        ) {
          continue;
        }

        let videosAux: string | any[] = [];
        for (const [subCategoryId, videos] of Object.entries(subCategories)) {
          const hiddenVideosIds = Object.entries(videos)
            .filter(([_, isSelected]) => isSelected)
            .map(([videoId, _]) => videoId);

          if (hiddenVideosIds.length === 0) {
            videosAux = [];
          } else {
            videosAux = hiddenVideosIds;
          }
        }

        const organizationId = localStorage.getItem("organizationId");
        let teamId = 0;
        for (const team of teamSelect) {
          if (team.value === selection.value) {
            teamId = team.teamId;
          }
        }

        if (videosAux.length === 0) {
          const response = await api.postMethod(
            `edit_hidden_videos/${organizationId}/${categoryId}/${0}`,
            { hidden_videos_ids: JSON.stringify([]), teamId: teamId },
          );

          if (response.status !== 200) {
            toast.error(
              `Error updating hidden videos for category ${categoryId}.`,
            );
          }

          continue;
        }

        if (
          !itemsInSecondColumn.some(
            (category) => category.id.toString() === categoryId,
          )
        ) {
          continue;
        }

        const hiddenVideosJSONStringify = JSON.stringify(videosAux as any);
        const response = await api.postMethod(
          `edit_hidden_videos/${organizationId}/${categoryId}/${0}`,
          { hidden_videos_ids: hiddenVideosJSONStringify, teamId: teamId },
        );

        if (response.status !== 200) {
          toast.error(
            `Error updating hidden videos for category ${categoryId}, subcategory ${0}.`,
          );
        }
      }
    } catch (error) {
      console.error("Error updating hidden videos: ", error);
      toast.error("Error updating hidden videos.");
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const organizationName = localStorage.getItem("organizationName");
  const organizationOption = [
    {
      value: organizationName,
      label: `Default learning path (New Users)`,
    },
  ];

  const getCategoriesInfo = async (selection: any) => {
    if (selection.value === organizationName) {
      const organizationCategoriesSaved: AxiosResponse<any[any]> =
        await api.getMethod(
          `get_all_organization_categories/${parseInt(
            localStorage.getItem("organizationId") as string,
          )}`,
        );

      const organizationCategories: AxiosResponse<any[any]> =
        await api.getMethod(
          `get_all_organization_categories_learning_path/${parseInt(
            localStorage.getItem("organizationId") as string,
          )}`,
        );

      const organizationCategoriesNotSavedFiltered =
        organizationCategories?.data?.data.filter((item: any) => {
          return !organizationCategoriesSaved?.data?.data.some(
            (savedItem: any) => savedItem.category_id === item.id,
          );
        });

      const organizationCategoriesSavedFiltered =
        organizationCategories?.data?.data.filter((item: any) => {
          return organizationCategoriesSaved?.data?.data.some(
            (savedItem: any) => savedItem.category_id === item.id,
          );
        });

      const categoriesSavedSorted: any =
        organizationCategoriesSavedFiltered.sort((a: any, b: any) =>
          a.sort < b.sort ? -1 : a.sort > b.sort ? 1 : 0,
        );
      const categoriesNotSavedSorted: any =
        organizationCategoriesNotSavedFiltered.sort((a: any, b: any) =>
          a.id < b.id ? -1 : a.id > b.id ? 1 : 0,
        );

      setCategoriesTotal(categoriesNotSavedSorted);
      setItemsInSecondColumn(categoriesSavedSorted);
    } else {
      let teamId = 0;
      for (const team of teamSelect) {
        if (team.value === selection.value) {
          teamId = team.teamId;
        }
      }

      const organizationCategories: AxiosResponse<any[any]> =
        await api.getMethod(
          `get_all_organization_categories_learning_path/${parseInt(
            localStorage.getItem("organizationId") as string,
          )}`,
        );

      const organizationCategoriesSaved: AxiosResponse<any[any]> =
        await api.getMethod(
          `get_all_organization_categories_learning_path/${parseInt(
            localStorage.getItem("organizationId") as string,
          )}`,
          {
            team_id: teamId,
          },
        );

      const organizationCategoriesNotSavedFiltered =
        organizationCategories?.data?.data.filter((item: any) => {
          return !organizationCategoriesSaved?.data?.data.some(
            (savedItem: any) => savedItem.id === item.id,
          );
        });
      const organizationCategoriesSavedFiltered =
        organizationCategoriesSaved?.data?.data.filter((item: any) => {
          return organizationCategoriesSaved?.data?.data.some(
            (savedItem: any) => {
              if (savedItem.id === item.id) {
                item.essential = savedItem.essential;
              }
              return savedItem.id === item.id;
            },
          );
        });

      const categoriesSavedSorted: any =
        organizationCategoriesSavedFiltered.sort((a: any, b: any) =>
          a.sort < b.sort ? -1 : a.sort > b.sort ? 1 : 0,
        );
      const categoriesNotSavedSorted: any =
        organizationCategoriesNotSavedFiltered.sort((a: any, b: any) =>
          a.id < b.id ? -1 : a.id > b.id ? 1 : 0,
        );

      setCategoriesTotal(categoriesNotSavedSorted);
      setItemsInSecondColumn(categoriesSavedSorted);
      setIsLoading(false);
    }
  };

  const getTeams = async () => {
    const { data }: AxiosResponse<any[any]> = await api.getMethod(
      `organization/${localStorage.getItem("organizationId")}/teams`,
    );
    const selectFormatTeams = [];

    for (let i = 0; i < data.length; i++) {
      selectFormatTeams.push({
        value: data[i].name,
        label: data[i].name,
        teamId: data[i].id,
      });
    }
    const teamSelectArray = [...organizationOption, ...selectFormatTeams];
    setTeamSelect(teamSelectArray);
  };

  function handleSelectChangeTeam(selectedOptionTeam: any) {
    setSelectedOptionTeam(selectedOptionTeam);
    changeSelection(selectedOptionTeam);
  }

  React.useEffect(() => {
    getTeams();
  }, []);

  function changeSelection(team: any) {
    getCategoriesInfo(team);
  }

  // React.useEffect(() => {
  //   if (selectedOptionTeam !== null) {
  //     setIsLoading(true)
  //     getCategoriesInfo()
  //   }
  // }, [selectedOptionTeam])

  const initialItemsInSecondColumn: Array<any> = [];
  const [itemsInSecondColumn, setItemsInSecondColumn] = useState(
    initialItemsInSecondColumn,
  );

  const saveCategoryOrder = async (selection: any) => {
    if (itemsInSecondColumn.length === 0) {
      setShowAlerts(true);
      return;
    }

    const categoriesEssentialsTeam: any = itemsInSecondColumn.map(
      (item: any, _: number) => {
        return item.essential !== 0
          ? { value: item.id, label: item.name }
          : null;
      },
    );
    const filteredCategoriesEssentialsTeam = categoriesEssentialsTeam.filter(
      (item: null) => item !== null,
    );

    if (filteredCategoriesEssentialsTeam.length === 0) {
      setShowAlerts(true);
      return;
    }

    const categoriesOrderTeam: object[] = itemsInSecondColumn.map(
      (item: any, index: number) => {
        return { value: item.id, label: item.name };
      },
    );

    const generateOrderCategories = itemsInSecondColumn.map(
      (item: any, index: number) => {
        return { category_id: item.id, order: index + 1 };
      },
    );

    const formDataOrder = {
      categories: JSON.stringify(generateOrderCategories),
    };

    const formDataOrg = {
      banner_file: false,
      categories: JSON.stringify(categoriesOrderTeam),
      videos: "[]",
    };
    const formDataOrgEssential = {
      categories: JSON.stringify(filteredCategoriesEssentialsTeam),
    };

    if (selectedOptionTeam.value === organizationName) {
      const responseOrgStruct = await api.postMethod(
        `organization/edit/${localStorage.getItem("organizationId")}`,
        formDataOrg,
      );
      const responseOrgEssential = await api.postMethod(
        `organization/edit/essential-category/${localStorage.getItem(
          "organizationId",
        )}`,
        formDataOrgEssential,
      );
      const responseOrgOrder = await api.postMethod(
        `organization/edit/order-categories/${localStorage.getItem(
          "organizationId",
        )}`,
        formDataOrder,
      );

      await updateHiddenVideos(selection);

      if (
        responseOrgOrder.status === 201 &&
        responseOrgStruct.status === 201 &&
        responseOrgEssential.status === 201
      ) {
        toast.success("Great! Organization Categories sorted successfully!", {
          autoClose: 2000,
          onOpen: () => setShowModal(false),
          onClose: () =>
          (window.location.href =
            "/manage-organization/learning-path-creator"),
        });
      } else {
        toast.error("There was a problem performing the operation.", {
          autoClose: 2000,
          onOpen: () => setShowModal(false),
          onClose: () =>
          (window.location.href =
            "/manage-organization/learning-path-creator"),
        });
      }
    } else {
      let teamId = 0;
      for (const team of teamSelect) {
        if (team.value === selectedOptionTeam.value) {
          teamId = team.teamId;
        }
      }
      const formDataTeamEssential = {
        team_id: selectedOptionTeam.teamId ?? 0,
        categories: JSON.stringify(categoriesOrderTeam),
        essential_categories: JSON.stringify(filteredCategoriesEssentialsTeam),
      };
      const formDataTeam = {
        name: selectedOptionTeam.value,
        organizationId: localStorage.getItem("organizationId"),
        teamCategories: JSON.stringify(categoriesOrderTeam),
        members: "[]",
      };

      const responseTeamCategories = await api.postMethod(
        `organization/teams/${teamId}`,
        formDataTeam,
      );
      const responseTeam = await api.postMethod(
        `organization/teams/essential-category/${localStorage.getItem(
          "organizationId",
        )}`,
        formDataTeamEssential,
      );

      await updateHiddenVideos(selection);

      if (
        responseTeam.status === 201 &&
        responseTeamCategories.status === 201
      ) {
        toast.success(
          `Great! Team ${selectedOptionTeam.value} Categories sorted successfully!`,
          {
            autoClose: 2000,
            onOpen: () => setShowModal(false),
            onClose: () =>
            (window.location.href =
              "/manage-organization/learning-path-creator"),
          },
        );
      } else {
        toast.error("There was a problem performing the operation.", {
          autoClose: 2000,
          onOpen: () => setShowModal(false),
          onClose: () =>
          (window.location.href =
            "/manage-organization/learning-path-creator"),
        });
      }
    }
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // If the item was dropped outside of any Droppable, do nothing
    if (!destination) {
      return;
    }

    // Check if the item was moved from the first column to the second column
    if (
      source.droppableId === "allCategories" &&
      destination.droppableId === "orderedCategories"
    ) {
      const draggedItem = categoriesTotal[source.index];

      // Remove the item from the first column and add it to the second column
      const newItemsInFirstColumn = [...categoriesTotal];
      newItemsInFirstColumn.splice(source.index, 1);

      // Insert the dragged item at the specified destination index
      const updatedItemsInSecondColumn = [...itemsInSecondColumn];
      updatedItemsInSecondColumn.splice(destination.index, 0, draggedItem);

      setCategoriesTotal(newItemsInFirstColumn);
      setItemsInSecondColumn(updatedItemsInSecondColumn);
    } else if (
      source.droppableId === "orderedCategories" &&
      destination.droppableId === "allCategories"
    ) {
      // Check if the item was moved from the second column to the first column
      const draggedItem = itemsInSecondColumn[source.index];

      // Remove the item from the second column and add it to the first column
      const updatedItemsInSecondColumn = [...itemsInSecondColumn];
      updatedItemsInSecondColumn.splice(source.index, 1);

      // Insert the dragged item at the specified destination index in the first column
      const updatedItemsInFirstColumn = [...categoriesTotal];
      updatedItemsInFirstColumn.splice(destination.index, 0, draggedItem);

      setItemsInSecondColumn(updatedItemsInSecondColumn);
      setCategoriesTotal(updatedItemsInFirstColumn);
    } else if (
      source.droppableId === "orderedCategories" &&
      destination.droppableId === "orderedCategories"
    ) {
      const updatedItemsInSecondColumn = [...itemsInSecondColumn];
      const [draggedItem] = updatedItemsInSecondColumn.splice(source.index, 1);
      updatedItemsInSecondColumn.splice(destination.index, 0, draggedItem);
      setItemsInSecondColumn(updatedItemsInSecondColumn);
    } else if (
      source.droppableId === "allCategories" &&
      destination.droppableId === "allCategories"
    ) {
      const updatedItemsInSecondColumn = [...categoriesTotal];
      const [draggedItem] = updatedItemsInSecondColumn.splice(source.index, 1);
      updatedItemsInSecondColumn.splice(destination.index, 0, draggedItem);
      setCategoriesTotal(updatedItemsInSecondColumn);
    }
  };

  const handleSwitchChange = (
    categoryId: string,
    newValue: boolean,
    columnNumber: string,
  ) => {
    if (columnNumber === "1") {
      const updatedApiData = categoriesTotal.map((category) => {
        if (category.id === categoryId) {
          return { ...category, essential: newValue === true ? 1 : 0 };
        }
        return category;
      });
      setCategoriesTotal(updatedApiData);
    } else {
      const updatedApiData = itemsInSecondColumn.map((category) => {
        if (category.id === categoryId) {
          return { ...category, essential: newValue === true ? 1 : 0 };
        }
        return category;
      });
      // Update the state with the updated data
      setItemsInSecondColumn(updatedApiData);
    }
  };

  const handleSetPreview = async (id: number) => {
    try {
      setIsPreviewPending(true)
      const { data }: any = await api.postMethod("videos/view", {
        admin_video_id: String(id),
        skip: 0
      })
      console.log(data)
      console.log(data.data)
      setVideoPreview(data.data.main_resolutions.original)
    } catch (err) {
      toast.error("Erro ao buscar video")
      console.log(err)
    } finally {
      setIsPreviewPending(false)
    }
    console.log(id)
  }

  return (
    <Container>
      <Header />
      <div style={{ position: "absolute", marginLeft: "2%", top: "8%" }}>
        <Link to="/manage-organization">
          <ButtonOrange as="button" type="button" className="btn">
            Back
          </ButtonOrange>
        </Link>
      </div>
      <PageContainer>
        <CreatorHeader>
          <TeamPicker>
            <text>
              <strong>Step 1: </strong>Choose a team or the organization to
              create the learning path:
            </text>
            <Select
              options={teamSelect}
              styles={customStyles}
              onChange={handleSelectChangeTeam}
              value={
                selectedOptionTeam === null
                  ? "Please select a team"
                  : selectedOptionTeam
              }
              defaultValue="Please select a team"
            />
          </TeamPicker>
        </CreatorHeader>
        <DragDropContext onDragEnd={onDragEnd}>
          <ContentContainer>
            <ColumnContainer>
              <ColumnName>
                <strong>Step 2: </strong>Review categories and videos
              </ColumnName>
              {!isLoading ? (
                <Droppable droppableId="allCategories">
                  {(provided, snapshot) => (
                    <AllCategoriesColumn
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      isDraggingOver={snapshot.isDraggingOver}
                    >
                      {categoriesTotal.map((category: any, index: number) => {
                        return (
                          <CategoryCard
                            setPreview={handleSetPreview}
                            index={index}
                            categoryId={category.id}
                            thumbImage={category.picture}
                            name={category.name}
                            initialValue={category.essential}
                            key={index}
                            onUpdate={handleSwitchChange}
                            switchLabel={
                              category.essential === 1
                                ? "Required Category"
                                : "Optional Category"
                            }
                            columnNumber={"1"}
                            teamSelect={teamSelect}
                            teamSelected={selectedOptionTeam}
                            propUnselectedVideosIds={unselectedVideoIds}
                            subCategory={category.subcategories}
                            onUpdateUnselectedVideoIds={(
                              categoryId,
                              subCategoryId,
                              videoId,
                            ) => {
                              updateUnselectedVideos(
                                categoryId,
                                subCategoryId,
                                videoId,
                              );
                            }}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </AllCategoriesColumn>
                  )}
                </Droppable>
              ) : (
                <LoadingSpinner />
              )}
            </ColumnContainer>
            <ColumnContainer>
              <ColumnName>
                <strong>Step 3: </strong>Drag and drop categories here
              </ColumnName>
              {!isLoading ? (
                <Droppable droppableId="orderedCategories">
                  {(provided, snapshot) => (
                    <OrderedCategoriesColumn
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      isDraggingOver={snapshot.isDraggingOver}
                    >
                      {itemsInSecondColumn.map(
                        (category: any, index: number) => {
                          return (
                            <CategoryCard
                              setPreview={handleSetPreview}
                              index={index}
                              categoryId={category.id}
                              thumbImage={category.picture}
                              name={category.name}
                              initialValue={category.essential}
                              key={index}
                              onUpdate={handleSwitchChange}
                              switchLabel={
                                category.essential === 1
                                  ? "Required Category"
                                  : "Optional Category"
                              }
                              columnNumber={"2"}
                              teamSelect={teamSelect}
                              teamSelected={selectedOptionTeam}
                              propUnselectedVideosIds={unselectedVideoIds}
                              subCategory={category.subcategories}
                              onUpdateUnselectedVideoIds={(
                                categoryId,
                                subCategoryId,
                                videoId,
                              ) => {
                                updateUnselectedVideos(
                                  categoryId,
                                  subCategoryId,
                                  videoId,
                                );
                              }}
                            />
                          );
                        },
                      )}
                      {provided.placeholder}
                    </OrderedCategoriesColumn>
                  )}
                </Droppable>
              ) : (
                <LoadingSpinner />
              )}
              {selectedOptionTeam === null ? (
                <></>
              ) : (
                <SaveButtonContainer>
                  <SaveButton
                    style={{ backgroundColor: "#222B6A", color: "#D8DCF3" }}
                    onClick={openModal}
                  >
                    <strong>Step 4: </strong>Save your selection
                  </SaveButton>
                  {showAlerts && (
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      <strong>Attention!</strong> You should add at least one
                      required category.
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => setShowAlerts(false)}
                      >
                        <span aria-hidden="true" color="red !important">
                          &times;
                        </span>
                      </button>
                    </div>
                  )}
                </SaveButtonContainer>
              )}
            </ColumnContainer>
            <VideoPlayerColumnContainer>
              <PreviewText>Click on a video to preview it.</PreviewText>
              <VideoPlayer>
                {
                  isPreviewPending && (
                    <LoadingSpinner />
                  )
                }
                {
                  videoPreview && !isPreviewPending ? (
                    <video autoPlay controls src={videoPreview} style={{ width: '100%' }} />
                  ) : (
                    <NoVideoText>No video selected</NoVideoText>
                  )
                }
              </VideoPlayer>
            </VideoPlayerColumnContainer>
          </ContentContainer>
        </DragDropContext>
      </PageContainer>
      {showModal && (
        <ModalWrapper>
          <ModalContent>
            <ModalText>
              Are you sure you want to save this learning Path?
            </ModalText>
            <FooterModalOptions>
              <ModalButtonCancel onClick={closeModal}>Cancel</ModalButtonCancel>
              <ModalButtonConfirm onClick={() => saveCategoryOrder(selectedOptionTeam)}>
                Confirm
              </ModalButtonConfirm>
            </FooterModalOptions>
          </ModalContent>
        </ModalWrapper>
      )}
    </Container>
  );
};

export default CategorySorter;
