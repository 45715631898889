import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import teamIco from '../../../assets/images/team-ico.svg'
import useAuth from '../../../hooks/useAuth'
import api from '../../../services/api'

import { Props } from './interfaces'

import {
  Container,
  UserImgContainerLeft,
  UserImgContainerMiddle,
  UserName,
  UserInfoProfileLeft,
  UserInfoProfileMiddle,
  UserTeam,
  UserInfo,
  // UserLoginInfo,
  UserInfoMiddle,
  MobileVersion,
  MobileButtonsContainer,
  FirstMobileButton,
  SecondMobileButton,
  ThirdMobileButton
} from './styled'

export default function UserInfoCard ({ infoOnLeft }: Props) {
  const [name, setName] = useState('')
  const [picture, setPicture] = useState('')
  const [role, setRole] = useState('')
  const [team, setTeam] = useState('')
  // const [lastLogin, setLastLogin] = useState<string>('')
  const { userDetails } = useAuth()

  const getUserDetails = (user: any) => {
    setPicture(user.picture || localStorage.getItem('active_profile_image'))
    setName(user.name || userDetails?.name)
    setRole(user.role || localStorage.getItem('role'))
    setTeam(user.team || localStorage.getItem('team') || '')
  }

  /* const getLastUserActivity = async () => {
    setLastLogin(formaterDate(String(localStorage.getItem('last_login')).slice(0, 10), 'simpleDate'))

    const { data }: any = await api.getMethod(`get_user_activity/${localStorage.getItem('userId')}`)

    if (data?.success === true) {
      setLastLogin(typeof data?.data?.lastLogin === 'string' ? formaterDate(data?.data?.lastLogin, 'simpleDate') : formaterDate(Object.keys(data?.data?.lastLogin)[0], 'complexDate'))
    }
  } */

  const getUser = async () => {
    const { data }: any = await api.getMethod(`user/${Number(localStorage.getItem('userId'))}/infos`)
    if (data.length <= 0) {
      return
    }

    getUserDetails(data)

    // await getLastUserActivity()
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <Container>
      {
        infoOnLeft
          ? <UserInfoProfileLeft>
              <UserImgContainerLeft>
                <LazyLoadImage src={picture} width='100%' height='100%' style={{ padding: '2px', borderRadius: '50%' }} />
              </UserImgContainerLeft>
              <UserInfo>
                <UserName>
                  Hi, {name} <text>{role}</text>
                </UserName>
                <UserTeam>
                  <LazyLoadImage src={teamIco} width='16px' height='16px'/>
                  <text>{team}</text>
                </UserTeam>
                {/* <UserLoginInfo>
                  Last Login: { lastLogin }
                </UserLoginInfo> */}
              </UserInfo>
            </UserInfoProfileLeft>
          : <UserInfoProfileMiddle>
              <MobileVersion>
                <MobileButtonsContainer>
                  <FirstMobileButton>
                  </FirstMobileButton>
                  <SecondMobileButton>
                  </SecondMobileButton>
                  <ThirdMobileButton>
                  </ThirdMobileButton>
                </MobileButtonsContainer>
                <UserInfoMiddle>
                  <UserName>
                    Hi, {name}
                  </UserName>
                  <UserTeam>
                    <text>{team}</text>
                    <LazyLoadImage src={teamIco} width='16px' height='16px'/>
                  </UserTeam>
                </UserInfoMiddle>
              </MobileVersion>
              <UserImgContainerMiddle>
                <LazyLoadImage src={picture} width='100%' height='100%' style={{ padding: '2px', borderRadius: '50%' }}/>
              </UserImgContainerMiddle>
            </UserInfoProfileMiddle>
      }
    </Container>
  )
}
