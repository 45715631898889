import styled from 'styled-components'

export const Container = styled.div`

  .videos-container {
    .slider-wrapper {
      @media (max-width: 426px) {
        margin-top: 8px;
      }
    }
  }
`

export const ProgressCircleContainer = styled.div`
  margin-top: 2rem;
  width: 20%;
  height: 20%;

  @media (max-width: 769px) {
    svg {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 479.67px) {
    width: 20vw;
    height: 20vh;
  }

  @media (max-width: 425px) {
    width: 80px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-right: -40px;
    margin-top: 8px;

    svg {
      width: 80px;
      height: 80px;
    }
  }
`
