import styled from 'styled-components'

export const ContainerDiv = styled.div`
`

export const TitlePageH1 = styled.h1`
`

export const TitlePageH4 = styled.h1`
`

export const ContainerUl = styled.ul`
`

export const ContainerLi = styled.li`
`

export const ContainerManageOrganization = styled.div`
  color: #212C68;
  margin-top: 6em;
  padding: 0 55px;
  
  h1 { 
    font-size: 2.15em;
    margin: 0 0 .55em 0;
    text-transform: capitalize;
  }

  .account-sec{
    border-top: 1px solid #999;
  }
  .account-sec [class*="col-"]{
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .account-sec-head{
    color: #999;
    font-size: 1.14em;
    text-transform: uppercase;
  }
  .account-sec .email{
    color: #ccc;
    font-size: 1.14em;
    line-height: 1.375;
  }
  .account-sec .password{
    color: #999;
    margin-bottom: 0;
    font-size: 1.14em;
    line-height: 1.375;
    text-transform: capitalize;
  }
  .account-sec .password .asterisk{
    font-size: .6em;
    vertical-align: 10%;
  }
  .account-nav-link{
    margin:0;
    padding: 0;
    list-style: none;
  }
  .account-nav-link li{
    text-align: right;
    margin-bottom: .5em; 
  }
  .account-nav-link li a{
    font-size: 1.14em;
    line-height: 1.375; 
    text-transform: capitalize;
    color:#70b7fd;
  }

`
