import React, { useState } from 'react'

import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import api from '../../../services/api'

import {
  ContainerDiv,
  InputDefault,
  InputLabel,
  ContainerTeamForm,
  TitleForm
} from './styled'

import { ButtonOrange } from '../../Buttons'
import useTeams from '../../../hooks/useTeams'
import { Link } from 'react-router-dom'

const animatedOptions = makeAnimated()

interface Props {
  formTitle: string
}

const TeamForm = ({ formTitle }: Props) => {
  const [selectedUsers, setSelectedUsers] = useState([])
  // const [teamCategories, setTeamCategories] = useState([])
  const [teamName, setTeamName] = useState('')
  const { organizationUsers/* , organizationCategories */ } = useTeams()

  const handelCreateTeam = async () => {
    const formData = {
      name: teamName,
      organizationId: localStorage.getItem('organizationId'),
      teamCategories: JSON.stringify([]),
      members: JSON.stringify(selectedUsers)
    }

    const response = await api.postMethod(`organization/${localStorage.getItem('organizationId')}/teams`, formData)

    if (response.status === 201) {
      alert('Team created successfully!')
      window.location.href = '/manage-organization/create-team'
    }
  }

  return (
    <React.Fragment>
      <ContainerDiv style={{ position: 'absolute', marginLeft: '5%', top: '12%' }}>
        <Link to='/manage-organization'>
          <ButtonOrange
            as="button"
            type="button"
            className='btn'
          >
            Back
          </ButtonOrange>
        </Link>
      </ContainerDiv>

      <ContainerTeamForm>
        <TitleForm>{formTitle}</TitleForm>
        <ContainerDiv className="container">
          <ContainerDiv className="form-group mt-2">
            <InputLabel htmlFor="teamName">Team Name</InputLabel>
            <InputDefault
              className="form-control"
              id="teamName"
              name="teamName"
              placeholder="Team Name"
              minLength={1}
              value={teamName}
              onChange={(event: any) => setTeamName(event.target.value)}
              required
            />
          </ContainerDiv>

          {/* <ContainerDiv className="form-group mt-2">
            <InputLabel htmlFor="members[]">Team Categories:</InputLabel>
            <Select
              onChange={(item: any) => setTeamCategories(item)}
              components={animatedOptions}
              options={organizationCategories}
              closeMenuOnSelect={false}
              className="basic-multi-select"
              classNamePrefix="select"
              name="members[]"
              isMulti
              required
            />
          </ContainerDiv> */}

          <ContainerDiv className="form-group mt-2">
            <InputLabel htmlFor="members[]">Team Members:</InputLabel>
            <Select
              onChange={(item: any) => setSelectedUsers(item)}
              components={animatedOptions}
              options={organizationUsers}
              closeMenuOnSelect={false}
              className="basic-multi-select"
              classNamePrefix="select"
              name="members[]"
              isMulti
              required
            />
          </ContainerDiv>

          <ButtonOrange
            as="button"
            type="button"
            className='btn'
            data-dismiss="modal"
            onClick={ () => handelCreateTeam() }
          >
            Create Team
          </ButtonOrange>
        </ContainerDiv>
      </ContainerTeamForm>
    </React.Fragment>
  )
}

export default TeamForm
