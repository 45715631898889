import React, { useEffect, useState } from 'react'

import {
  ContainerDiv,
  ContainerTeamOverview,
  TableDefault,
  TbodyDefault,
  ThDefault,
  TheadDefault,
  TitleForm,
  TrDefault
} from './styled'
import { ButtonOrange } from '../../Buttons'
import { Link } from 'react-router-dom'
import api from '../../../services/api'
import Loading from '../../Loading'

type TeamId = {
  teamName: string
  teamId: number
}

const TeamOverviewCard = ({ teamName, teamId }: TeamId) => {
  const [teamMembers, setTeamMembers] = useState<any>([])
  const [teamCategories, setTeamCategories] = useState<any>([])

  const getTeam = async () => {
    const { data }: any = await api.getMethod(`organization/${teamId}/team`, {
      organization_id: localStorage.getItem('organizationId')
    })
    setTeamMembers(data.data.members)
    setTeamCategories(data.data.categories)
  }
  useEffect(() => {
    getTeam()
  }, [])

  if (!teamMembers && !teamCategories) {
    return <Loading />
  }

  return (
    <React.Fragment>
      <ContainerDiv style={{ position: 'absolute', marginLeft: '5%', top: '12%' }}>
        <Link to='/manage-organization/edit-team'>
          <ButtonOrange
            as="button"
            type="button"
            className='btn'
          >
            Back
          </ButtonOrange>
        </Link>
      </ContainerDiv>

      <ContainerTeamOverview className="container">
        <TitleForm>{ teamName }</TitleForm>

        <ContainerDiv style={{ marginBottom: '5%' }}>
          <TableDefault>
            <TheadDefault>
              <TrDefault>
                <ThDefault>Member First Name</ThDefault>
                <ThDefault>Member Last Name</ThDefault>
                <ThDefault>Created At</ThDefault>
                <ThDefault>Updated At</ThDefault>
              </TrDefault>
            </TheadDefault>
            <TbodyDefault>
              {teamMembers.map((member: any, _: number) => {
                return (
                  <TrDefault key={member.id}>
                    <ThDefault>{ member.first_name }</ThDefault>
                    <ThDefault>{ member.last_name }</ThDefault>
                    <ThDefault>{ member.created_at }</ThDefault>
                    <ThDefault>{ member.updated_at }</ThDefault>
                  </TrDefault>
                )
              })}
            </TbodyDefault>
          </TableDefault>
        </ContainerDiv>

        <ContainerDiv>
          <TableDefault>
            <TheadDefault>
              <TrDefault>
                <ThDefault>Category Image</ThDefault>
                <ThDefault>Category Name</ThDefault>
                <ThDefault>Essential</ThDefault>
              </TrDefault>
            </TheadDefault>
            <TbodyDefault>
              {teamCategories.map((category: any, _: number) => {
                return (
                  <TrDefault key={category.category_id}>
                    <ThDefault><img src={category.picture} alt='category image' width="100px" height="70px" /></ThDefault>
                    <ThDefault>{ category.name }</ThDefault>
                    <ThDefault>{ category.essential === 1 ? 'Yes' : 'No' }</ThDefault>
                  </TrDefault>
                )
              })}
            </TbodyDefault>
          </TableDefault>
        </ContainerDiv>
      </ContainerTeamOverview>
    </React.Fragment>
  )
}

export default TeamOverviewCard
