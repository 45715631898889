import React from 'react'
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts'

interface Props {
  data: Array<object>,
  courseNames: string[] | undefined,
  keyNames: string[] | undefined,
  valueNames: number[] | undefined,
  height: number
}

export function HorizontalStackedBarChart ({ data, courseNames, keyNames, valueNames, height }: Props) {
  const colors = [
    '#2196F3', '#F44336', '#4CAF50', '#FFC107', '#9C27B0', '#00BCD4',
    '#FF5722', '#673AB7', '#8BC34A', '#FFEB3B', '#795548', '#CDDC39',
    '#3F51B5', '#E91E63', '#009688', '#FF9800', '#607D8B', '#FFA726',
    '#9E9E9E', '#9E9E9E'
  ]

  function randomColor () {
    return colors.shift()
  }

  const newdata = [data]

  const labelFormatter = (value: any, entry: any, index: any) => <span style={{ color: '#2D2935', fontWeight: 500 }}>{value} = {valueNames && valueNames[index]}%</span>
  const labelListFormatter = (value:any) => `${value}%`

  return (
    <ResponsiveContainer width='100%' height='100%' >
      <BarChart
        width={400}
        height={600}
        data={newdata}
        barSize={30}
        margin={{
          top: 5,
          right: 10,
          left: 0,
          bottom: 0
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey='courseLabel' tick={false} />
        <YAxis ticks={[0, 25, 50, 75, 100]} />
        <Legend verticalAlign='top' align='left' iconType='circle' wrapperStyle={{ maxWidth: '170px' }} layout='vertical' formatter={labelFormatter} />
        {
          courseNames &&
          courseNames.map((item, index) => {
            return (
              <Bar
                key={index}
                dataKey={String(keyNames && keyNames[index])}
                fill={randomColor()}
                unit='%'
                name={String(courseNames && courseNames[index])}
                stackId="a"
              >
                <LabelList dataKey={String(keyNames && keyNames[index])} style={{ fontWeight: 600, color: '#2D2935' }} position='right' formatter={labelListFormatter} />
              </Bar>
            )
          })
        }
      </BarChart>
    </ResponsiveContainer>
  )
}
